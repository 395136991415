// @deprecated: Please update the new site properties default: packages/core/src/i18n/sitePropertiesDefaults.ts
export const defaults = {
  'account.login.emailverificationsuccess.text': {
    value:
      'Thanks for verifying your email address, please log in below in any way you like',
    location: 'client',
  },
  'account.address.delivertothisaddress': {
    value: 'Deliver to this address',
    location: 'client',
  },
  'account.order.status.purchasedinstore.text': {
    value: 'Purchased in store',
    location: 'client',
  },
  'account.loyalty.offers.error.text': {
    value: 'There was an error getting rewards information, please try again.',
    location: 'client',
  },
  'account.loyalty.tabs.rewardcard.title': {
    value: 'Reward Card',
    location: 'client',
  },
  'account.loyalty.tabs.offers.title': {
    value: 'My Offers',
    location: 'client',
  },
  'account.loyalty.tabs.offers.redeem.text': {
    value: 'Redeem Offer',
    location: 'client',
  },
  'account.loyalty.tabs.offers.empty.title': {
    value: 'Offers on their way...',
    location: 'client',
  },
  'account.loyalty.tabs.offers.empty.text': {
    value:
      'Your offers will start arriving soon. You will never have to miss out on any discounts or sales again!',
    location: 'client',
  },
  'account.loyalty.tabs.managecard.title': {
    value: 'Manage Card',
    location: 'client',
  },
  'account.loyalty.removecard.button.text': {
    value: 'Remove Card',
    location: 'client',
  },
  'account.loyalty.removecard.success.text': {
    value: 'Reward card removed successfully.',
    location: 'client',
  },
  'account.loyalty.removecard.error.text': {
    value:
      'There was an error with removing your card, please try again later.',
    location: 'client',
  },
  'account.loyalty.removecard.heading': {
    value: 'Want to remove your card?',
    location: 'client',
  },
  'account.loyalty.removecard.section.text': {
    value:
      'Once you remove your card, you will no longer have access to members only rewards and early access to upcoming sales.',
    location: 'client',
  },
  'account.loyalty.removecard.modal.heading': {
    value: 'Are you sure you want to remove your card?',
    location: 'client',
  },
  'account.loyalty.removecard.modal.section.text1': {
    value: 'Once you remove your card, you will no longer have access to:',
    location: 'client',
  },
  'account.loyalty.removecard.modal.section.text2': {
    value: 'Early access to sales instore and online.',
    location: 'client',
  },
  'account.loyalty.removecard.modal.section.text3': {
    value: 'Instore exclusive rewards selected for you from the best.',
    location: 'client',
  },
  'account.loyalty.removecard.modal.section.text4': {
    value: 'Additionally you will not receive any marketing emails around...',
    location: 'client',
  },
  'account.loyalty.removecard.modal.view.offers': {
    value: 'View My Offers',
    location: 'client',
  },
  'account.loyalty.redeemofferonline.modal.title.text': {
    value: 'Redeem Online',
    location: 'client',
  },
  'account.loyalty.redeemofferonline.modal.instructions.text': {
    value:
      'The below voucher code will be copied to your clipboard. Simply paste the voucher code into the promo code box at the checkout.',
    location: 'client',
  },
  'account.loyalty.redeemofferinstore.modal.title.text': {
    value: 'Redeem Instore',
    location: 'client',
  },
  'account.loyalty.redeemofferinstore.modal.instuctions.text': {
    value: 'Present the barcode below at the till point in one of our stores.',
    location: 'client',
  },
  'account.loyalty.redeemoffer.modal.terms.title': {
    value: 'Terms & Conditions',
    location: 'client',
  },
  'account.loyalty.redeemoffer.modal.terms.text': {
    value:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
    location: 'client',
  },
  'account.address.subscription.info': {
    value:
      'If you wish to change the delivery address, the change will not take place until after the current order has been dispatched.',
    location: 'client',
  },
  'account.addresschange.success': {
    value:
      'Your address has been updated successfully. The change will not take place until after the current order has been dispatched.',
    location: 'client',
  },
  'account.addresschange.error': {
    value: 'There was an error updating your subscription address.',
    location: 'client',
  },
  'account.login.existingcustomers.title': {
    value: 'Customer sign in',
    location: 'client',
  },
  'account.login.forgotten-your-password.text': {
    value: 'Forgotten your password?',
    location: 'client',
  },
  'account.login.forgotten-your-password.text.arialabel': {
    value: 'Reset your forgotten password',
    location: 'client',
  },
  'account.login.forgottenpassword.button.cancel': {
    value: `Cancel`,
    location: 'client',
  },
  'account.login.forgottenpassword.emailaddress.label.text': {
    value: 'Reset your password',
    location: 'client',
  },
  'account.login.forgottenpassword.instructions.text': {
    value:
      "Please enter your email address below and we'll email you instructions to reset it",
    location: 'client',
  },
  'account.login.forgottenpassword.button.submit.text': {
    value: 'Reset Password',
    location: 'client',
  },
  'account.login.forgottenpassword.error.text': {
    value: 'Something went wrong',
    location: 'client',
  },
  'account.login.existingcustomers.login.button.text': {
    value: 'Sign in',
    location: 'client',
  },
  'account.login.existingcustomers.login.button.text.arialabel': {
    value: 'Sign in to your account',
    location: 'client',
  },
  'account.login.newcustomers.title': {
    value: 'New to White Label',
    location: 'client',
  },
  'account.login.newcustomers.text': {
    value:
      'Sign up for a White Label account to gain access to the highest quality design assets on the planet.',
    location: 'client',
  },
  'account.login.whyregister.message': {
    value: '',
    location: 'client',
  },
  'account.login.button.register.text': {
    value: 'Sign up',
    location: 'client',
  },
  'account.login.button.guestcheckout.register.text': {
    value: 'Register',
    location: 'client',
  },
  'account.login.wechatsetpasswordform.title.text': {
    value: 'Set up a password',
    location: 'client',
  },
  'account.login.creation.unavailable.message': {
    value:
      'Please contact customer support via 1234 5678 for assistance with account creation.',
    location: 'client',
  },
  'account.login.creation.unavailable.link.text': {
    value: 'here',
    location: 'client',
  },
  'account.login.creation.unavailable.link': {
    value: '/registration-info.list',
    location: 'client',
  },
  'account.login.creation.unavailable.message1': {
    value:
      'Please contact customer support via 1234 5678 for assistance with account creation.',
    location: 'client',
  },
  'account.sociallogin.facebook.header.text.login': {
    value: 'Or, sign in with',
    location: 'client',
  },
  'input.password.show': {
    value: 'Show',
    location: 'client',
  },
  'input.password.show.arialabel': {
    value: 'Show password',
    location: 'client',
  },
  'input.password.hide': {
    value: 'Hide',
    location: 'client',
  },
  'input.password.hide.arialabel': {
    value: 'Hide password',
    location: 'client',
  },
  'general.form.input.password.show.announcement': {
    value: 'Showing password',
    location: 'client',
  },
  'general.info.unavailable': {
    value: 'Information currently unavailable',
    location: 'client',
  },
  'general.form.input.password.hide.announcement': {
    value: 'Hiding password',
    location: 'client',
  },
  'input.clear.arialabel': {
    value: 'Clear field',
    location: 'client',
  },
  'general.form.input.clear.announcement': {
    value: 'Cleared input',
    location: 'client',
  },
  'formitem.confirmable.prefix': {
    value: 'Confirm',
    location: 'client',
  },
  'formitem.confirmable.complete': {
    value: 'Complete',
    location: 'client',
  },
  'formitem.confirmable.mustmatch': {
    value: 'must match',
    location: 'client',
  },
  'account.login.guestcheckout.title': {
    value: 'Guest Checkout',
    location: 'client',
  },
  'account.login.guestcustomers.benefit.1.text': {
    value: 'No need to register',
    location: 'client',
  },
  'account.login.guestcustomers.benefit.2.text': {
    value: 'Come back and create an account later if you wish',
    location: 'client',
  },
  'account.login.guestcheckout.button': {
    value: 'Continue as a Guest',
    location: 'client',
  },
  'account.login.loginform.subtitle.text': {
    value: 'For existing customers, log in below.',
    location: 'client',
  },
  'account.login.loginform.subtitle.link.text': {
    value: 'here',
    location: 'client',
  },
  'account.login.loginform.subtitle.link': {
    value: '/accountCreate.account',
    location: 'client',
  },
  'pageunavailable.need.help': {
    value: 'Need help?',
    location: 'client',
  },
  'maintenance.need.help.description': {
    value:
      'Call our Customer Service team on: \n%s \n\nMonday to Friday 8am \nSaturday 9am to 4pm \nPlease note we are closed on Sundays and Bank Holidays\n\n',
    location: 'client',
  },
  'maintenance.footer.description': {
    value:
      '\n © %s %s\nRegistered in England No. 4305103.\nRegistered office: 5th Floor, Voyager House, Chicago Avenue, \nManchester Airport, M90 3DQ',
    location: 'client',
  },
  'account.login.guestcheckout.emptybasket.text': {
    value:
      'Your basket appears empty, please continue shopping before visiting the checkout',
    location: 'client',
  },
  'thgcopyright.copyrighttext': {
    value: '© The Hut.com Ltd.',
    location: 'client',
  },
  'thgcopyright.momentumtext': {
    value: 'THG Ideas with Momentum',
    location: 'client',
  },
  'account.register.heading': {
    value: '',
    location: 'client',
  },
  'account.register.gdpr.heading': {
    value: 'From time to time, we would like to send you emails containing:',
    location: 'client',
  },
  'account.register.unavailable.error': {
    value:
      'Sorry, account registration is currently unavailable. This should be available in less than 5 minutes. Please try again.',
    location: 'client',
  },
  'account.registration.continue.shopping.button.text': {
    value: 'Continue Shopping',
    location: 'client',
  },
  'account.registration.guest.checkout.prompt': {
    value: 'Please checkout as a guest',
    location: 'client',
  },
  'account.registration.guest.helper.purchase.sans.account': {
    value:
      'If you want to purchase something, you are still able to checkout without an account',
    location: 'client',
  },
  'account.registration.unavailable.status': {
    value: 'Account registration is currently unavailable',
    location: 'client',
  },
  'account.newaccount.createaccountform.mail.contents.1': {
    value: 'Exclusive workouts & fitness plans',
    location: 'client',
  },
  'account.newaccount.createaccountform.mail.contents.2': {
    value: 'Exclusive discounts',
    location: 'client',
  },
  'account.newaccount.createaccountform.mail.contents.3': {
    value: 'Early access to sales',
    location: 'client',
  },
  'account.newaccount.createaccountform.mail.contents.4': {
    value: '',
    location: 'client',
  },
  'account.newaccount.createaccountform.mail.contents.5': {
    value: '',
    location: 'client',
  },
  'account.register.title.text': {
    value: 'Account Registration',
    location: 'client',
  },
  'account.newaccount.createaccountform.customer.signup.with.text': {
    value: 'Sign Up With',
    location: 'client',
  },
  'account.register.form.title.text': {
    value: 'Or create an email account',
    location: 'client',
  },
  'account.communicationprefrences.title.text': {
    value: 'Communication Preferences',
    location: 'client',
  },
  'account.whysignup.title.text': {
    value: 'Why sign up?',
    location: 'client',
  },
  'account.exclusivecontenttitle.title.text': {
    value: 'Exclusive content',
    location: 'client',
  },
  'account.exclusivecontenttext.title.text': {
    value: 'Brand launches, sale previews, direct to your inbox.',
    location: 'client',
  },
  'account.exclusiveofferstitle.title.text': {
    value: 'Exclusive offers',
    location: 'client',
  },
  'account.exclusiveofferstext.title.text': {
    value: 'Great discounts, free next day delivery and more.',
    location: 'client',
  },
  'account.exclusivetoustitle.title.text': {
    value: 'Exclusive to us',
    location: 'client',
  },
  'account.exclusivetoustext.title.text': {
    value: 'No data sold or shared, just the best deals for you.',
    location: 'client',
  },
  'account.marketingpreferencesyes.title.text': {
    value: 'Yes',
    location: 'client',
  },
  'account.marketingpreferencesno.title.text': {
    value: 'No',
    location: 'client',
  },
  'account.marketingpreferencessavechanges.title.text': {
    value: 'Save changes',
    location: 'client',
  },
  'account.marketingpreferences.info.text': {
    value:
      'By opting out of marketing, you will also be opting out of My Rewards',
    location: 'client',
  },
  'account.sendmeemails.title.text': {
    value: 'Send me emails',
    location: 'client',
  },
  'account.sendmemarketingcommunication.title.text': {
    value: 'Send me marketing communication via email and post',
    location: 'client',
  },
  'account.selectaddress.dropdown.text': {
    value: 'Please select an address...',
    location: 'client',
  },
  'widget.buildyourbundle.rrp.text': {
    value: 'WAS: ',
    location: 'client',
  },
  'account.selectaddress.dropdown.label': {
    value:
      'This will be used as your default postal, billing and Rewards address',
    location: 'client',
  },
  'account.selectaddress.error.adddefault.text': {
    value: 'Add a default billing address to your',
    location: 'client',
  },
  'account.selectaddress.error.addresses.text': {
    value: 'addresses',
    location: 'client',
  },
  'account.selectaddress.error.receivepost.text': {
    value: 'to receive post',
    location: 'client',
  },
  'account.selecteddefaultaddress.text': {
    value: 'Post will be sent to your default billing address, go to your',
    location: 'client',
  },
  'account.selecteddefaultaddress.changetext': {
    value: 'to change this',
    location: 'client',
  },
  'account.default.postal.address.label.text': {
    value: 'Default postal address',
    location: 'client',
  },
  'account.selecteddefaultaddress.label': {
    value: 'Postal address',
    location: 'client',
  },
  'account.smsunsubscribesuccess.title.text': {
    value: 'Successfully Opted Out',
    location: 'client',
  },
  'account.sms-opt-out.text': {
    value:
      'If you no longer wish to receive exclusive offers and early access to sales via SMS please confirm your mobile number below.',
    location: 'client',
  },
  'account.smsunsubscribesuccess.message': {
    value:
      'You have successfully opted out. You will no longer receive SMS marketing messages.',
    location: 'client',
  },
  'account.smsunsubscribesuccess.message.note': {
    value:
      'Please note: It can take up to 24 hours for your account to update.',
    location: 'client',
  },
  'account.smsunsubscribefailure.message': {
    value:
      'There was an error while unsubscribing from SMS marketing. Please, try again.',
    location: 'client',
  },
  'account.communicationoptin.title.text': {
    value: 'Communication opt-in',
    location: 'client',
  },
  'account.contactoptin.title.text': {
    value: 'Contact opt-in',
    location: 'client',
  },
  'account.sendmesms.title.text': {
    value: 'Send me sms',
    location: 'client',
  },
  'account.sms-opt-out.title': {
    value: 'SMS opt out',
    location: 'client',
  },
  'account.sms-opt-out.placeholder.text': {
    value: 'Phone Number',
    location: 'client',
  },
  'account.addedtoemailmessage.title.text': {
    value:
      'You have successfully been added to the %s newsletter email subscription service.',
    location: 'client',
  },
  'account.removedfromemailmessage.title.text': {
    value:
      'You have successfully been removed from the %s newsletter email subscription service.',
    location: 'client',
  },
  'account.errorremovingfromemailmessage.title.text': {
    value:
      'There was an error unsubscribing from the newsletter. Please try again later.',
    location: 'client',
  },
  'account.addedtosmsmessage.title.text': {
    value:
      'You have successfully been added to the %s newsletter sms subscription service.',
    location: 'client',
  },
  'account.removedfromsmsmessage.title.text': {
    value:
      'You have successfully been removed from the %s newsletter sms subscription service.',
    location: 'client',
  },
  'twobestsellers.title.text': {
    value: 'Two Best Sellers',
    location: 'client',
  },
  'account.errormessage.title.text': {
    value: 'There was an error saving your preferences. Please try again',
    location: 'client',
  },
  'form.marketingconsent.text.i_consent_to_receiving_marketing_material': {
    value: 'Yes please',
    location: 'client',
  },
  'form.marketingconsent.text.i_do_not_consent_to_receiving_marketing_material': {
    value: 'No thanks',
    location: 'client',
  },
  'form.marketingconsent.text.i_consent_to_receiving_marketing_material.sms': {
    value: 'Yes please',
    location: 'client',
  },
  'form.marketingconsent.text.i_do_not_consent_to_receiving_marketing_material.sms': {
    value: 'No thanks',
    location: 'client',
  },
  'account.messages.thankyou.message.title': {
    value: 'Thank you for your message',
    location: 'client',
  },
  'account.messages.file.uploads.error': {
    value: 'Failed to upload',
    location: 'client',
  },
  'account.messages.file.uploads.success': {
    value: 'Successfully uploaded',
    location: 'client',
  },
  'account.messages.file.size.error': {
    value: 'File size surpasses 10MB. Failed to upload',
    location: 'client',
  },
  'account.messages.file.format.error': {
    value: 'File format is not accepted. Failed to upload',
    location: 'client',
  },
  'account.messages.file.uploads.text': {
    value:
      'If you have a supporting image or document that will assist us with your query please use the facility below. We accept the following files (GIF/JPEG/JPG/MSWORD/ODT/PNG/PDF/TXT/ZIP)',
    location: 'client',
  },
  'account.messages.file.uploads.arialabel.remove': {
    value: 'Remove file',
    location: 'client',
  },
  'account.messages.file.uploads.button': {
    value: 'Upload a file',
    location: 'client',
  },
  'account.messages.thankyou.message.messagesent': {
    value:
      'Your message has been sent to our Customer Services department, you should receive a reply within the next 24 hours, however during busy periods response times may increase, therefore we ask our customers to allow up to 48 hours for a response to be made.',
    location: 'client',
  },
  'account.messages.thankyou.message.responsetime': {
    value:
      'Our response to your query will show in the Customer Service Contact Centre when you login to your account and will also be sent to your email address.',
    location: 'client',
  },
  'account.messages.thankyou.customer.service.link': {
    value: 'Go to your Customer Service Contact Centre',
    location: 'client',
  },
  'text.optional': {
    value: 'Optional',
    location: 'client',
  },
  'account.newaccount.createaccountform.button.register.text': {
    value: 'Create new account',
    location: 'client',
  },
  'account.login.socialloginerror.unknown.message': {
    value:
      'Sorry, there was an error logging you in. Please try again or contact customer services.',
    location: 'client',
  },
  'account.login.accountlocked.message': {
    value:
      "Your account is locked. Please click the 'Forgotton your password' link to validate and reset your password.",
    location: 'client',
  },
  'account.login.existingcustomers.loginerror.text': {
    value: 'The email address or password you entered were invalid',
    location: 'client',
  },
  'account.wishlist.add.text': {
    value: 'Add more products to your wishlist',
    location: 'client',
  },
  'account.wishlist.error': {
    value: 'There was an error loading your wishlist',
    location: 'client',
  },
  'account.wishlist.remove.item.error': {
    value: 'There was an error removing the item from your wishlist',
    location: 'client',
  },
  'account.wishlist.option.unavailable.text': {
    value: 'Out of Stock',
    location: 'client',
  },
  'account.order.addreview.subtitle': {
    value: 'Choose item(s) you’d like to review',
    location: 'client',
  },
  'account.order.addreview.yourreviews': {
    value: 'Your review(s)',
    location: 'client',
  },
  'account.order.addreview.remainingitems': {
    value: 'Remaining item(s) to review',
    location: 'client',
  },
  'account.order.addreview.error': {
    value: 'There was an error loading the review order page',
    location: 'client',
  },
  'account.order.deletereview.succesmessage': {
    value: 'You have successfully deleted your review for: ',
    location: 'client',
  },

  'account.cancelorder.error': {
    value: 'There was an error loading the cancel order page',
    location: 'client',
  },
  'account.cancelorder.products.error': {
    value: 'There was an error while cancelling the order products',
    location: 'client',
  },
  'account.cancelorder.selectall': {
    value: 'Select all items',
    location: 'client',
  },
  'account.cancelorder.acknowledgement': {
    value:
      'If you cancel items from your order, we will communicate acknowledgement by email.',
    location: 'client',
  },
  'account.cancelorder.subtitle': {
    value: 'Choose item(s) to cancel',
    location: 'client',
  },
  'account.cancelorder.righttocancel': {
    value: 'Right to Cancel',
    location: 'client',
  },
  'account.cancelorder.righttocancel.inaddition': {
    value: 'This right to cancel is in addition to our Returns Policy.',
    location: 'client',
  },
  'account.cancelorder.righttocancel.withoutgivingreason': {
    value:
      'You have the right to cancel this contract within 14 days without giving reason.',
    location: 'client',
  },
  'account.cancelorder.righttocancel.expireafter': {
    value:
      'The cancellation period will expire after 14 days from the day on which you acquire, or a third party other than the carrier and indicated by you acquires physical possession of the goods.',
    location: 'client',
  },
  'account.cancelorder.righttocancel.exercisetheright': {
    value:
      'To exercise the right to cancel, you must inform us of your decision to cancel this contract by a clear statement (e.g. by selecting the appropriate option below or a letter sent by post). You may use the attached model cancellation form, but it is not obligatory. If you cancel by selecting the appropriate option below, we will communicate to you an acknowledgement of receipt of such cancellation by email.',
    location: 'client',
  },
  'account.cancelorder.righttocancel.cancancel': {
    value:
      'You can cancel your order either in whole or in part by selecting the appropriate option below but please note that it may not always be possible to cancel all parts of your order prior to the order being despatched (due to the fact that the order may have already been despatched or may be in the process of being despatched). However, you may still cancel the contract in accordance with this right to cancel and our Returns Policy once the order has been despatched.',
    location: 'client',
  },
  'account.cancelorder.effectsofcancellation': {
    value: 'Effects of Cancellation',
    location: 'client',
  },
  'account.cancelorder.effectsofcancellation.cancelthiscontract': {
    value:
      'If you cancel this contract, we will reimburse to you all payments received from you, including the cost of delivery (except for supplementary costs arising if you chose a type of delivery other than the least expensive type of standard delivery offered by us).',
    location: 'client',
  },
  'account.cancelorder.effectsofcancellation.maymakededuction': {
    value:
      'We may make a deduction from the reimbursement for loss in value of any goods supplied if the loss is the result of unnecessary handling by you.',
    location: 'client',
  },
  'account.cancelorder.effectsofcancellation.reimbursement': {
    value:
      'We will make the reimbursement without undue delay and not later than:',
    location: 'client',
  },
  'account.cancelorder.effectsofcancellation.reimbursementlist.goodssupplied': {
    value:
      '(a) 14 days after the day we receive back from you any goods supplied; or',
    location: 'client',
  },
  'account.cancelorder.effectsofcancellation.reimbursementlist.ifearlier': {
    value:
      '(b) (if earlier) 14 days after the day you provide evidence that you have returned the goods; or',
    location: 'client',
  },
  'account.cancelorder.effectsofcancellation.reimbursementlist.nogoods': {
    value:
      '(c) if there were no goods supplied, 14 days after the day on which we are informed about your decision to cancel the contract.',
    location: 'client',
  },
  'account.cancelorder.effectsofcancellation.sameMeansOfPayment': {
    value:
      'We will make the reimbursement using the same means of payment as you used for the initial transaction, unless you have expressly agreed otherwise. In any event, you will not incur any fees as a result of the reimbursement. You will have to bear the direct cost of returning the goods.',
    location: 'client',
  },
  'account.cancelorder.modelcancellationform': {
    value: 'Model Cancellation Form',
    location: 'client',
  },
  'account.cancelorder.modelcancellationform.to': {
    value:
      'To: The Hut.com Limited, Meridian House, Gadbrook Park, Rudheath, Cheshire, CW9 7RA.',
    location: 'client',
  },
  'account.cancelorder.modelcancellationform.notice': {
    value:
      'I hereby give notice that I cancel my contract of sale of the following goods',
    location: 'client',
  },
  'account.cancelorder.modelcancellationform.orderedon': {
    value: 'Ordered on',
    location: 'client',
  },
  'account.cancelorder.modelcancellationform.nameofcustomer': {
    value: 'Name of customer',
    location: 'client',
  },
  'account.cancelorder.modelcancellationform.address': {
    value: 'Address of customer',
    location: 'client',
  },
  'account.cancelorder.modelcancellationform.signature': {
    value: 'Signature of customer (only if sent by paper)',
    location: 'client',
  },
  'account.cancelorder.modelcancellationform.date': {
    value: 'Date',
    location: 'client',
  },
  'account.login.error.failed_login': {
    value: 'Oops. Your login attempt failed, please try again.',
    location: 'client',
  },
  'account.emailsignup.instruction': {
    value: 'Sign up to our newsletter for exclusive offers',
    location: 'client',
  },
  'account.emailsignup.duplicateemail.error.message': {
    value: 'Your E-mail address is already in our database.',
    location: 'client',
  },
  'account.login.error.account_locked': {
    value: 'This account is locked. Please reset your password.',
    location: 'client',
  },
  'account.login.error.pending_action': {
    value:
      'This account is pending further action. Please contact customer service for assistance.',
    location: 'client',
  },
  'account.login.error.email_verification': {
    value: 'Please check your email to verify your account',
    location: 'client',
  },
  'account.login.error.account_verification': {
    value: 'Please verify your account before logging in.',
    location: 'client',
  },
  'account.login.error.invalid_data': {
    value: 'The email address or password you entered were invalid.',
    location: 'client',
  },
  'account.registration.error.invalid_data': {
    value: "There are errors with the information you've provided.",
    location: 'client',
  },
  'account.login.error.incorrect_password': {
    value: 'The email address or password you entered were invalid',
    location: 'client',
  },
  'account.login.error.invalid_social_authentication_token': {
    value: 'We were unable to log you in, using your social account provider',
    location: 'client',
  },
  'profile-service.translations.profile.my.beauty.header.text': {
    value: 'My Profile',
    location: 'client',
  },
  'profile-service.translations.profile.pet.beauty.header.text': {
    value: 'My Profile',
    location: 'client',
  },
  'profile-service.translations.profile.litter.beauty.header.text': {
    value: 'My Profile',
    location: 'client',
  },
  'profile-service.translations.profile.litter.purpose.description.text.p3': {
    value: 'Placeholder copy',
    location: 'client',
  },
  'profile-service.translations.profile.petprofile.beauty.header.text': {
    value: 'My Profile',
    location: 'client',
  },
  'profile-service.translations.profile.testpetprofile.beauty.header.text': {
    value: 'My Profile',
    location: 'client',
  },
  'profile-service.translations.profile.myprofile.beauty.header.text': {
    value: 'My Profile',
    location: 'client',
  },
  'profile-service.translations.profile.myprofile.purpose.description.text.p3': {
    value: 'Fill in your profile',
    location: 'client',
  },
  'profile-service.translations.profile.pet.purpose.description.text.p3': {
    value: 'Fill in your profile',
    location: 'client',
  },
  'profile-service.translations.profile.petprofile.purpose.description.text.p3': {
    value: 'Fill in your profile',
    location: 'client',
  },
  'profile-service.translations.profile.testpetprofile.purpose.description.text.p3': {
    value: 'Fill in your profile',
    location: 'client',
  },
  'profile-service.translations.profile.cbpreferencecentre.beauty.header.text': {
    value: 'Tell Us About You',
    location: 'client',
  },
  'profile-service.translations.profile.cbpreferencecentreeurope.beauty.header.text': {
    value: 'Tell Us About You',
    location: 'client',
  },
  'profile-service.translations.profile.icons.beauty.header.text': {
    value: 'Vote for your favourite beauty Icons',
    location: 'client',
  },
  'profile-service.translations.profile.icons2023.beauty.header.text': {
    value: 'Vote for your favourite beauty Icons',
    location: 'client',
  },
  'profile-service.translations.profile.newbeautyprofileplus.beauty.header.text': {
    value: 'My Beauty Profile',
    location: 'client',
  },
  'profile-service.translations.profile.preferenceCentre.beauty.header.text': {
    value: 'My Beauty Profile',
    location: 'client',
  },
  'profile-service.translations.profile.cbpreferencecentre.purpose.description.text.p3': {
    value:
      "Don't forget to let us know your birthday so we can send you a treat for your special month! Want to receive curated beauty recommendations? Answer four quick questions and we'll make sure they're tailored just for you.",
    location: 'client',
  },
  'profile-service.translations.profile.cbpreferencecentreeurope.purpose.description.text.p3': {
    value:
      "Don't forget to let us know your birthday so we can send you a treat for your special month! Want to receive curated beauty recommendations? Answer four quick questions and we'll make sure they're tailored just for you.",
    location: 'client',
  },
  'profile-service.translations.profile.icons.purpose.description.text.p3': {
    value: 'Choose your favourite mascara, shampoo and more...',
    location: 'client',
  },
  'profile-service.translations.profile.icons2023.purpose.description.text.p3': {
    value: 'Choose your favourite mascara, shampoo and more...',
    location: 'client',
  },
  'profile-service.translations.profile.newBeautyprofileplus.purpose.description.text.p3': {
    value:
      'Complete our short beauty profile for 500 Plus +Points (1 time only), plus personalisation of offers and recommendations.',
    location: 'client',
  },
  'profile-service.translations.profile.preferenceCentre.purpose.description.text.p3': {
    value:
      'We want to make sure you receive the best beauty recommendations that are suited to your individual needs. This will help us to make sure we are giving you the best tips, advice and product suggestions that are tailored to you.<br><br>Are you ready?',
    location: 'client',
  },
  'profile-service.translations.profile.my.purpose.description.text.p3': {
    value:
      "Completing Myprofile will take two minutes and help us personalise your Myprotein experience, you'll even receive and exclusive Birthday gift every year!",
    location: 'client',
  },
  'profile-service.skip.this.text': {
    value:
      '<a title="Account Home" href="/accountHome.account">I want to come back to this later &gt;</a>',
    location: 'client',
  },
  'profile-service.submission.successful.message': {
    value: 'Thank you!',
    location: 'client',
  },
  'profile-service.submission.successful.text': {
    value: 'Your profile has been saved',
    location: 'client',
  },
  'profile-service.submission.successful.return': {
    value: 'Return to profile questions',
    location: 'client',
  },
  'multipageform.previous.button.text': {
    value: 'Previous',
    location: 'client',
  },
  'mulitpageform.next.button.text': {
    value: 'Next',
    location: 'client',
  },
  'multipageform.submit.button.text': {
    value: 'Submit',
    location: 'client',
  },
  'profile-service.questionnumber.question.text': {
    value: 'Question',
    location: 'client',
  },
  'multipageform.submit.header.text': {
    value: 'Submit Page',
    location: 'client',
  },
  'multipageform.minimum.required.error.text': {
    value: 'This is a required field',
    location: 'client',
  },
  'multipageform.pattern.error.text': {
    value: 'This field must be valid',
    location: 'client',
  },
  'multipageform.minimum.selected.error.text': {
    value: 'You need to select a minimum of %d elements',
    location: 'client',
  },
  'multipageform.maximum.length.text': {
    value: 'This field must have at most ${max} characters',
    location: 'client',
  },
  'multipageform.minimum.length.error.text': {
    value: 'This field must have at least ${min} characters',
    location: 'client',
  },
  'multipageform.maximum.selected.text': {
    value: 'Select up to %d responses',
    location: 'client',
  },
  'multipageform.dropdown.placeholder.text': {
    value: 'Please select an answer',
    location: 'client',
  },
  'multipageform.selected.options.text': {
    value: 'Selected: %d/%d',
    location: 'client',
  },
  'multipageform.unsupported.component.error.text': {
    value: 'An error has occured when loading form',
    location: 'client',
  },
  'account.profile.initialsurface.edit.button.text': {
    value: 'Edit your profile',
    location: 'client',
  },
  'account.profile.initialsurface.create.button.text': {
    value: 'Create your profile',
    location: 'client',
  },
  'account.profile.error.loading.profile.text': {
    value: 'There was an error getting profile information, please try again.',
    location: 'client',
  },
  'account.profile.error.loading.petprofile.text': {
    value: 'Error. You need to add a pet.',
    location: 'client',
  },
  'account.profile.error.submitting.profile.text': {
    value: 'Error while submitting your profile.',
    location: 'client',
  },
  'account.profile.error.cannotsubmit.profile.text': {
    value: 'Error. Cannot submit profile.',
    location: 'client',
  },
  'gravity.multioptionselector.minselected.error': {
    value: 'You need to select a minimum of %d elements',
    location: 'client',
  },
  'form.fieldname.title': {
    value: 'Title',
    location: 'client',
  },
  'form.fieldname.email': {
    value: 'Email Address',
    location: 'client',
  },
  'account.emailsignup.emailaddress': {
    value: 'Email Address',
    location: 'client',
  },
  'form.fieldname.password': {
    value: 'Password',
    location: 'client',
  },
  'form.fieldname.password.helpertext': {
    value: 'Include a minimum of 6 characters, and contain at least 1 number',
    location: 'client',
  },
  'form.fieldname.fullname': {
    value: 'Full Name',
    location: 'client',
  },
  'form.fieldname.firstName': {
    value: 'First Name',
    location: 'client',
  },
  'form.fieldname.surname': {
    value: 'Full Surname',
    location: 'client',
  },
  'form.fieldname.newemailaddress': {
    value: 'New email address',
    location: 'client',
  },
  'form.fieldname.newpassword': {
    value: 'New password',
    location: 'client',
  },
  'form.fieldname.currentpassword': {
    value: 'Please enter your password',
    location: 'client',
  },
  'form.fieldname.country': {
    value: 'Country',
    location: 'client',
  },
  'account.details.edit.password.heading': {
    value: 'Edit your password',
    location: 'client',
  },
  'account.details.edit.email.heading': {
    value: 'Edit your email address',
    location: 'client',
  },
  'account.details.edit.fullname.heading': {
    value: 'Edit your full name',
    location: 'client',
  },
  'form.fieldname.phonenumber': {
    value: 'Mobile Number',
    location: 'client',
  },
  'form.fieldname.phonenumber.helpertext': {
    value: 'We will use this number to send occasional promotional messages',
    location: 'client',
  },
  'form.fieldname.referrercode': {
    value: 'Referral Code',
    location: 'client',
  },
  'form.fieldname.referrercode.helpertext': {
    value: 'Enter if you have a referral code. It will be applied at basket',
    location: 'client',
  },
  'form.fieldname.loyaltyoptin': {
    value: 'Include me in the rewards program. %s',
    location: 'client',
  },
  'form.title.placeholder': {
    value: 'Please select an option...',
    location: 'client',
  },
  'account.login.passwordreset.title': {
    value: 'Reset your password',
    location: 'client',
  },
  'account.login.passwordreset.subtext.text': {
    value:
      'Must be a minimum of 6 characters long and contain at least one number.',
    location: 'client',
  },
  'account.login.passwordreset.button.text': {
    value: 'Resend Link',
    location: 'client',
  },
  'account.login.passwordreset.button.text.arialabel': {
    value: 'Resend Link',
    location: 'client',
  },
  'account.login.passwordreset.badtoken.expired.title': {
    value: 'Your link has expired',
    location: 'client',
  },
  'account.login.passwordreset.badtoken.expired.subtext': {
    value:
      'The reset link has expired. Please request another password reset link by entering your email address below.',
    location: 'client',
  },
  'account.login.passwordreset.badtoken.invalid.title': {
    value: 'Your link is invalid',
    location: 'client',
  },
  'account.login.passwordreset.badtoken.invalid.subtext': {
    value:
      'The reset link could not be verified. Please request another password reset link by entering your email address below.',
    location: 'client',
  },
  'account.login.passwordreset.badtoken.inactive.title': {
    value: 'Your link is no longer active',
    location: 'client',
  },
  'account.login.passwordreset.badtoken.inactive.subtext': {
    value:
      'The reset link is no longer active and has already been used. You can request another password reset link by entering your email address below.',
    location: 'client',
  },
  'account.login.passwordreset.badtoken.submitbutton.text': {
    value: 'Resend link',
    location: 'client',
  },
  'account.login.passwordreset.badtoken.goback.text': {
    value: 'Back to login page',
    location: 'client',
  },
  'account.login.passwordreset.badtoken.error': {
    value: 'Something went wrong',
    location: 'client',
  },
  'account.passwordreset.badtoken.success.title': {
    value: 'Check your inbox',
    location: 'client',
  },
  'account.passwordreset.badtoken.success.summary': {
    value: 'Reset details have been sent to the following email address:',
    location: 'client',
  },
  'account.passwordreset.badtoken.notreceived.title': {
    value: 'Not received your reset link?',
    location: 'client',
  },
  'account.passwordreset.badtoken.notreceived.bullets': {
    value:
      '<ul><li>Check your spam folder</li><li>Check you have spelled the email address you entered correctly</li><li>Wait 15 minutes before trying again. Your request may not have been processed yet.</li></ul>',
    location: 'client',
  },
  'account.passwordreset.badtoken.notreceived.cta': {
    value: 'Still not received? Resend link',
    location: 'client',
  },
  'account.login.passwordreset.success.title': {
    value: 'Check your inbox',
    location: 'client',
  },
  'account.login.passwordreset.goback.text': {
    value: 'Go back to login',
    location: 'client',
  },
  'account.login.passwordreset.notreceived.title': {
    value: 'Not received your reset link?',
    location: 'client',
  },
  'account.login.passwordreset.notreceived.bullets': {
    value:
      '<ul><li>Check your spam folder.</li><li>Check you have spelled the email address you entered correctly.</li><li>Wait 15 minutes before trying again. Your request may not have been processed yet.</li></ul>',
    location: 'client',
  },
  'account.login.passwordreset.notreceived.cta': {
    value: 'Still not received? Start again',
    location: 'client',
  },
  'account.login.passwordreset.success.summary': {
    value: 'Reset details have been sent to the following email address:',
    location: 'client',
  },
  'account.login.passwordreset.reset.button.text': {
    value: 'Save Changes',
    location: 'client',
  },
  'account.login.passwordreset.reset.button.text.arialabel': {
    value: 'Reset your password',
    location: 'client',
  },
  'account.login.sociallink.success.title': {
    value: 'Verify your email address',
    location: 'client',
  },
  'account.login.sociallink.goback.text': {
    value: 'Go back to login',
    location: 'client',
  },
  'account.newaccount.createaccountform.termsandconditions.text.pt1': {
    value: 'By proceeding, you are confirming that you agree to our',
    location: 'client',
  },
  'account.newaccount.createaccountform.alternatetermsandconditions.text.pt1': {
    value: '(Alternate)By proceeding, you are confirming that you agree to our',
    location: 'client',
  },
  'account.newaccount.createaccountform.termsandconditions.text.pt2': {
    value: 'Terms and Conditions',
    location: 'client',
  },
  'account.newaccount.createaccountform.alternatetermsandconditions.text.pt2': {
    value: '(Alternate)Terms and Conditions',
    location: 'client',
  },
  'account.newaccount.createaccountform.termsandconditions.text.pt3': {
    value: 'and',
    location: 'client',
  },
  'account.newaccount.createaccountform.alternatetermsandconditions.text.pt3': {
    value: '(Alternate)and',
    location: 'client',
  },
  'account.newaccount.createaccountform.termsandconditions.text.pt4': {
    value: 'Privacy Policy',
    location: 'client',
  },
  'account.newaccount.createaccountform.alternatetermsandconditions.text.pt4': {
    value: '(Alternate)Privacy Policy',
    location: 'client',
  },
  'account.newaccount.createaccountform.termsandconditions.text.pt5': {
    value: '',
    location: 'client',
  },
  'account.newaccount.createaccountform.alternatetermsandconditions.text.pt5': {
    value: '(Alternate)',
    location: 'client',
  },
  'account.newaccount.createaccountform.termsandconditions.link.terms': {
    value: 'info/terms-conditions.list',
    location: 'client',
  },
  'account.newaccount.createaccountform.alternatetermsandconditions.link.terms': {
    value: '(Alternate)info/terms-conditions.list',
    location: 'client',
  },
  'account.newaccount.createaccountform.termsandconditions.link.privacy': {
    value: 'info/privacy-policy.list',
    location: 'client',
  },
  'account.newaccount.createaccountform.alternatetermsandconditions.link.privacy': {
    value: '(Alternate)info/privacy-policy.list',
    location: 'client',
  },
  'account.login.sociallink.notreceived.title': {
    value: 'Not received your reset link?',
    location: 'client',
  },
  'account.login.sociallink.notreceived.bullets': {
    value:
      '<ul><li>Check your spam folder.</li><li>Check you have spelled the email address you entered correctly.</li><li>Wait 15 minutes before trying again. Your request may not have been processed yet.</li></ul>',
    location: 'client',
  },
  'account.login.sociallink.notreceived.cta': {
    value: 'Still not received? Resend link',
    location: 'client',
  },
  'account.login.sociallink.success.summary': {
    value: 'Reset details have been sent to the following email address:',
    location: 'client',
  },
  'account.social.opt.in.title': {
    value: 'One last thing...',
    location: 'client',
  },
  'account.social.opt.in.button.text': {
    value: 'Continue',
    location: 'client',
  },
  'socialloginverificationoptions.title.text': {
    value: 'Link an existing account',
    location: 'client',
  },
  'socialloginverificationoptions.text.text': {
    value:
      'An account with your email already exists. To complete your registration, please verify that you own this account.',
    location: 'client',
  },
  'socialloginverificationoptions.emailoption.text': {
    value: 'Send a verification email',
    location: 'client',
  },
  'socialloginverificationoptions.divider.text': {
    value: 'Or simply sign in',
    location: 'client',
  },
  'socialloginverificationoptions.loginoption.text': {
    value: 'Proceed to login page',
    location: 'client',
  },
  'socialloginmissinginformation.title.text': {
    value: 'About you',
    location: 'client',
  },
  'socialloginmissinginformation.text.text': {
    value:
      'Could you enter your details below so we can keep you updated on your orders? You only need to do it once.',
    location: 'client',
  },
  'socialloginmissinginformation.form.submit.text': {
    value: 'Submit',
    location: 'client',
  },
  'verificationtokenlandingpage.title.text': {
    value: 'Almost Done',
    location: 'client',
  },
  'verificationtokenlandingpage.text.text': {
    value:
      'Please click on the button below to complete your email verification',
    location: 'client',
  },
  'verificationtokenlandingpage.button.text': {
    value: 'Complete',
    location: 'client',
  },
  'verificationtokenerror.invalid.title.text': {
    value: 'An unknown error occured',
    location: 'client',
  },
  'verificationtokenerror.invalid.text.text': {
    value: 'Your account could not be verified',
    location: 'client',
  },
  'verificationtokenerror.invalid.button.text': {
    value: 'Go to login',
    location: 'client',
  },
  'verificationtokenerror.expired.title.text': {
    value: 'The link you followed has expired',
    location: 'client',
  },
  'verificationtokenerror.expired.text.text': {
    value: 'Please go back to login and try again',
    location: 'client',
  },
  'verificationtokenerror.resendbutton.text': {
    value: 'Go to login',
    location: 'client',
  },
  'verificationtokenerror.used.title.text': {
    value: 'The link has been used',
    location: 'client',
  },
  'verificationtokenerror.used.text.text': {
    value: 'Your account is already verified',
    location: 'client',
  },
  'verificationtokenerror.used.button.text': {
    value: 'Go to login',
    location: 'client',
  },
  'account.login.guestcustomers.subscription.text': {
    value:
      'To purchase a subscription-based product, please log in or create an account.',
    location: 'client',
  },
  'account.login.unknownerror.text': {
    value: 'Something went wrong',
    location: 'client',
  },
  'account.sociallogin.facebook.text': {
    value: 'Facebook',
    location: 'client',
  },
  'account.sociallogin.twitter.text': {
    value: 'Twitter',
    location: 'client',
  },
  'account.sociallogin.google.text': {
    value: 'Google',
    location: 'client',
  },
  'account.sociallogin.wechat.text': {
    value: 'WeChat',
    location: 'client',
  },
  'account.sociallogin.weibo.text': {
    value: 'Weibo',
    location: 'client',
  },
  'account.details.error.title': {
    value: "Account Details can't be found",
    location: 'client',
  },
  'account.details.error.text': {
    value: 'There was an error whilst trying to find your account details',
    location: 'client',
  },
  'account.details.edit.phonenumber.heading': {
    value: 'Edit your mobile number',
    location: 'client',
  },
  'cookie.text.cookiemessage': {
    value:
      'We use cookies to give you the best browsing experience. By using our website, you agree to our use of cookies in accordance with our privacy policy and cookie policy',
    location: 'client',
  },
  'cookie.button.accept': {
    value: 'OK',
    location: 'client',
  },
  'general.cookie.notice.button.arialabel': {
    value: 'Accept cookies',
    location: 'client',
  },
  'general.modal.close.button.arialabel': {
    value: 'Close',
    location: 'client',
  },
  'general.modal.close.button.label': {
    value: 'Close',
    location: 'client',
  },
  'general.header.logo.arialabel': {
    value: '%s Home Page',
    location: 'client',
  },
  'titles.end.text': {
    value: 'Whitelabel',
    location: 'client',
  },
  'general.internationaloverlay.header.text': {
    value: 'Welcome',
    location: 'client',
  },
  'internationalredirect.gobuttontext.ar.text': {
    value: 'Go to',
    location: 'client',
  },
  'internationalredirect.gobuttontext.az.text': {
    value: 'Go to',
    location: 'client',
  },
  'internationalredirect.gobuttontext.bg.text': {
    value: 'Отидете на',
    location: 'client',
  },
  'internationalredirect.gobuttontext.bs.text': {
    value: 'Idi na',
    location: 'client',
  },
  'internationalredirect.gobuttontext.cs.text': {
    value: 'Přejděte na',
    location: 'client',
  },
  'internationalredirect.gobuttontext.da.text': {
    value: 'Gå til',
    location: 'client',
  },
  'internationalredirect.gobuttontext.de.text': {
    value: 'Weiter zu',
    location: 'client',
  },
  'internationalredirect.gobuttontext.el.text': {
    value: 'Μεταφορά στο',
    location: 'client',
  },
  'internationalredirect.gobuttontext.en.text': {
    value: 'Go to',
    location: 'client',
  },
  'internationalredirect.gobuttontext.es.text': {
    value: 'Ir a',
    location: 'client',
  },
  'internationalredirect.gobuttontext.fi.text': {
    value: 'Siirry',
    location: 'client',
  },
  'internationalredirect.gobuttontext.fr.text': {
    value: 'Aller à',
    location: 'client',
  },
  'internationalredirect.gobuttontext.hr.text': {
    value: 'Idi na',
    location: 'client',
  },
  'internationalredirect.gobuttontext.hu.text': {
    value: 'Irány a',
    location: 'client',
  },
  'internationalredirect.gobuttontext.id.text': {
    value: 'Go to',
    location: 'client',
  },
  'internationalredirect.gobuttontext.is.text': {
    value: 'Go to',
    location: 'client',
  },
  'internationalredirect.gobuttontext.it.text': {
    value: 'Vai su',
    location: 'client',
  },
  'internationalredirect.gobuttontext.ja.text': {
    value: 'クリック○:',
    location: 'client',
  },
  'internationalredirect.gobuttontext.ko.text': {
    value: '이동',
    location: 'client',
  },
  'internationalredirect.gobuttontext.lt.text': {
    value: 'Eiti į',
    location: 'client',
  },
  'internationalredirect.gobuttontext.nb.text': {
    value: 'Gå til',
    location: 'client',
  },
  'internationalredirect.gobuttontext.nl.text': {
    value: 'Ga naar',
    location: 'client',
  },
  'internationalredirect.gobuttontext.pl.text': {
    value: 'Idź do',
    location: 'client',
  },
  'internationalredirect.gobuttontext.pt.text': {
    value: 'Ir para',
    location: 'client',
  },
  'internationalredirect.gobuttontext.ro.text': {
    value: 'Intra pe',
    location: 'client',
  },
  'internationalredirect.gobuttontext.ru.text': {
    value: 'Перейти на',
    location: 'client',
  },
  'internationalredirect.gobuttontext.sk.text': {
    value: 'Prejdite na',
    location: 'client',
  },
  'internationalredirect.gobuttontext.sl.text': {
    value: 'Pojdi na',
    location: 'client',
  },
  'internationalredirect.gobuttontext.sr.text': {
    value: 'Idi na',
    location: 'client',
  },
  'internationalredirect.gobuttontext.sv.text': {
    value: 'Gå till',
    location: 'client',
  },
  'internationalredirect.gobuttontext.uk.text': {
    value: 'Пройдіть',
    location: 'client',
  },
  'internationalredirect.gobuttontext.vi.text': {
    value: 'Đi đến',
    location: 'client',
  },
  'internationalredirect.gobuttontext.zh.text': {
    value: '转到',
    location: 'client',
  },
  'internationalredirect.heading.ar.text': {
    value: 'Welcome',
    location: 'client',
  },
  'internationalredirect.heading.az.text': {
    value: 'Welcome',
    location: 'client',
  },
  'internationalredirect.heading.bg.text': {
    value: 'Добре дошли',
    location: 'client',
  },
  'internationalredirect.heading.bs.text': {
    value: 'Dobrodošli',
    location: 'client',
  },
  'internationalredirect.heading.cs.text': {
    value: 'Vítejte!',
    location: 'client',
  },
  'internationalredirect.heading.da.text': {
    value: 'Velkommen',
    location: 'client',
  },
  'internationalredirect.heading.de.text': {
    value: 'Willkommen!',
    location: 'client',
  },
  'internationalredirect.heading.el.text': {
    value: 'Καλώς Ήρθατε',
    location: 'client',
  },
  'internationalredirect.heading.en.text': {
    value: 'Welcome',
    location: 'client',
  },
  'internationalredirect.heading.es.text': {
    value: 'bienvenido',
    location: 'client',
  },
  'internationalredirect.heading.fi.text': {
    value: 'Tervetuloa',
    location: 'client',
  },
  'internationalredirect.heading.fr.text': {
    value: 'Bienvenue!',
    location: 'client',
  },
  'internationalredirect.heading.hr.text': {
    value: 'Dobrodošli',
    location: 'client',
  },
  'internationalredirect.heading.hu.text': {
    value: 'Üdvözöljük!',
    location: 'client',
  },
  'internationalredirect.heading.id.text': {
    value: 'Welcome',
    location: 'client',
  },
  'internationalredirect.heading.is.text': {
    value: 'Welcome',
    location: 'client',
  },
  'internationalredirect.heading.it.text': {
    value: 'Benvenuto',
    location: 'client',
  },
  'internationalredirect.heading.ja.text': {
    value: 'での価格は、こちらでチェック',
    location: 'client',
  },
  'internationalredirect.heading.ko.text': {
    value: '안녕하세요!',
    location: 'client',
  },
  'internationalredirect.heading.lt.text': {
    value: 'Sveiki atvykę',
    location: 'client',
  },
  'internationalredirect.heading.nb.text': {
    value: 'Velkommen',
    location: 'client',
  },
  'internationalredirect.heading.nl.text': {
    value: 'Welkom',
    location: 'client',
  },
  'internationalredirect.heading.pl.text': {
    value: 'Witamy',
    location: 'client',
  },
  'internationalredirect.heading.pt.text': {
    value: 'Bem-vindo',
    location: 'client',
  },
  'internationalredirect.heading.ro.text': {
    value: 'Bine ai venit',
    location: 'client',
  },
  'internationalredirect.heading.ru.text': {
    value: 'Добро пожаловать',
    location: 'client',
  },
  'internationalredirect.heading.sk.text': {
    value: 'Vitajte!',
    location: 'client',
  },
  'internationalredirect.heading.sl.text': {
    value: 'Dobrodošli',
    location: 'client',
  },
  'internationalredirect.heading.sr.text': {
    value: 'Dobrodošli',
    location: 'client',
  },
  'internationalredirect.heading.sv.text': {
    value: 'Välkommen',
    location: 'client',
  },
  'internationalredirect.heading.uk.text': {
    value: 'Ласкаво просимо',
    location: 'client',
  },
  'internationalredirect.heading.vi.text': {
    value: 'Xin chào!',
    location: 'client',
  },
  'internationalredirect.heading.zh.text': {
    value: '欢迎',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.ar.text': {
    value: ', would you like to see our ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.az.text': {
    value: ', would you like to see our ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.bg.text': {
    value: ', искате ли да посетите нашия ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.bs.text': {
    value: ', da li bi željeli vidjeti našu ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.cs.text': {
    value: 'Chtěli byste se podívat na naší ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.da.text': {
    value: 'Vi har en ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.de.text': {
    value: 'bist. Möchtest du unsere ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.el.text': {
    value: ', θα θέλατε να μεταφερθείτε στο ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.en.text': {
    value: ', would you like to see our ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.es.text': {
    value: 'Te gustaría visitarnos en la web ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.fi.text': {
    value: 'Haluaisitteko vierailla ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.fr.text': {
    value: ', voulez-vous voir notre site ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.hr.text': {
    value: ', biste li voljeli vidjeti našu ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.hu.text': {
    value: 'van, megnézné a ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.id.text': {
    value: ', would you like to see our ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.is.text': {
    value: ', would you like to see our ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.it.text': {
    value: ', vuoi consultare il nostro sito ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.ja.text': {
    value: 'にお住まいですか？',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.ko.text': {
    value: '에 계신것으로 확인됩니다.',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.lt.text': {
    value: ', ar norėtumėte pamatyti Mūsų ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.nb.text': {
    value: ', vil du se den ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.nl.text': {
    value: ' bent, wil je graag doorverwezen worden naar onze ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.pl.text': {
    value: ', czy chcesz wejść na naszą ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.pt.text': {
    value: 'Gostaria de ver nosso site ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.ro.text': {
    value: 'Vrei sa vezi site-ul ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.ru.text': {
    value: ', вы хотели бы видеть наш ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.sk.text': {
    value: 'Chceli by ste sa pozrieť na našej ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.sl.text': {
    value: 'in bi morda raje obiskali našo ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.sr.text': {
    value: ', da li bi željeli vidjeti našu ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.sv.text': {
    value: ', skulle du vilja gå till vår ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.uk.text': {
    value: 'Чи бажаєте Ви побачити наш ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.vi.text': {
    value: ', bạn muốn xem trang web ',
    location: 'client',
  },
  'internationalredirect.postcountryexplanation.zh.text': {
    value: '，何不访问我们的 ',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation': {
    value: 'currency?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.ar.text': {
    value: 'currency?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.az.text': {
    value: 'currency?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.bg.text': {
    value: 'валута?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.bs.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.cs.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.da.text': {
    value: '.',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.de.text': {
    value: 'Währung?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.el.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.en.text': {
    value: 'currency?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.es.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.fi.text': {
    value: 'hinnat?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.fr.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.hr.text': {
    value: 'valutom?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.hu.text': {
    value: 'pénznemben?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.id.text': {
    value: 'currency?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.is.text': {
    value: 'currency?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.it.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.ja.text': {
    value: 'での価格は、こちらでチェック！',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.ko.text': {
    value: '(원화) 가격을 확인하시겠습니까?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.lt.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.nb.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.nl.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.pl.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.pt.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.ro.text': {
    value: 'moneda?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.ru.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.sk.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.sl.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.sr.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.sv.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.uk.text': {
    value: '?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.vi.text': {
    value: 'tiền tệ?',
    location: 'client',
  },
  'internationalredirect.postcurrencyexplanation.zh.text': {
    value: '？',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.ar.text': {
    value: 'It looks like you are in the',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.az.text': {
    value: 'It looks like you are in the',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.bg.text': {
    value: 'Забелязахме, че сте във ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.bs.text': {
    value: 'Čini se da ste u ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.cs.text': {
    value: 'Vypadá to, že jste v ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.da.text': {
    value: 'Det ser ud til du er i ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.de.text': {
    value: 'Es sieht so aus, als ob du in ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.el.text': {
    value: 'Φαίνεται ότι βρίσκεστε στην ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.en.text': {
    value: 'It looks like you are in the',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.es.text': {
    value: 'Parece que estás en ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.fi.text': {
    value: 'Näyttää siltä, että olette ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.fr.text': {
    value: 'Il semblerait que vous soyez en ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.hr.text': {
    value: 'Izgleda da živite u ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.hu.text': {
    value: 'Úgy tűnik ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.id.text': {
    value: 'It looks like you are in ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.is.text': {
    value: 'It looks like you are in ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.it.text': {
    value: 'Sembra che tu sia in ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.ja.text': {
    value: 'お客様は',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.ko.text': {
    value: '고객님은 현재',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.lt.text': {
    value: 'Atrodo, esate ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.nb.text': {
    value: 'Det ser ut som om du er i ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.nl.text': {
    value: 'Het lijkt dat je in ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.pl.text': {
    value: 'Wygląda na to, że jesteś w ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.pt.text': {
    value: 'Para que está no ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.ro.text': {
    value: 'Se pare ca esti bazat in ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.ru.text': {
    value: 'Похоже, что вы находитесь в ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.sk.text': {
    value: 'Vyzerá to, že ste na ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.sl.text': {
    value: 'Se nahajate v ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.sr.text': {
    value: 'Čini se da ste u ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.sv.text': {
    value: 'Det verkar som att du är i ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.uk.text': {
    value: 'Схоже, що Ви знаходитесь у ',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.vi.text': {
    value: 'Dường như bạn đang ở',
    location: 'client',
  },
  'internationalredirect.precountryexplanation.zh.text': {
    value: '看起来您在',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation': {
    value:
      'It looks like you are in the UK, would you like to see our UK site with',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.ar.text': {
    value: 'site with ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.az.text': {
    value: 'site with ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.bg.text': {
    value: 'сайт в ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.bs.text': {
    value: 'stranicu sa cijenama u ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.cs.text': {
    value: 'stránku s cenami v',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.da.text': {
    value: 'side hvor alle priserne også er angivet i ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.de.text': {
    value: 'Seite in der ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.el.text': {
    value: 'site και το νόμισμα της συναλλαγής να είναι',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.en.text': {
    value: 'site with ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.es.text': {
    value: 'con precios en',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.fi.text': {
    value: 'sivustollamme joka sisältää ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.fr.text': {
    value: 'avec les prix en',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.hr.text': {
    value: 'stranicu s pripadajućom',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.hu.text': {
    value: 'oldalunkat',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.id.text': {
    value: 'site with ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.is.text': {
    value: 'site with ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.it.text': {
    value: 'con i prezzi in',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.ja.text': {
    value: 'サイトにて、',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.ko.text': {
    value: '웹사이트로 이동하여',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.lt.text': {
    value: 'svetainę su',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.nb.text': {
    value: 'siden som inkluderer priser i',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.nl.text': {
    value: 'website met',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.pl.text': {
    value: 'stronę z cenami w ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.pt.text': {
    value: 'com preços em ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.ro.text': {
    value: 'cu prețuri în ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.ru.text': {
    value: 'сайт с валютой',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.sk.text': {
    value: 'stránke s cenami v',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.sl.text': {
    value: 'spletno stran s cenami v ',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.sr.text': {
    value: 'stranicu sa',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.sv.text': {
    value: 'sida där priserna är i',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.uk.text': {
    value: 'сайт з',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.vi.text': {
    value: 'của chúng tôi với Vương quốc',
    location: 'client',
  },
  'internationalredirect.precurrencyexplanation.zh.text': {
    value: '网站，用 结算',
    location: 'client',
  },
  'internationalredirect.staybuttontext.ar.text': {
    value: 'No Thanks, Stay on',
    location: 'client',
  },
  'internationalredirect.staybuttontext.az.text': {
    value: 'No Thanks, Stay on',
    location: 'client',
  },
  'internationalredirect.staybuttontext.bg.text': {
    value: 'Не благодаря, искам да остана на',
    location: 'client',
  },
  'internationalredirect.staybuttontext.bs.text': {
    value: 'Ne hvala, ostanite na',
    location: 'client',
  },
  'internationalredirect.staybuttontext.cs.text': {
    value: 'Ne děkuji, chci pokračovat na',
    location: 'client',
  },
  'internationalredirect.staybuttontext.da.text': {
    value: 'Nej tak, bliv på',
    location: 'client',
  },
  'internationalredirect.staybuttontext.de.text': {
    value: 'Ich bleibe lieber auf',
    location: 'client',
  },
  'internationalredirect.staybuttontext.el.text': {
    value: 'Ευχαριστώ, θα παραμείνω στο',
    location: 'client',
  },
  'internationalredirect.staybuttontext.en.text': {
    value: 'No Thanks, Stay on',
    location: 'client',
  },
  'internationalredirect.staybuttontext.es.text': {
    value: 'No, gracias. Quiero quedarme en',
    location: 'client',
  },
  'internationalredirect.staybuttontext.fi.text': {
    value: 'Ei kiitos, pysy',
    location: 'client',
  },
  'internationalredirect.staybuttontext.fr.text': {
    value: 'Non Merci, Rester sur',
    location: 'client',
  },
  'internationalredirect.staybuttontext.hr.text': {
    value: 'Ne Hvala,Ostani Na',
    location: 'client',
  },
  'internationalredirect.staybuttontext.hu.text': {
    value: 'Nem, köszönöm. Maradok a oldalon',
    location: 'client',
  },
  'internationalredirect.staybuttontext.id.text': {
    value: 'No Thanks, Stay on',
    location: 'client',
  },
  'internationalredirect.staybuttontext.is.text': {
    value: 'No Thanks, Stay on',
    location: 'client',
  },
  'internationalredirect.staybuttontext.it.text': {
    value: 'No Grazie, Resta su',
    location: 'client',
  },
  'internationalredirect.staybuttontext.ja.text': {
    value: '結構です。続けます。',
    location: 'client',
  },
  'internationalredirect.staybuttontext.ko.text': {
    value: '아니오, 이 사이트에 머무르겠습니다.',
    location: 'client',
  },
  'internationalredirect.staybuttontext.lt.text': {
    value: 'Ne Ačių, Pasilikti',
    location: 'client',
  },
  'internationalredirect.staybuttontext.nb.text': {
    value: 'Nei takk, bli her',
    location: 'client',
  },
  'internationalredirect.staybuttontext.nl.text': {
    value: 'Bedankt, blijf op',
    location: 'client',
  },
  'internationalredirect.staybuttontext.pl.text': {
    value: 'Nie dziękuję, chcę tu zostać.',
    location: 'client',
  },
  'internationalredirect.staybuttontext.pt.text': {
    value: 'Não obrigado, ficar aqui',
    location: 'client',
  },
  'internationalredirect.staybuttontext.ro.text': {
    value: 'Nu multumesc, ramai aici',
    location: 'client',
  },
  'internationalredirect.staybuttontext.ru.text': {
    value: 'Нет спасибо , остаться на',
    location: 'client',
  },
  'internationalredirect.staybuttontext.sk.text': {
    value: 'Nie ďakujem, chcem pokračovať na',
    location: 'client',
  },
  'internationalredirect.staybuttontext.sl.text': {
    value: 'Ne hvala, želim ostati na',
    location: 'client',
  },
  'internationalredirect.staybuttontext.sr.text': {
    value: 'Ne hvala, ostanite na',
    location: 'client',
  },
  'internationalredirect.staybuttontext.sv.text': {
    value: 'Nej tack, stanna kvar på',
    location: 'client',
  },
  'internationalredirect.staybuttontext.uk.text': {
    value: 'Ні дякую, залишитися тут',
    location: 'client',
  },
  'internationalredirect.staybuttontext.vi.text': {
    value: 'Không, Cảm ơn. Vui Lòng Ở Lại',
    location: 'client',
  },
  'internationalredirect.staybuttontext.zh.text': {
    value: '不，谢谢，留在这里',
    location: 'client',
  },
  'general.internationaloverlay.gobutton.text': {
    value: 'Go to',
    location: 'client',
  },
  'general.internationaloverlay.staybutton.text': {
    value: 'No Thanks. Stay on',
    location: 'client',
  },
  'general.page.title.basket.text': {
    value: 'Your Shopping Basket',
    location: 'client',
  },
  'general.page.title.login.text': {
    value: 'Account Login',
    location: 'client',
  },
  'general.page.meta.description.login.text': {
    value: 'Account micro frontend meta description to be updated per page.',
    location: 'client',
  },
  'general.page.meta.description.article.text': {
    value:
      'Read articles curated by writers and experts, with ideas, advice and product recommendations',
    location: 'client',
  },
  'general.page.title.register.text': {
    value: 'About You',
    location: 'client',
  },
  'general.page.meta.description.register.text': {
    value: 'Account Registration',
    location: 'client',
  },
  'pageunavailable.server.maintenance': {
    value: 'Temporarily down for maintenance.',
    location: 'client',
  },
  'pageunavailable.pagenotfound.text': {
    value: "This page can't be found.",
    location: 'client',
  },
  'pageunavailable.servererror.text': {
    value: 'Internal Server Error',
    location: 'client',
  },
  'pageunavailable.enterprise.button.description': {
    value: 'Return to homepage',
    location: 'client',
  },
  'pageunavailable.maintenance.details.text': {
    value:
      'We are currently performing scheduled maintenance. We should be back online shortly.',
    location: 'client',
  },
  'pageunavailable.pagenotfound.details.text': {
    value:
      "It's either been removed from this location, or the URL is wrong. We've recorded this error to help us improve the site.",
    location: 'client',
  },
  'pageunavailable.servererror.details.text': {
    value:
      'The server encountered an internal error or misconfiguration and was unable to complete your request.',
    location: 'client',
  },
  'general.error.generic.text': {
    value: 'Something went wrong, please try again later.',
    location: 'client',
  },
  'header.search.placeholder': {
    value: 'Search for a product',
    location: 'client',
  },
  'header.search.results': {
    value: 'Search Results',
    location: 'client',
  },
  'header.search.products.title': {
    value: 'Products',
    location: 'client',
  },
  'header.search.suggestions.title': {
    value: 'Search Suggestions',
    location: 'client',
  },
  'header.search.corrections.title': {
    value: 'Did You Mean',
    location: 'client',
  },
  'header.search.results.available': {
    value: 'Results available, use up and down keys to navigate',
    location: 'client',
  },
  'header.search.results.last': {
    value: 'Last result',
    location: 'client',
  },
  'header.logo.arialabel': {
    value: '%s Home page',
    location: 'client',
  },
  'header.account.nav.arialabel': {
    value: 'Account',
    location: 'client',
  },
  'header.account.addresses.add': {
    value: 'Add address',
    location: 'client',
  },
  'header.account.addresses.edit': {
    value: 'Edit address',
    location: 'client',
  },
  'account.addresses.add.button': {
    value: 'Add address',
    location: 'client',
  },
  'account.addresses.edit.button': {
    value: 'Save changes',
    location: 'client',
  },
  'header.account.welcome.text': {
    value: 'Hello',
    location: 'client',
  },
  'header.basket.label': {
    value: 'Basket',
    location: 'client',
  },
  'header.account.label': {
    value: 'Account',
    location: 'client',
  },
  'header.search.button.arialabel': {
    value: 'Search',
    location: 'client',
  },
  'header.menu.button.arialabel': {
    value: 'Menu',
    location: 'client',
  },

  'header.country.ae.text': {
    value: 'United Arab Emirates',
    location: 'client',
  },
  'header.country.at.text': {
    value: 'Österreich',
    location: 'client',
  },
  'header.country.au.text': {
    value: 'Australia',
    location: 'client',
  },
  'header.country.az.text': {
    value: 'Azerbeijan',
    location: 'client',
  },
  'header.country.ba.text': {
    value: 'Bosna',
    location: 'client',
  },
  'header.country.be.text': {
    value: 'België',
    location: 'client',
  },
  'header.country.bg.text': {
    value: 'България',
    location: 'client',
  },
  'header.country.br.text': {
    value: 'Brasil',
    location: 'client',
  },
  'header.country.ca.text': {
    value: 'Canada',
    location: 'client',
  },
  'header.country.ch.text': {
    value: 'Schweiz',
    location: 'client',
  },
  'header.country.cl.text': {
    value: 'Chile',
    location: 'client',
  },
  'header.country.cn.text': {
    value: '中国',
    location: 'client',
  },
  'header.country.cs.text': {
    value: 'Česká republika',
    location: 'client',
  },
  'header.country.cy.text': {
    value: 'Κύπρος',
    location: 'client',
  },
  'header.country.da.text': {
    value: 'Danmark',
    location: 'client',
  },
  'header.country.de.text': {
    value: 'Deutschland',
    location: 'client',
  },
  'header.country.dk.text': {
    value: 'Danmark',
    location: 'client',
  },
  'header.country.ee.text': {
    value: 'Eesti',
    location: 'client',
  },
  'header.country.el.text': {
    value: 'Ελλάδα',
    location: 'client',
  },
  'header.country.en.text': {
    value: 'United Kingdom',
    location: 'client',
  },
  'header.country.es.text': {
    value: 'España',
    location: 'client',
  },
  'header.country.eu.text': {
    value: 'Europe',
    location: 'client',
  },
  'header.country.fi.text': {
    value: 'Suomi',
    location: 'client',
  },
  'header.country.fr.text': {
    value: 'France',
    location: 'client',
  },
  'header.country.gb.text': {
    value: 'United Kingdom',
    location: 'client',
  },
  'header.country.gr.text': {
    value: 'Ελλάδα',
    location: 'client',
  },
  'header.country.he.text': {
    value: 'ישראל',
    location: 'client',
  },
  'header.country.hk.text': {
    value: 'Hong Kong',
    location: 'client',
  },
  'header.country.hr.text': {
    value: 'Hrvatska',
    location: 'client',
  },
  'header.country.id.text': {
    value: 'Indonesia',
    location: 'client',
  },
  'header.country.ie.text': {
    value: 'Eire',
    location: 'client',
  },
  'header.country.in.text': {
    value: 'India',
    location: 'client',
  },
  'header.country.is.text': {
    value: 'Ísland',
    location: 'client',
  },
  'header.country.it.text': {
    value: 'Italia',
    location: 'client',
  },
  'header.country.ja.text': {
    value: '日本',
    location: 'client',
  },
  'header.country.jp.text': {
    value: '日本',
    location: 'client',
  },
  'header.country.kr.text': {
    value: '대한민국',
    location: 'client',
  },
  'header.country.kz.text': {
    value: 'kazakhstan',
    location: 'client',
  },
  'header.country.lt.text': {
    value: 'Lietuva',
    location: 'client',
  },
  'header.country.lv.text': {
    value: 'Latvija',
    location: 'client',
  },
  'header.country.ma.text': {
    value: 'Maroc',
    location: 'client',
  },
  'header.country.mx.text': {
    value: 'México',
    location: 'client',
  },
  'header.country.my.text': {
    value: 'Ísland',
    location: 'client',
  },
  'header.country.nb.text': {
    value: 'Norge',
    location: 'client',
  },
  'header.country.nl.text': {
    value: 'Nederland',
    location: 'client',
  },
  'header.country.no.text': {
    value: 'Norge',
    location: 'client',
  },
  'header.country.nz.text': {
    value: 'New Zealand',
    location: 'client',
  },
  'header.country.pk.text': {
    value: 'Pakistan',
    location: 'client',
  },
  'header.country.pl.text': {
    value: 'Polska',
    location: 'client',
  },
  'header.country.pt.text': {
    value: 'Portugal',
    location: 'client',
  },
  'header.country.ro.text': {
    value: 'România',
    location: 'client',
  },
  'header.country.rs.text': {
    value: 'Србија',
    location: 'client',
  },
  'header.country.ru.text': {
    value: 'Россия',
    location: 'client',
  },
  'header.country.sa.text': {
    value: 'المملكة العربية السعودية',
    location: 'client',
  },
  'header.country.se.text': {
    value: 'Sverige',
    location: 'client',
  },
  'header.country.sg.text': {
    value: 'Singapore',
    location: 'client',
  },
  'header.country.sk.text': {
    value: 'Slovensko',
    location: 'client',
  },
  'header.country.sl.text': {
    value: 'Slovenija',
    location: 'client',
  },
  'header.country.sv.text': {
    value: 'Sverige',
    location: 'client',
  },
  'header.country.text': {
    value: 'Country:',
    location: 'client',
  },
  'header.country.th.text': {
    value: 'Thailand',
    location: 'client',
  },
  'header.country.tw.text': {
    value: '台灣',
    location: 'client',
  },
  'header.country.ua.text': {
    value: 'Україна',
    location: 'client',
  },
  'header.country.us.text': {
    value: 'United States',
    location: 'client',
  },
  'header.country.vn.text': {
    value: 'Việt Nam',
    location: 'client',
  },
  'header.country.xa.text': {
    value: 'Canada (Quebec)',
    location: 'client',
  },
  'header.country.xb.text': {
    value: 'Malaysia',
    location: 'client',
  },
  'header.country.xc.text': {
    value: 'Israel',
    location: 'client',
  },
  'header.country.xd.text': {
    value: 'Vietnam',
    location: 'client',
  },
  'header.country.za.text': {
    value: 'South Africa',
    location: 'client',
  },
  'header.country.cz.text': {
    value: 'České republice',
    location: 'client',
  },
  'header.country.hu.text': {
    value: 'Magyarországon ',
    location: 'client',
  },
  'header.country.identifier.ae.text': {
    value: 'UAE',
    location: 'client',
  },
  'header.country.identifier.at.text': {
    value: 'österreichische ',
    location: 'client',
  },
  'header.country.identifier.au.text': {
    value: 'Australian',
    location: 'client',
  },
  'header.country.identifier.az.text': {
    value: 'Azərbaycan ',
    location: 'client',
  },
  'header.country.identifier.ba.text': {
    value: 'Bosansku',
    location: 'client',
  },
  'header.country.identifier.be.text': {
    value: 'Belgische ',
    location: 'client',
  },
  'header.country.identifier.bg.text': {
    value: 'български ',
    location: 'client',
  },
  'header.country.identifier.br.text': {
    value: 'brasileiro',
    location: 'client',
  },
  'header.country.identifier.ca.text': {
    value: 'Canadian',
    location: 'client',
  },
  'header.country.identifier.ch.text': {
    value: 'schweizer ',
    location: 'client',
  },
  'header.country.identifier.cn.text': {
    value: '中文',
    location: 'client',
  },
  'header.country.identifier.cy.text': {
    value: 'Κύπριος',
    location: 'client',
  },
  'header.country.identifier.cz.text': {
    value: 'českou ',
    location: 'client',
  },
  'header.country.identifier.de.text': {
    value: 'deutsche',
    location: 'client',
  },
  'header.country.identifier.dk.text': {
    value: 'dansk',
    location: 'client',
  },
  'header.country.identifier.es.text': {
    value: 'Española ',
    location: 'client',
  },
  'header.country.identifier.fi.text': {
    value: 'Suomen',
    location: 'client',
  },
  'header.country.identifier.fr.text': {
    value: 'Français',
    location: 'client',
  },
  'header.country.identifier.gb.text': {
    value: 'UK',
    location: 'client',
  },
  'header.country.identifier.gr.text': {
    value: 'Ελληνικό',
    location: 'client',
  },
  'header.country.identifier.hk.text': {
    value: 'Hong Kong',
    location: 'client',
  },
  'header.country.identifier.hr.text': {
    value: 'hrvatsku ',
    location: 'client',
  },
  'header.country.identifier.hu.text': {
    value: 'magyar ',
    location: 'client',
  },
  'header.country.identifier.id.text': {
    value: 'Indonesian',
    location: 'client',
  },
  'header.country.identifier.ie.text': {
    value: 'Irish',
    location: 'client',
  },
  'header.country.identifier.il.text': {
    value: 'Israeli',
    location: 'client',
  },
  'header.country.identifier.in.text': {
    value: 'Indian',
    location: 'client',
  },
  'header.country.identifier.is.text': {
    value: 'Icelandic',
    location: 'client',
  },
  'header.country.identifier.it.text': {
    value: 'Italiano',
    location: 'client',
  },
  'header.country.identifier.jp.text': {
    value: '日本語',
    location: 'client',
  },
  'header.country.identifier.kr.text': {
    value: '한국어',
    location: 'client',
  },
  'header.country.identifier.kz.text': {
    value: 'Казахский ',
    location: 'client',
  },
  'header.country.identifier.lt.text': {
    value: 'Lietuviška ',
    location: 'client',
  },
  'header.country.identifier.ma.text': {
    value: 'Moroccan',
    location: 'client',
  },
  'header.country.identifier.mx.text': {
    value: 'mexicano ',
    location: 'client',
  },
  'header.country.identifier.my.text': {
    value: 'Malaysian',
    location: 'client',
  },
  'header.country.identifier.nl.text': {
    value: 'Nederlandse ',
    location: 'client',
  },
  'header.country.identifier.no.text': {
    value: 'norske',
    location: 'client',
  },
  'header.country.identifier.nz.text': {
    value: 'New Zealand',
    location: 'client',
  },
  'header.country.identifier.ph.text': {
    value: 'Philipino ',
    location: 'client',
  },
  'header.country.identifier.pk.text': {
    value: 'Pakistani',
    location: 'client',
  },
  'header.country.identifier.pl.text': {
    value: 'Polską ',
    location: 'client',
  },
  'header.country.identifier.pt.text': {
    value: 'português ',
    location: 'client',
  },
  'header.country.identifier.ro.text': {
    value: 'romanesc',
    location: 'client',
  },
  'header.country.identifier.rs.text': {
    value: 'Cрпску ',
    location: 'client',
  },
  'header.country.identifier.ru.text': {
    value: 'Русский ',
    location: 'client',
  },
  'header.country.identifier.sa.text': {
    value: 'Saudi',
    location: 'client',
  },
  'header.country.identifier.se.text': {
    value: 'svenska',
    location: 'client',
  },
  'header.country.identifier.sg.text': {
    value: 'Singaporean',
    location: 'client',
  },
  'header.country.identifier.si.text': {
    value: 'Slovensko',
    location: 'client',
  },
  'header.country.identifier.sk.text': {
    value: 'slovenskej ',
    location: 'client',
  },
  'header.country.identifier.ua.text': {
    value: 'Український ',
    location: 'client',
  },
  'header.country.identifier.us.text': {
    value: 'American',
    location: 'client',
  },
  'header.country.identifier.vn.text': {
    value: 'Tiếng Việt',
    location: 'client',
  },
  'header.country.identifier.za.text': {
    value: 'South African',
    location: 'client',
  },
  'header.country.il.text': {
    value: 'Israel',
    location: 'client',
  },
  'header.country.ph.text': {
    value: 'Philipines',
    location: 'client',
  },
  'header.country.si.text': {
    value: 'Sloveniji',
    location: 'client',
  },
  'general.form.input.validation.email': {
    value: '${path} must be a valid email',
    location: 'client',
  },
  'general.form.input.validation.required': {
    value: '${path} is a required field',
    location: 'client',
  },
  'general.form.input.validation.minlength': {
    value: '${path} must be at least ${min} characters',
    location: 'client',
  },
  'general.form.input.validation.maxlength': {
    value: '${path} must be at most ${max} characters',
    location: 'client',
  },
  'general.form.input.validation.pattern': {
    value: '${path} match the following: "${regex}"',
    location: 'client',
  },
  'general.form.input.validation.phonenumberpattern': {
    value: '%s match the following format: "07 XXXXXXXXX"',
    location: 'client',
  },
  'general.form.marketingpreferences.label': {
    value: 'Marketing Preferences',
    location: 'client',
  },
  'header.submenu.link1.text': {
    value: 'Contact Us',
    location: 'client',
  },
  'header.submenu.link1.link': {
    value: '/contact-us.list',
    location: 'client',
  },
  'header.submenu.link2.text': {
    value: 'Help',
    location: 'client',
  },
  'header.submenu.link2.link': {
    value: '/help-centre.list',
    location: 'client',
  },
  'header.submenu.slot1.label': {
    value: 'Contact Us',
    location: 'client',
  },
  'header.submenu.slot1.link': {
    value: '/contact-us.list',
    location: 'client',
  },
  'header.submenu.slot2.label': {
    value: 'Help',
    location: 'client',
  },
  'header.submenu.slot2.link': {
    value: '/help-centre.list',
    location: 'client',
  },
  'header.submenu.link3.text': {
    value: 'Returns',
    location: 'client',
  },
  'header.submenu.link3.link': {
    value: '/',
    location: 'client',
  },
  'header.submenu.link4.text': {
    value: 'Here to Help',
    location: 'client',
  },
  'header.submenu.link4.link': {
    value: '/',
    location: 'client',
  },
  'header.submenu.arialabel': {
    value: 'Settings and Help',
    location: 'client',
  },
  'header.sessionsettings.savenotification': {
    value:
      "Your settings have been saved. We'll remember this next time you visit us!",
    location: 'client',
  },
  'header.sessionsettings.modal.header': {
    value: 'Your Settings',
    location: 'client',
  },
  'header.sessionsettings.button.open.arialabel': {
    value: 'open',
    location: 'client',
  },
  'header.sessionsettings.selector.shipping.to.label': {
    value: 'Shipping to',
    location: 'client',
  },
  'header.sessionsettings.selector.region.label': {
    value: 'Region & Language',
    location: 'client',
  },
  'header.sessionsettings.selector.currencies.label': {
    value: 'Currencies',
    location: 'client',
  },
  'header.sessionsettings.selector.submit.button.text': {
    value: 'Submit',
    location: 'client',
  },
  'header.submenu.button.change.text': {
    value: 'Change',
    location: 'client',
  },
  'footer.social.icons.title.text': {
    value: 'Connect with us',
    location: 'client',
  },
  'footer.links.title.pay.securely.with': {
    value: 'Pay securely with',
    location: 'client',
  },
  'errors.account.emailaddressmismatchresetrequest': {
    value:
      'The email address entered does not match the email address that the password reset instructions were sent to.',
    location: 'client',
  },
  'errors.account.invaliddata': {
    value:
      'Some of the fields in this form have invalid data, please try again.',
    location: 'client',
  },
  'errors.account.cancelordertypeinvalid': {
    value: 'Please select products that you would like to cancel',
    location: 'client',
  },
  'errors.account.productactionreasoninvalid': {
    value: 'Please provide a valid reason for cancellation.',
    location: 'client',
  },
  'errors.account.cancel.order.products': {
    value: 'There was an error cancelling one or more products',
    location: 'client',
  },
  'general.form.input.validation.nodigits': {
    value: 'This field cannot contain numbers.',
    location: 'client',
  },
  'general.form.input.validation.nospecialcharacters': {
    value: 'This field cannot contain special characters.',
    location: 'client',
  },
  'footer.newsletter.signup.submit.button.text': {
    value: 'Sign up',
    location: 'client',
  },
  'header.flyout.linkwithchildren.arialabel': {
    value: 'Open %s submenu',
    location: 'client',
  },
  'header.flyout.sectionhome.text': {
    value: '%s Home',
    location: 'client',
  },
  'header.flyout.button.back.text': {
    value: 'Back',
    location: 'client',
  },
  'account.header.breadcrumb.home.text': {
    value: 'My Account',
    location: 'client',
  },
  'myaccount.welcome.text': {
    value: 'Welcome',
    location: 'client',
  },
  'myaccount.error.text': {
    value:
      'There was an error loading your account information. Please try again later.',
    location: 'client',
  },
  'myaccount.welcome.text.end': {
    value: ',',
    location: 'client',
  },
  'account.orderdetail.details.status': {
    value: 'Status',
    location: 'client',
  },
  'account.orderdetail.details.message': {
    value:
      "We're sorry, some of your order details haven't loaded. For more information on this order, please contact our customer services team.",
    location: 'client',
  },
  'account.orderdetail.details.personalisation.reorder.info': {
    value:
      'This product cannot be reordered from your Account. If you wish to purchase it again, please select the product from the website.',
    location: 'client',
  },
  'account.order.cancel.review.text': {
    value: 'Your cancellation is being reviewed',
    location: 'client',
  },
  'account.order.cancel.products.text': {
    value: 'Cancel Items',
    location: 'client',
  },
  'account.order.cancel.products.success': {
    value: 'Cancellation request submitted successfully',
    location: 'client',
  },
  'account.order.cancelled.items': {
    value: 'Your cancelled items',
    location: 'client',
  },
  'account.orders.clickandcollect.collectionnotice': {
    value:
      'Orders are held for 3 days. You will be contacted when your order is ready to collect, please bring ID with you.',
    location: 'client',
  },
  'account.orders.delivertostore.collectionnotice': {
    value:
      'Orders are held for 7 days You will be contacted when your order is ready to collect, please bring ID with you.',
    location: 'client',
  },
  'account.orders.ordernumber': {
    value: 'Order Number',
    location: 'client',
  },
  'account.orders.freeprice.text': {
    value: 'Free',
    location: 'client',
  },
  'account.orderdetail.details.totalcost': {
    value: 'Total Cost',
    location: 'client',
  },
  'account.orderdetail.tracking.text': {
    value: 'Track Order',
    location: 'client',
  },
  'account.subscription.meta.total.label': {
    value: 'Total',
    location: 'client',
  },
  'account.orders.orderdate': {
    value: 'Order Date',
    location: 'client',
  },
  'account.home.orders.expand.text': {
    value: 'Show more orders',
    location: 'client',
  },
  'account.home.orders.collapse.text': {
    value: 'Show less orders',
    location: 'client',
  },
  'account.home.recent.orders.text': {
    value: 'Recent Orders',
    location: 'client',
  },
  'account.home.orders.viewall.text': {
    value: 'View all orders',
    location: 'client',
  },
  'account.home.orders.vieworderhistory.text': {
    value: 'View Order History',
    location: 'client',
  },
  'account.home.noorders.shop.text': {
    value: 'Go shopping',
    location: 'client',
  },
  'account.subscriptions.order.history.sort.time': {
    value: '*Time Filter',
    location: 'client',
  },
  'account.subscriptions.order.history.listheading.text': {
    value: 'Product(s):',
    location: 'client',
  },
  'account.addresses.loading.error': {
    value: 'Error loading addresses',
    location: 'client',
  },
  'account.address.loading.edit.error': {
    value: 'Error occured while loading your address',
    location: 'client',
  },
  'account.ordersummary.products.arialabel': {
    value: '%s products in your order including the following',
    location: 'client',
  },
  'myaccount.orderdetail.estimateddeliverydate.text': {
    value: 'Est. Delivery',
    location: 'client',
  },
  'account.order.orderstatus.orderplaced': {
    value: 'Order Placed',
    location: 'client',
  },
  'account.order.orderstatus.processing': {
    value: 'Processing',
    location: 'client',
  },
  'account.order.orderstatus.despatched': {
    value: 'Dispatched',
    location: 'client',
  },
  'account.order.orderstatus.cancelled': {
    value: 'Cancelled',
    location: 'client',
  },
  'account.order.orderstatus.paymentproblem': {
    value: 'Payment Problem',
    location: 'client',
  },
  'account.order.status.paymentproblem.noestimateddelivery.text': {
    value:
      'Estimated delivery will be provided when the payment problem is resolved.',
    location: 'client',
  },
  'account.order.orderstatus.unknown': {
    value: 'Unknown',
    location: 'client',
  },
  'myaccount.viewall.text': {
    value: 'View More',
    location: 'client',
  },
  'myaccount.yourorders.text': {
    value: 'Your Orders',
    location: 'client',
  },
  'myaccount.petandlitterprofiles.text': {
    value: 'My Pet & Litter profiles',
    location: 'client',
  },
  'myaccount.petprofiles.text': {
    value: 'My Pet profiles',
    location: 'client',
  },
  'account.petprofile.confirmbutton.text': {
    value: 'Yes, delete this profile.',
    location: 'client',
  },
  'account.petprofile.cancelbutton.text': {
    value: 'Cancel',
    location: 'client',
  },
  'account.petprofile.title': {
    value: 'My Pet Profiles',
    location: 'client',
  },
  'account.petprofile.content': {
    value: 'Showing your last pet profile',
    location: 'client',
  },
  'account.litterprofile.widget.content': {
    value: 'Tell us about your litter!',
    location: 'client',
  },
  'account.litterprofile.widget.cta': {
    value: 'Add a litter',
    location: 'client',
  },
  'account.petprofile.tab.mypets': {
    value: 'My Pets',
    location: 'client',
  },
  'account.petprofile.tab.mylitters': {
    value: 'My Litters',
    location: 'client',
  },
  'account.litterprofile.widget.content.disabled': {
    value: 'Please add a female pet before adding their litter',
    location: 'client',
  },
  'account.motherprofile.puppies.mother.key': {
    value: 'whichMother',
    location: 'client',
  },
  'account.motherprofile.kittens.mother.key': {
    value: 'Which_Mother_Kitten',
    location: 'client',
  },
  'account.petspecies.pets.key': {
    value: 'petSpecies',
    location: 'client',
  },
  'account.petspecies.litter.key': {
    value: 'Do_you_have_a_dog_or_a_cat',
    location: 'client',
  },
  'account.petspecies.override': {
    value: 'cat',
    location: 'client',
  },
  'account.profile.deletemodal.subtitle': {
    value: 'Are you sure you want to delete this main pet profile?',
    location: 'client',
  },
  'account.orders.text': {
    value: 'Orders',
    location: 'client',
  },
  'account.orders.invoice': {
    value: 'View Invoice',
    location: 'client',
  },
  'account.order.invoices': {
    value: 'View Invoices',
    location: 'client',
  },
  'account.order.invoices.message': {
    value:
      'Your order was dispatched in multiple shipments. Please see the relevant invoice per shipment for further details.',
    location: 'client',
  },
  'account.order.invoices.shipments': {
    value: 'Shipment %s of %s',
    location: 'client',
  },
  'account.orders.noorders.message': {
    value: 'You currently have no orders',
    location: 'client',
  },
  'account.orders.noorders.active.text': {
    value: 'You have no active orders',
    location: 'client',
  },
  'account.orders.noorders.completed.text': {
    value: 'You have no completed orders',
    location: 'client',
  },
  'account.orders.active.text': {
    value: 'Active orders',
    location: 'client',
  },
  'account.orders.completed.text': {
    value: 'Completed orders',
    location: 'client',
  },
  'account.orders.shopping.text': {
    value: 'Go Shopping',
    location: 'client',
  },
  'account.logout.button.text': {
    value: 'Logout',
    location: 'client',
  },
  'header.account.logout.text': {
    value: 'Log Out',
    location: 'client',
  },
  'header.account.login.text': {
    value: 'Log in',
    location: 'client',
  },
  'header.account.register.text': {
    value: 'Register',
    location: 'client',
  },
  'header.account.my.account.text': {
    value: 'My Account',
    location: 'client',
  },
  'header.account.your.wishlist.text': {
    value: 'Your Wishlist',
    location: 'client',
  },
  'account.your.wishlist.header.text': {
    value: 'Your Wishlist - Enjoy 5% off almost everything - Use code: WISH5',
    location: 'client',
  },
  'account.your.wishlist.saveforlater.text': {
    value: 'Add some items to your wishlist to save them for later.',
    location: 'client',
  },
  'account.your.wishlist.empty.text': {
    value: 'Your wishlist is empty',
    location: 'client',
  },
  'account.addresses.text': {
    value: 'Addresses',
    location: 'client',
  },
  'account.addresses.view.more.text': {
    value: 'View more',
    location: 'client',
  },
  'account.address.addaddress.text': {
    value: 'Add address',
    location: 'client',
  },
  'account.address.add.title': {
    value: 'Add new delivery address',
    location: 'client',
  },
  'account.address.edit.title': {
    value: 'Edit delivery address',
    location: 'client',
  },
  'account.address.form.field.addresseename': {
    value: 'Full name',
    location: 'client',
  },
  'account.address.form.field.country': {
    value: 'Country',
    location: 'client',
  },
  'account.address.form.field.state': {
    value: 'State',
    location: 'client',
  },
  'account.address.form.field.state.placeholder': {
    value: 'Select state',
    location: 'client',
  },
  'account.address.form.field.postcode': {
    value: 'Postcode',
    location: 'client',
  },
  'account.address.form.field.companyname': {
    value: 'Company name',
    location: 'client',
  },
  'account.address.form.field.housenumber': {
    value: 'House name/number',
    location: 'client',
  },
  'account.address.form.field.town': {
    value: 'Town/City',
    location: 'client',
  },
  'account.address.form.field.county': {
    value: 'County',
    location: 'client',
  },
  'account.address.form.field.mobilenumber': {
    value: 'Mobile number',
    location: 'client',
  },
  'account.address.form.field.addressline1': {
    value: 'Address 1',
    location: 'client',
  },
  'account.address.form.field.addressline2': {
    value: 'Address 2',
    location: 'client',
  },
  'account.address.add.error': {
    value: 'An error has occured while adding address',
    location: 'client',
  },
  'account.address.edit.error': {
    value:
      'An error has occurred while editing this address, please try again.',
    location: 'client',
  },
  'account.address.add.success': {
    value: 'New address has been added',
    location: 'client',
  },
  'account.address.edit.success': {
    value: 'Address has been edited',
    location: 'client',
  },
  'account.address.nosavedaddresses.text': {
    value: 'You have no saved addresses.',
    location: 'client',
  },
  'header.account.your.orders.text': {
    value: 'Your Orders',
    location: 'client',
  },
  'header.account.your.referrals.text': {
    value: 'Your Referrals',
    location: 'client',
  },
  'header.account.member.perks.text': {
    value: 'Member Perks',
    location: 'client',
  },
  'header.skip.link.text': {
    value: 'Skip to main content',
    location: 'client',
  },
  'referrals.welcome.reward.text': {
    value:
      "Earn £10 Credit per successful referral when your friends spend £35. And when they do, we'll give them 3 months free next day delivery on us.",
    location: 'client',
  },
  'account.accountcredit.reward.text': {
    value: 'View your account credit',
    location: 'client',
  },
  'account.accountcredit.credittotal': {
    value: 'Total Account Credit',
    location: 'client',
  },
  'referrals.count.explaination': {
    value: 'Friends Referred',
    location: 'client',
  },
  'referrals.login.view': {
    value: 'View details',
    location: 'client',
  },
  'profile.default.translations.profile.login.title': {
    value: 'My Profile',
    location: 'client',
  },
  'account.wishlist.card.title': {
    value: 'Your Wishlist',
    location: 'client',
  },
  'account.wishlist.welcome.text': {
    value: 'View your favourite products',
    location: 'client',
  },
  'account.wishlist.view': {
    value: 'View Wishlist',
    location: 'client',
  },
  'account.deals.card.title': {
    value: 'Member Perks',
    location: 'client',
  },
  'account.deals.welcome.text': {
    value: 'New deals to be added every week',
    location: 'client',
  },
  'account.deals.view': {
    value: 'Access exclusive deals',
    location: 'client',
  },
  'account.login.to.write.review': {
    value: 'Please login to submit a review',
    location: 'client',
  },
  'account.login.to.vote.review': {
    value: 'Please login to vote for a review',
    location: 'client',
  },
  'account.login.to.report.review': {
    value: 'Please login to report a review',
    location: 'client',
  },
  'account.login.linkingaccounts.message': {
    value:
      'Please sign in to your account, or create one in order to link your account credits.',
    location: 'client',
  },
  'general.list.show.more': {
    value: 'Show more',
    location: 'client',
  },
  'general.list.show.less': {
    value: 'Show less',
    location: 'client',
  },
  'general.add.text': {
    value: 'Add',
    location: 'client',
  },
  'general.date.distance.now.text': {
    value: 'Just now',
    location: 'client',
  },
  'general.date.distance.suffixpast.text': {
    value: 'ago',
    location: 'client',
  },
  'general.date.distance.prefixfuture.text': {
    value: 'in',
    location: 'client',
  },
  'general.date.second.singular.text': {
    value: '%d second',
    location: 'client',
  },
  'general.date.second.plural.text': {
    value: '%d seconds',
    location: 'client',
  },
  'general.date.minute.singular.text': {
    value: '%d minute',
    location: 'client',
  },
  'general.date.minute.plural.text': {
    value: '%d minutes',
    location: 'client',
  },
  'general.date.hour.singular.text': {
    value: '%d hour',
    location: 'client',
  },
  'general.date.hour.plural.text': {
    value: '%d hours',
    location: 'client',
  },
  'general.date.day.singular.text': {
    value: '%d day',
    location: 'client',
  },
  'general.date.day.plural.text': {
    value: '%d days',
    location: 'client',
  },
  'general.date.week.singular.text': {
    value: '%d week',
    location: 'client',
  },
  'general.date.week.plural.text': {
    value: '%d weeks',
    location: 'client',
  },
  'general.date.month.singular.text': {
    value: '%d month',
    location: 'client',
  },
  'general.date.month.plural.text': {
    value: '%d months',
    location: 'client',
  },
  'general.date.year.singular.text': {
    value: '%d year',
    location: 'client',
  },
  'general.date.year.plural.text': {
    value: '%d years',
    location: 'client',
  },
  'general.date.unknown.duration.text': {
    value: 'Unknown duration',
  },
  'account.settings.title': {
    value: 'Account Settings',
    location: 'client',
  },
  'account.settings.credit.title': {
    value: 'Account Credit',
    location: 'client',
  },
  'account.settings.email.preferences.title': {
    value: 'Email Preferences',
    location: 'client',
  },
  'account.settings.account.details.title': {
    value: 'Account Details',
    location: 'client',
  },
  'account.details.personal.title': {
    value: 'Personal Details',
    location: 'client',
  },
  'account.details.login.title': {
    value: 'Login Details',
    location: 'client',
  },
  'account.details.notprovided': {
    value: 'Not provided',
    location: 'client',
  },
  'account.settings.payment.cards.title': {
    value: 'Payment Cards',
    location: 'client',
  },
  'account.cards.nocards.text': {
    value: 'You have no saved payment cards.',
    location: 'client',
  },
  'account.cards.viewmore.text': {
    value: 'View More',
    location: 'client',
  },
  'account.cards.errorloadingcards.text': {
    value: 'Error while loading in saved payment cards.',
    location: 'client',
  },
  'account.paymentmethod.delete.text': {
    value: 'Delete',
    location: 'client',
  },
  'account.settings.address.book.title': {
    value: 'Address Book',
    location: 'client',
  },
  'account.loyalty.meta.title': {
    value: 'My Rewards',
    location: 'client',
  },
  'account.loyalty.notregistered.text': {
    value: 'You are currently not a member',
    location: 'client',
  },
  'account.loyalty.replacecard.heading.text': {
    value: 'Picked up another card in store?',
    location: 'client',
  },
  'account.loyalty.replacecard.subheading.text': {
    value:
      'Enter the 15 digit number below of the card you picked up in store to replace your existing card ',
    location: 'client',
  },
  'account.loyalty.replacecard.label': {
    value: 'Reward Card Number',
    location: 'client',
  },

  'account.loyalty.entercard.heading.text': {
    value: 'Have you already picked up a card in store?',
    location: 'client',
  },
  'account.loyalty.entercard.subheading.text': {
    value:
      'Enter the 15 digit number below of the card you picked up in store to join My Rewards.',
    location: 'client',
  },
  'account.loyalty.entercard.number.text': {
    value: 'Reward Card Number',
    location: 'client',
  },
  'account.loyalty.entercard.error.text': {
    value:
      'There was an error while adding your reward card, please try again later.',
    location: 'client',
  },
  'account.loyalty.link.reward.card.text': {
    value: 'Link card to account',
    location: 'client',
  },
  'account.loyalty.join.rewards.text_1': {
    value: `Exclusive Discounts - Plus extra rewards when you shop, in store vouchers and more.`,
    location: 'client',
  },
  'account.loyalty.join.rewards.text_2': {
    value: `Shop the Sale First - you'll hear about the sale early, so you can be first in line.`,
    location: 'client',
  },
  'account.loyalty.join.rewards.text_3': {
    value: `A Birthday Treat - Share your special day with us, and look out for a little treat on the day.`,
    location: 'client',
  },
  'account.loyalty.join.rewards.text_4': {
    value: `Win Your Basket - Shop online or use your Matalan Me card or app in store for the chance to win your basket - there's a winner every day!`,
    location: 'client',
  },
  'account.loyalty.join.rewards.text_5': {
    value: `By clicking 'Join Matalan Me' you're confirming that you agree to our <a href='/customer-services/terms-and-conditions.list'>Terms and Conditions</a> and <a href='/customer-services/privacy.list'>Privacy Policy.</a>`,
    location: 'client',
  },
  'account.loyalty.join.rewards.error.text': {
    value: 'There was an error while joining rewards, please try again later.',
    location: 'client',
  },
  'account.loyalty.join.rewards.button.text': {
    value: 'Join Matalan Rewards',
    location: 'client',
  },
  'account.loyalty.replacecardform.submit': {
    value: 'Replace Card',
    location: 'client',
  },
  'account.loyalty.replacecardform.title': {
    value: 'Picked up another card in store?',
    location: 'client',
  },
  'account.loyalty.rewardcardnumber.placeholder': {
    value: 'Reward Card Number',
    location: 'client',
  },
  'account.loyalty.replace.success': {
    value: 'Your card number has been updated',
    location: 'client',
  },
  'account.loyalty.managecard.faqtitle': {
    value: 'FAQs',
    location: 'client',
  },
  'account.loyalty.managecard.faq1.question': {
    value: 'How do I activate the new card I picked up in store?',
    location: 'client',
  },
  'account.loyalty.managecard.faq2.question': {
    value: 'Do you still send Matalan cards out?',
    location: 'client',
  },
  'account.loyalty.managecard.faq3.question': {
    value: 'I’ve lost my card',
    location: 'client',
  },
  'account.loyalty.managecard.faq4.question': {
    value: 'What if I don’t want to be a part of Matalan Me?',
    location: 'client',
  },
  'account.loyalty.managecard.faq1.answer': {
    value:
      'Enter the 15 digit number on the card you picked up in store in the ‘replace my card’ section of your online account. This will replace any existing card.',
    location: 'client',
  },
  'account.loyalty.managecard.faq2.answer': {
    value:
      'No, we do not send Matalan Me cards out in the post. But you can pick one up in store and add the number to your online account under the Reward Card tab.<br /><br />You can also download the Matalan app to access your digital Matalan Me card to use in store and online.',
    location: 'client',
  },
  'account.loyalty.managecard.faq3.answer': {
    value:
      'If you’ve lost your physical card, you still have access to your digital card. Log in to your account online or on the app, and your card is available in the Reward Card tab. It’s automatically applied when you shop online, and you can use it in store too.<br /><br />If you’d still like to have a physical card, pick one up in store and update your card number in the ‘replace my card’ section of your online account.',
    location: 'client',
  },
  'account.loyalty.managecard.faq4.answer': {
    value:
      'If you don’t want to be part of Matalan Me, you can remove your card from your account.<br /><br />Once you remove your card, you will no longer have access to Matalan Me member benefits including exclusive discounts, rewards for shopping, your birthday treat and your early notice of our sales. You will also stop receiving our marketing emails.<br /><br />If you opt out of receiving our emails, you’ll also be removed from our Matalan Me mailing list.',
    location: 'client',
  },
  'account.rewards.welcome.title': {
    value: 'Welcome to my Rewards',
    location: 'client',
  },
  'account.rewards.welcome.text': {
    value: "You're just %s away from becoming a member!",
    location: 'client',
  },
  'account.rewards.points.text': {
    value: 'points',
    location: 'client',
  },
  'account.rewards.welcome.benefits.title': {
    value: 'Benefits',
    location: 'client',
  },
  'account.rewards.welcome.benefits.subtitle': {
    value: 'Subtitle goes here.',
    location: 'client',
  },
  'account.rewards.welcome.benefits.accordion1title': {
    value: 'What is my Rewards?',
    location: 'client',
  },
  'account.rewards.welcome.benefits.accordion1content': {
    value: 'Content',
    location: 'client',
  },
  'account.rewards.welcome.benefits.accordion2title': {
    value: 'Rewards Benefits',
    location: 'client',
  },
  'account.rewards.welcome.benefits.accordion2content': {
    value: 'Content',
    location: 'client',
  },
  'account.rewards.welcome.benefits.accordion3title': {
    value: 'Title goes here',
    location: 'client',
  },
  'account.rewards.welcome.benefits.accordion3content': {
    value: 'Content',
    location: 'client',
  },
  'account.rewards.optout.title': {
    value: 'Want to opt-out?',
    location: 'client',
  },
  'account.rewards.optout.text': {
    value:
      "We'd be sorry to see you go, but if you'd like to opt out of our reward scheme you can do so",
    location: 'client',
  },
  'account.rewards.optout.cta': {
    value: 'here',
    location: 'client',
  },
  'account.rewards.optout.modal.title': {
    value: 'Are you sure you want to opt-out?',
    location: 'client',
  },
  'account.rewards.optout.modal.description': {
    value: 'By opting out you will lose any current points and credit.',
    location: 'client',
  },
  'account.rewards.optout.modal.cancel': {
    value: 'No',
    location: 'client',
  },
  'account.rewards.optout.modal.confirm': {
    value: 'Yes opt-out',
    location: 'client',
  },
  'account.rewards.optin.title': {
    value: 'Opt-in to Rewards',
    location: 'client',
  },
  'account.rewards.optin.text': {
    value:
      "By opting into Rewards you'll earn exclusive benefits on your favourite products",
    location: 'client',
  },
  'account.rewards.optin.button.text': {
    value: 'Opt in',
    location: 'client',
  },
  'account.rewards.earnpoints.title': {
    value: 'How To Earn Points',
    location: 'client',
  },
  'account.rewards.earnpoints.text': {
    value: 'Make a purchase online or leave a product review',
    location: 'client',
  },
  'account.rewards.earnpoints.shopcard.title': {
    value: 'Shop',
    location: 'client',
  },
  'account.rewards.earnpoints.shopcard.text': {
    value: '£1 Spent = 1 Point to spend',
    location: 'client',
  },
  'account.rewards.earnpoints.earncard.title': {
    value: 'Earn',
    location: 'client',
  },
  'account.rewards.earnpoints.earncard.text': {
    value: '100 Points = £5 Reward',
    location: 'client',
  },
  'account.rewards.earnpoints.redeemcard.title': {
    value: 'Reward',
    location: 'client',
  },
  'account.rewards.earnpoints.redeemcard.text': {
    value: 'X Months To Redeem',
    location: 'client',
  },
  'account.rewards.balance.title': {
    value: "You're at Level %s",
    location: 'client',
  },
  'account.rewards.balance.text': {
    value: "Here you can see how many points you've earned to date.",
    location: 'client',
  },
  'account.rewards.balance.points.title': {
    value: 'Your Rewards Balance',
    location: 'client',
  },
  'account.rewards.summary.balance.points.title': {
    value: 'Rewards Balance',
    location: 'client',
  },
  'account.rewards.summary.balance.points.message': {
    value: 'Towards your next purchase!',
    location: 'client',
  },
  'account.rewards.balance.points.text': {
    value:
      'Here you can see how many points you’ve earned & unlock your Rewards.',
    location: 'client',
  },
  'account.rewards.balance.points.accordion1.title': {
    value: 'What are Status Points?',
    location: 'client',
  },
  'account.rewards.balance.points.accordion1.content': {
    value: 'Content',
    location: 'client',
  },
  'account.rewards.balance.points.accordion2.title': {
    value: 'How to redeem Rewards',
    location: 'client',
  },
  'account.rewards.balance.points.accordion2.content': {
    value: 'Content',
    location: 'client',
  },
  'account.rewards.balance.yourpoints': {
    value: 'Your Points',
    location: 'client',
  },
  'account.rewards.balance.pointstonextreward': {
    value: 'You’re %s points away from your next reward',
    location: 'client',
  },
  'account.rewards.balance.totalpoints': {
    value: 'Total Points',
    location: 'client',
  },
  'account.rewards.balance.pointstonexttier': {
    value: 'Points To Level %s',
    location: 'client',
  },
  'account.rewards.balance.tierbenefits': {
    value: 'Level %s Benefits',
    location: 'client',
  },
  'account.rewards.balance.benefits.accordion1.content': {
    value: 'Content',
    location: 'client',
  },
  'account.rewards.balance.benefits.accordion2.content': {
    value: 'Content',
    location: 'client',
  },
  'account.rewards.balance.benefits.accordion3.content': {
    value: 'Content',
    location: 'client',
  },
  'account.rewards.unlockrewards.description': {
    value:
      'You can unlock your new Rewards quickly by clicking the value you would like to redeem below. Once Rewards are unlocked, you have 30 days to spend them.',
    location: 'client',
  },
  'account.rewards.unlockrewards.title': {
    value: 'Unlock your rewards',
    location: 'client',
  },
  'account.rewards.unlockrewards.redeem': {
    value: 'Redeem',
    location: 'client',
  },
  'account.rewards.unlockrewards.redeem.available': {
    value: 'You can redeem this at any time',
    location: 'client',
  },
  'account.rewards.unlockrewards.redeem.pointstogo': {
    value: 'points to go',
    location: 'client',
  },
  'account.rewards.bonus.socialmedia.title': {
    value: 'Earn bonus Points',
    location: 'client',
  },
  'account.rewards.bonus.socialmedia.note.text': {
    value:
      '*Reward points can only be redeemed once per social platform. Once redeemed the social platform of your choice will no longer be redeemable.',
    location: 'client',
  },
  'account.rewards.bonus.socialmedia.description': {
    value: `You can earn bonus points by interacting with our socials, simply %s and $s on:`,
    location: 'client',
  },
  'account.rewards.bonus.socialmedia.like': {
    value: `like`,
    location: 'client',
  },
  'account.rewards.bonus.socialmedia.follow': {
    value: `follow us`,
    location: 'client',
  },
  'account.rewards.bonus.socialmedia.facebook': {
    value: 'Facebook',
    location: 'client',
  },
  'account.rewards.bonus.socialmedia.instagram': {
    value: 'Instagram',
    location: 'client',
  },
  'account.rewards.bonus.socialmedia.twitter': {
    value: 'Twitter',
    location: 'client',
  },
  'account.rewards.bonus.socialmedia.tiktok': {
    value: 'TikTok',
    location: 'client',
  },
  'account.rewards.bonus.socialmedia.success.message': {
    value: `%s points added from social media share`,
    location: 'client',
  },
  'account.address.deletemodal.title': {
    value: 'Delete address',
    location: 'client',
  },
  'account.address.deletemodal.subtitle': {
    value: 'Are you sure you want to delete this address?',
    location: 'client',
  },
  'account.deletepaymentmodal.delete': {
    value: 'Delete',
    location: 'client',
  },
  'account.deletepaymentmodal.cancel': {
    value: 'Cancel',
    location: 'client',
  },
  'general.social_share.twitter.text': {
    value: 'Share this on Twitter',
    location: 'client',
  },
  'general.social_share.facebook.text': {
    value: 'Share this on Facebook',
    location: 'client',
  },
  'general.social_share.pinterest.text': {
    value: 'Share this on Pinterest',
    location: 'client',
  },
  'general.social_share.email.text': {
    value: 'Share this by Email',
    location: 'client',
  },
  'general.social_share.whatsapp.text': {
    value: 'Share this on WhatsApp',
    location: 'client',
  },
  'account.accountsummary.section.title.messagecenter': {
    value: 'Customer Service Contact Centre',
    location: 'client',
  },
  'account.accountsummary.creditmessage.title': {
    value: 'Account Credit',
    location: 'client',
  },
  'myaccount.livechat.text': {
    value: 'Live Chat',
    location: 'client',
  },
  'myaccount.adachat.text': {
    value: 'Ada Chat',
    location: 'client',
  },
  'myaccount.livechatavailable.text': {
    value: 'Available',
    location: 'client',
  },
  'myaccount.livechatunavailable.text': {
    value: 'Unavailable',
    location: 'client',
  },
  'myaccount.message.text': {
    value: 'Message',
    location: 'client',
  },
  'myaccount.viewyourmessages.text': {
    value: 'View your messages',
    location: 'client',
  },
  'myaccount.helpcentre.contact.text': {
    value: 'Contact Us by phone on 033300444444',
    location: 'client',
  },
  'myaccount.helpcentre.contact.alttext': {
    value:
      'Lines are open 8am - 6pm, 7days a week. Check your service provider for changes',
    location: 'client',
  },
  'myaccount.helpcentre.contact.number': {
    value: '033300444444',
    location: 'client',
  },
  'myaccount.helpcentre.experiment.text': {
    value: 'Contact Us',
    location: 'client',
  },
  'myaccount.helpcentre.text': {
    value: 'Contact us',
    location: 'client',
  },
  'general.author.label': {
    value: 'Author',
    location: 'client',
  },
  'general.position.label': {
    value: 'Position',
    location: 'client',
  },
  'general.articleposted.label': {
    value: 'Posted this article',
    location: 'client',
  },
  'general.viewprofile.text': {
    value: "View %s's profile",
    location: 'client',
  },
  'general.productreviewsingle.label': {
    value: '%s out of %s stars. %s review',
    location: 'client',
  },
  'general.productreview.label': {
    value: '%s out of %s stars. %s reviews',
    location: 'client',
  },
  'footer.countryselector.label': {
    value: 'country selector',
    location: 'client',
  },
  'basket.superize.button.text': {
    value: 'Supersize Now',
    location: 'client',
  },
  'basket.supersizemessage.part1': {
    value: 'Supersize to',
    location: 'client',
  },
  'basket.dropship.item.message': {
    value:
      'This product must be returned by pre paid post slip and cannot be returned to store',
    location: 'client',
  },
  'basket.dropship.message': {
    value:
      'Some items in your bag are not eligible for in-store returns. Please review your items for details',
    location: 'client',
  },
  'basket.supersizemessage.part2': {
    value: 'Save',
    location: 'client',
  },
  'basket.supersizemessage.part3': {
    value: 'per',
    location: 'client',
  },
  'header.basket.menu.arialabel': {
    value: 'Basket Menu',
    location: 'client',
  },
  'general.productbuynow.label': {
    value: 'Buy %s now',
    location: 'client',
  },
  'header.basket.summary.singular.text': {
    value: 'item in basket',
    location: 'client',
  },
  'header.basket.summary.text': {
    value: 'items in basket',
    location: 'client',
  },
  'header.basket.viewbasket.text': {
    value: 'View Basket',
    location: 'client',
  },
  'header.basket.noitems.text': {
    value: 'There are currently no items in your basket.',
    location: 'client',
  },
  'header.basket.button.continueshopping.text': {
    value: 'Continue Shopping',
    location: 'client',
  },
  'general.item.text': { value: 'Item', location: 'client' },
  'general.items.text': { value: 'Items', location: 'client' },
  'general.price.text': { value: 'Price', location: 'client' },
  'general.quantity.text': { value: 'Quantity', location: 'client' },
  'general.subtotal.text': { value: 'Subtotal', location: 'client' },
  'basket.subtotal.text': {
    value: 'Basket subtotal:',
    location: 'client',
  },
  'basket.total.text': {
    value: 'Basket Total:',
    location: 'client',
  },
  'account.order.payment.error.text': {
    value:
      'There is a problem with your card payment, please click resolve below to update your payment information',
    location: 'client',
  },
  'account.order.text': {
    value: 'Order',
    location: 'client',
  },
  'myaccount.resolve.text': {
    value: 'Resolve',
    location: 'client',
  },
  'myaccount.order.info.button': {
    value: 'Contact customer services',
    location: 'client',
  },
  'header.contentsearchform.placeholder': {
    value: 'Search all articles',
    location: 'client',
  },
  'header.contentsearchform.title': {
    value: 'search articles',
    location: 'client',
  },
  'header.contentsearchbutton.title': {
    value: 'start article search',
    location: 'client',
  },
  'header.basket.items.arialabel': {
    value: 'Your items',
    location: 'client',
  },
  'header.basket.qty.text': {
    value: 'Quantity:',
    location: 'client',
  },
  'general.basket.qty.text': {
    value: 'Quantity:',
    location: 'client',
  },
  'general.quantity.decrease': {
    value: 'Decrease',
    location: 'client',
  },
  'general.quantity.decrease.dynamic': {
    value: 'Decrease',
    location: 'client',
  },
  'general.quantity.increase': {
    value: 'Increase',
    location: 'client',
  },
  'general.quantity.increase.dynamic': {
    value: 'Increase',
    location: 'client',
  },
  'catalogue.nav.panel.account.accountlinks.link.text': {
    value: 'Social Links',
    location: 'client',
  },
  'header.brands.view.all.text': {
    value: 'View All Brands',
    location: 'client',
  },
  'general.article.preview.author': {
    value: 'By %s',
    location: 'client',
  },
  'account.accountsummary.subscriptions.title': {
    value: 'My Subscriptions',
    location: 'client',
  },
  'account.accountsummary.changefrequency.success': {
    value: 'Changes to your subscription have successfully saved',
    location: 'client',
  },
  'account.accountsummary.subscriptions.cardproblem.message': {
    value:
      'There is a problem with your card payment. Please contact our customer service team who will be able to advise how to proceed to make sure you get your next box.',
    location: 'client',
  },
  'account.accountsummary.subscriptions.status': {
    value: 'Status',
    location: 'client',
  },
  'account.subscriptiondetail.status.new': {
    value: 'New',
    location: 'client',
  },
  'account.subscriptiondetail.next.order': {
    value: 'Next order',
    location: 'client',
  },
  'account.subscriptiondetail.status.active': {
    value: 'Active',
    location: 'client',
  },
  'account.subscriptiondetail.next.billing.date': {
    value: 'Next billing date',
    location: 'client',
  },
  'account.subscriptiondetail.cancel': {
    value: 'Cancel subscription',
    location: 'client',
  },
  'account.subscriptiondetail.status.notice_period': {
    value: 'Notice Period',
    location: 'client',
  },
  'account.subscriptiondetail.status.complete': {
    value: 'Complete',
    location: 'client',
  },
  'account.subscriptiondetail.status.cancelled': {
    value: 'Cancelled',
    location: 'client',
  },
  'account.wishlist.comingsoon.text': {
    value: 'Coming soon',
    location: 'client',
  },
  'account.subscriptiondetail.status.failed_payment': {
    value: 'Failed Payment',
    location: 'client',
  },
  'account.subscriptiondetail.status.preprocessed': {
    value: 'Preprocessed',
    location: 'client',
  },
  'account.purchase.view.details.text': {
    value: 'View details',
    location: 'client',
  },
  'account.view.return.details.text': {
    value: 'View return details',
    location: 'client',
  },
  'account.accountsummary.subscriptions.paymentterm': {
    value: 'Payment term',
    location: 'client',
  },
  'account.accountsummary.subscriptions.initialorderdate': {
    value: 'Initial order date',
    location: 'client',
  },
  'account.accountsummary.subscriptions.renewterm': {
    value: 'Renew term',
    location: 'client',
  },
  'account.subscriptiondetail.paymentterm.rolling': {
    value: 'Auto-Renew',
    location: 'client',
  },
  'account.accountsummary.subscriptions.summary.heading': {
    value: '%s products: ',
    location: 'client',
  },
  'account.subscriptiondetail.paymentterm.notrolling': {
    value: 'Auto-Renew off',
    location: 'client',
  },
  'account.subscriptiondetail.paymentterm.rolling.description': {
    value:
      'This subscription will automatically renew at the end of the payment term.',
    location: 'client',
  },
  'account.subscriptiondetail.manage': {
    value: 'Manage Subscription',
    location: 'client',
  },
  'account.subscriptiondetail.paymentterm.notrolling.description': {
    value:
      'This subscription will not automatically renew at the end of the payment term.',
    location: 'client',
  },
  'account.orders.actions.button.resolve.text': {
    value: 'Resolve',
    location: 'client',
  },
  'account.subscriptiondetail.paymenttype.weekly': {
    value: 'per week',
    location: 'client',
  },
  'account.subscriptiondetail.paymenttype.monthly': {
    value: 'per month',
    location: 'client',
  },
  'account.subscriptiondetail.paymenttype.yearly': {
    value: 'per year',
    location: 'client',
  },
  'account.subscriptiondetail.paymenttype.upfront': {
    value: 'paid upfront',
    location: 'client',
  },
  'account.subscriptiondetail.paymentfrequency.weeks': {
    value: 'weeks',
    location: 'client',
  },
  'account.subscriptiondetail.paymentfrequency.months': {
    value: 'months',
    location: 'client',
  },
  'account.subscriptiondetail.paymentfrequency.years': {
    value: 'years',
    location: 'client',
  },
  'account.subscriptiondetail.sendmessage.text': {
    value: 'Send a message',
    location: 'client',
  },
  'contentheader.latest.text': {
    value: 'Latest',
    location: 'client',
  },
  'header.brands.nav.aria.label': {
    value: 'Sub menu for brands',
    location: 'client',
  },
  'article.references.title': {
    value: 'References',
    location: 'client',
  },
  'general.redirectscreen.title': {
    value: "We're redirecting you",
    location: 'client',
  },
  'general.helpcentre.text': {
    value: 'Help centre',
    location: 'client',
  },
  'general.redirectscreen.message': {
    value:
      'Please wait whilst we authenticate you, you will be redirected back to the site soon.',
    location: 'client',
  },
  'article.relatedarticles.title': {
    value: 'Related Articles',
    location: 'client',
  },
  'content.authorprefix.text': {
    value: 'By %s',
    location: 'client',
  },
  'content.authorcarousel.description': {
    value: 'Our Editors',
    location: 'client',
  },
  'account.accountsummary.subscriptions.none': {
    value: 'You currently have no subscriptions.',
    location: 'client',
  },
  'messages.text-area.limit': {
    value: 'character limit',
    location: 'client',
  },
  'account.messages.nomessages.title': {
    value: 'You currently have no messages.',
    location: 'client',
  },
  'account.messages.create.error': {
    value: 'Error while creating discussion',
    location: 'client',
  },
  'title.page.account.messages.returns.text': {
    value: 'Return items',
    location: 'client',
  },
  'title.page.account.messages.create.text': {
    value: 'Create message',
    location: 'client',
  },
  'title.page.account.cancelorder.text': {
    value: 'Cancel items',
    location: 'client',
  },
  'title.page.account.order.addreview.text': {
    value: 'Write a review',
    location: 'client',
  },
  'account.messages.message.error': {
    value: 'Error while replying to message',
    location: 'client',
  },
  'account.messages.message.discussion.error': {
    value: 'Error fetching message thread',
    location: 'client',
  },
  'account.messages.message.success': {
    value: 'Reply added to message thread',
    location: 'client',
  },
  'account.messages.create.selectproducts': {
    value: 'Please select a product from the order',
    location: 'client',
  },
  'account.messages.return.selectreason': {
    value: 'Please select a reason for return',
    location: 'client',
  },
  'account.messages.create.category.label': {
    value: 'Category',
    location: 'client',
  },
  'account.messages.create.category.option.damageditem': {
    value: 'Damaged item',
    location: 'client',
  },
  'account.messages.create.category.option.incorrectitems': {
    value: 'Incorrect item recieved',
    location: 'client',
  },
  'account.messages.create.category.option.invoicerequest': {
    value: 'Request an invoice',
    location: 'client',
  },
  'account.messages.create.category.option.returnanitem': {
    value: 'Return an item',
    location: 'client',
  },
  'account.messages.create.category.option.missingitem': {
    value: 'Missing item',
    location: 'client',
  },
  'account.messages.create.category.option.trackingnotupdated': {
    value: 'Tracking not updated',
    location: 'client',
  },
  'account.messages.create.category.option.pricebeater': {
    value: 'Price beater',
    location: 'client',
  },
  'account.messages.create.category.option.qualityissue': {
    value: 'Quality issue',
    location: 'client',
  },
  'account.messages.create.category.option.taxrequest': {
    value: 'Tax request',
    location: 'client',
  },
  'account.messages.create.category.option.returnupdate': {
    value: 'Update on a return',
    location: 'client',
  },
  'account.messages.create.category.option.cancellation': {
    value: 'Cancellation',
    location: 'client',
  },
  'account.messages.create.category.option.checkout': {
    value: 'Checkout',
    location: 'client',
  },
  'account.messages.create.category.option.offers-and-promotions': {
    value: 'Offers and Promotions',
    location: 'client',
  },
  'account.messages.create.category.option.payment': {
    value: 'Payment',
    location: 'client',
  },
  'account.messages.create.category.option.placing-an-order': {
    value: 'Placing an order',
    location: 'client',
  },
  'account.messages.create.category.option.product-info': {
    value: 'Product information',
    location: 'client',
  },
  'account.messages.create.category.option.returns-and-refunds': {
    value: 'Returns and Refunds',
    location: 'client',
  },
  'account.messages.create.category.option.website-issues': {
    value: 'Website issues',
    location: 'client',
  },
  'account.messages.create.category.option.wheres-my-order': {
    value: 'Wheres my order?',
    location: 'client',
  },
  'account.messages.create.category.option.delivery-problem': {
    value: 'Delivery problem',
    location: 'client',
  },
  'account.messages.create.category.option.feedback': {
    value: 'Feedback',
    location: 'client',
  },
  'account.messages.create.category.option.problem-with-my-order': {
    value: 'Problem with my order',
    location: 'client',
  },
  'account.messages.create.category.option.nutritional-advice': {
    value: 'Nutritional advice',
    location: 'client',
  },
  'account.messages.create.category.option.stock-availability': {
    value: 'Stock availability',
    location: 'client',
  },
  'account.messages.create.category.option.i-want-to-create-account': {
    value: 'I want to create an account',
    location: 'client',
  },
  'account.messages.create.category.option.i-want-to-delete-account': {
    value: 'I would like to delete my account / submit a data request change',
    location: 'client',
  },
  'account.messages.create.category.option.order-dispatched-not-arrived': {
    value: 'My Order has been dispatched but has not yet arrived',
    location: 'client',
  },
  'account.messages.create.category.option.item-missing-from-order': {
    value: 'An Item is missing from my order',
    location: 'client',
  },
  'account.messages.create.category.option.item-arrived-damaged': {
    value: 'My Item arrived damaged',
    location: 'client',
  },
  'account.messages.create.category.option.return-item': {
    value: 'I want to return an item',
    location: 'client',
  },
  'account.messages.create.category.option.product-quality-issue': {
    value: 'I have an issue with the quality of my product',
    location: 'client',
  },
  'account.messages.create.category.option.received-incorrect-item': {
    value: 'I received an incorrect item',
    location: 'client',
  },
  'account.messages.create.return.title': {
    value: 'Return Items',
    location: 'client',
  },
  'account.messages.create.category.placeholder': {
    value: 'Please select a category',
    location: 'client',
  },
  'account.messages.create.category.defaultmessage': {
    value: 'Default Message',
    location: 'client',
  },
  'account.customerservice.contact.form.instruction': {
    value:
      'Please complete the following fields to submit your enquiry. All fields that are marked with an * are mandatory.',
    location: 'client',
  },
  'myaccount.customerservice.returnsreasons.faulty.text': {
    value: 'I want to return a faulty item',
    location: 'client',
  },
  'myaccount.customerservice.returnsreasons.notrequired.text': {
    value: 'I want to return an item no longer required',
    location: 'client',
  },
  'myaccount.customerservice.returnsreasons.damaged.text': {
    value: 'I want to return a damaged item',
    location: 'client',
  },
  'myaccount.customerservice.returnsreasons.incorrect.text': {
    value: 'I have received an incorrect item',
    location: 'client',
  },
  'account.messages.create.return.start.return.form.button.text': {
    value: 'Start return form',
    location: 'client',
  },
  'account.messages.create.return.dropdown.placeholder': {
    value: 'Please select reason',
    location: 'client',
  },
  'account.messages.create.return.dropdown.label': {
    value: 'Reason for return',
    location: 'client',
  },
  'account.messages.create.return.product-list.heading': {
    value: 'Product List',
    location: 'client',
  },
  'account.customerservice.return.returnable.product.message.part1': {
    value:
      'You will be able to choose the products you wish to return once you start your',
    location: 'client',
  },
  'account.customerservice.return.returnable.product.message.part2': {
    value: 'Return Form',
    location: 'client',
  },
  'account.customerservice.return.returnable.products.title': {
    value: 'Returnable Products',
    location: 'client',
  },
  'account.customerservice.return.nonreturnable.products.title': {
    value: 'Non-Returnable Products',
    location: 'client',
  },
  'account.customerservice.return.nonreturnable.products.message': {
    value: 'If you want to return any of these products, please',
    location: 'client',
  },
  'account.messages.create.return.product-list.no-eligible-product': {
    value: 'No eligible products',
    location: 'client',
  },
  'account.messages.message.lastmessage': {
    value: 'Last message',
    location: 'client',
  },
  'account.messages.message.newmessage': {
    value: 'New Message',
    location: 'client',
  },
  'account.messages.message.lastreplied': {
    value: 'Last replied',
    location: 'client',
  },
  'account.messages.message.sentby': {
    value: 'Sent by',
    location: 'client',
  },
  'account.messages.message.status.completed': {
    value: 'Completed',
    location: 'client',
  },
  'account.messages.message.status.inprogress': {
    value: 'In Progress',
    location: 'client',
  },
  'account.messages.message.status.outstanding': {
    value: 'Outstanding',
    location: 'client',
  },
  'account.messages.message.status.pending': {
    value: 'Pending',
    location: 'client',
  },
  'account.messages.message.orderitems': {
    value: 'Order items',
    location: 'client',
  },
  'account.messages.message.issue': {
    value: 'Issue',
    location: 'client',
  },
  'account.messages.message.queriedproducts': {
    value: 'Queried products',
    location: 'client',
  },
  'account.messages.message.you': {
    value: 'You',
    location: 'client',
  },
  'account.messages.message.customerinfo': {
    value: 'Customer info',
    location: 'client',
  },
  'titles.page.account-home.text': {
    value: 'Account Home',
    location: 'client',
  },
  'title.page.account.rewards.text': {
    value: 'Your Rewards',
    location: 'client',
  },
  'titles.page.account-cancel-order.text': {
    value: 'Cancel order',
    location: 'client',
  },
  'titles.page.account.messages.text': {
    value: 'Messages',
    location: 'client',
  },
  'titles.page.account.messages.create.text': {
    value: 'Create message',
    location: 'client',
  },
  'account.messages.category.cancellation': {
    value: 'Cancellation',
    location: 'client',
  },
  'account.messages.category.checkout': {
    value: 'Checkout',
    location: 'client',
  },
  'account.messages.category.pricebeater': {
    value: 'PriceBeater request',
    location: 'client',
  },
  'account.messages.category.placinganorder': {
    value: 'Placing an order',
    location: 'client',
  },
  'account.messages.category.payment': {
    value: 'Payment',
    location: 'client',
  },
  'account.messages.category.offersandpromotions': {
    value: 'Offers and Promotions',
    location: 'client',
  },
  'account.messages.category.nutritionaladvice': {
    value: 'Nutritional advice',
    location: 'client',
  },
  'account.messages.category.createaccount': {
    value: 'Creating an account',
    location: 'client',
  },
  'account.messages.category.freegift': {
    value: 'Free gift',
    location: 'client',
  },
  'account.messages.category.feedback': {
    value: 'Feedback',
    location: 'client',
  },
  'account.messages.category.deliveryproblem': {
    value: 'Delivery problem',
    location: 'client',
  },
  'account.messages.lastmessage': {
    value: 'Last message',
    location: 'client',
  },
  'account.messages.lastreplied': {
    value: 'Last replied',
    location: 'client',
  },
  'titles.page.account.message.text': {
    value: 'Message',
    location: 'client',
  },
  'titles.page.account-marketing.text': {
    value: 'Account Marketing',
    location: 'client',
  },
  'titles.page.profiles.text': {
    value: 'Account Profiles',
    location: 'client',
  },
  'titles.page.account.emailsignup.text': {
    value: 'Email Sign Up',
    location: 'client',
  },
  'account.emailsignup.success': {
    value: 'You are now signed up to our newsletter.',
    location: 'client',
  },
  'titles.page.account.smsoptout.text': {
    value: 'Sms Unsubscribe',
    location: 'client',
  },
  'titles.page.order.history.text': {
    value: 'Order History',
    location: 'client',
  },
  'titles.page.account.details.text': {
    value: 'Account Details',
    location: 'client',
  },
  'titles.page.account.credit.text': {
    value: 'Account Credit',
    location: 'client',
  },
  'account.credit.meta.description.part1': {
    value: 'Credit can be gained in several ways. Visit our',
    location: 'client',
  },
  'account.credit.meta.description.part2': {
    value: '/terms-conditions/',
    location: 'client',
  },
  'account.credit.meta.description.part3': {
    value: 'terms & conditions',
    location: 'client',
  },
  'account.credit.meta.description.part4': {
    value: 'to learn more on account credit.',
    location: 'client',
  },
  'account.credit.meta.expiring.text': {
    value: '%s of your credit expires in the next 7 days',
    location: 'client',
  },
  'account.credit.meta.balance.text': {
    value: 'Balance:',
    location: 'client',
  },
  'account.credit.meta.muliplecurrencyavailable.text': {
    value: 'You have credit available in multiple currencies:',
    location: 'client',
  },
  'account.credit.history.heading': {
    value: 'Credit History',
    location: 'client',
  },
  'account.credit.history.expired.text': {
    value: 'Credit Expired',
    location: 'client',
  },
  'account.credit.history.end.text': {
    value: 'End of credit history',
    location: 'client',
  },
  'account.credit.nocredithistory.end.text': {
    value: 'You have no credit history yet',
    location: 'client',
  },
  'titles.page.account.emailunsubscribe.text': {
    value: 'Email Unsubscribe',
    location: 'client',
  },
  'meta.keywords.end.text': {
    value: 'extra keywords',
    location: 'client',
  },
  'product.recommend.manual.title': {
    value: 'Style with',
    location: 'client',
  },
  'product.price.from.text': {
    value: 'from',
    location: 'client',
  },
  'product.price.rrp.text': {
    value: 'RRP:',
    location: 'client',
  },
  'product.price.saving.up.to.text': {
    value: 'Save up to:',
    location: 'client',
  },
  'product.buynowpaylaterproviders.with': {
    value: 'with',
    location: 'client',
  },
  'product.buynowpaylater.viewoptions.text': {
    value: 'View Options',
    location: 'client',
  },
  'product.buynowpaylaterproviders.learnmore': {
    value: 'Learn more',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.newpay': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.afterpay': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.clearpay': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.openpay': {
    value: '%s monthly instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.splitit': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.klarna': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.klarna_slice': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.frasers_plus': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.quadpay': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.zippay': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.humm': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.paypal_in_3': {
    value: '%s instalments %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.laybuy': {
    value: '%s weekly payments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.sezzle': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.atom': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.instalmentsof.atome': {
    value: '%s instalments of %s %s %s',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.afterpay': {
    value: 'Afterpay',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.clearpay': {
    value: 'Clearpay',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.openpay': {
    value: 'Openpay',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.splitit': {
    value: 'Splitit',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.klarna_slice': {
    value: 'Klarna Slice',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.klarna': {
    value: 'Klarna',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.frasers_plus': {
    value: 'Frasers Plus',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.quadpay': {
    value: 'Zip',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.zippay': {
    value: 'Zip',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.humm': {
    value: 'Humm',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.paypal_in_3': {
    value: 'Paypal In 3',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.laybuy': {
    value: 'Laybuy',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.sezzle': {
    value: 'Sezzle',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.atom': {
    value: 'Atom',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.atome': {
    value: 'Atome',
    location: 'client',
  },
  'product.buynowpaylaterproviders.displayname.newpay': {
    value: 'Newpay',
    location: 'client',
  },
  'personalisation.item.disclaimer.text': {
    value: 'Made to order. Usually dispatched within 72 hours',
    location: 'client',
  },
  'personalisation.productbuynow.text': {
    value: 'Personalise',
    location: 'client',
  },
  'general.productsoldout.text': {
    value: 'Sold Out',
    location: 'client',
  },
  'general.productbuynow.text': {
    value: 'Shop now',
    location: 'client',
  },
  'general.redirectshopnow.warning': {
    value:
      'Clicking Shop Now will take you to another site to complete your purchase',
    location: 'client',
  },
  'reviews.stars.label': {
    value: 'Stars',
    location: 'client',
  },
  'reviews.review.label': {
    value: 'Review',
    location: 'client',
  },
  'reviews.reviews.label': {
    value: 'Reviews',
    location: 'client',
  },
  'widget.buildyourbundle.backinstocksoon.text': {
    value: 'Back in stock soon',
    location: 'client',
  },
  'widget.buildyourbundle.categories.text': {
    value: 'Categories',
    location: 'client',
  },
  'widget.buildyourbundle.items.text': {
    value: 'item(s)',
    location: 'client',
  },
  'widget.buildyourbundle.ofitems.text': {
    value: ' of %s item(s)',
    location: 'client',
  },
  'widget.buildyourbundle.title.text': {
    value: 'Build your own Bundle',
    location: 'client',
  },
  'widget.buildyourbundle.selected.text': {
    value: 'Selected',
    location: 'client',
  },
  'widget.buildyourbundle.chooseitems.text': {
    value: 'Choose item(s)',
    location: 'client',
  },
  'widget.buildyourbundle.oftext.text': {
    value: ' of %s',
    location: 'client',
  },
  'widget.buildyourbundle.yourbundletotal.text': {
    value: 'Your bundle total',
    location: 'client',
  },
  'widget.buildyourbundle.yourtotal.text': {
    value: 'Your Total',
    location: 'client',
  },
  'widget.buildyourbundle.addtobasketsimple.text': {
    value: 'add',
    location: 'client',
  },
  'widget.buildyourownbundle.alternateImage.text': {
    value: 'image',
    location: 'client',
  },
  'widget.recipe.servings': {
    value: 'Servings',
    location: 'client',
  },
  'widget.recipe.prep.time': {
    value: 'Prep Time',
    location: 'client',
  },
  'widget.recipe.cook.time': {
    value: 'Cook Time',
    location: 'client',
  },
  'widget.recipe.passive.time': {
    value: 'Passive Time',
    location: 'client',
  },
  'widget.recipe.ingredients': {
    value: 'Ingredients',
    location: 'client',
  },
  'widget.recipe.instructions': {
    value: 'Method',
    location: 'client',
  },
  'widget.recipe.nutritional.info': {
    value: 'Nutritional info per serving:',
    location: 'client',
  },
  'widget.recipe.calories': {
    value: 'Calories',
    location: 'client',
  },
  'widget.recipe.total.fat': {
    value: 'Total Fat',
    location: 'client',
  },
  'widget.recipe.saturated.fat': {
    value: 'Saturated Fat',
    location: 'client',
  },
  'widget.recipe.trans.fat': {
    value: 'Trans Fat',
    location: 'client',
  },
  'widget.recipe.polyunsaturated.fat': {
    value: 'Polyunsaturated Fat',
    location: 'client',
  },
  'widget.recipe.monounsaturated.fat': {
    value: 'Monounsaturated Fat',
    location: 'client',
  },
  'widget.recipe.cholesterol': {
    value: 'Cholesterol',
    location: 'client',
  },
  'widget.recipe.sodium': {
    value: 'Sodium',
    location: 'client',
  },
  'widget.recipe.potassium': {
    value: 'Potassium',
    location: 'client',
  },
  'widget.recipe.total.carbohydrates': {
    value: 'Total Carbohydrates',
    location: 'client',
  },
  'widget.recipe.dietary.fiber': {
    value: 'Dietary Fiber',
    location: 'client',
  },
  'widget.recipe.sugar': {
    value: 'Sugar',
    location: 'client',
  },
  'widget.recipe.protein': {
    value: 'Protein',
    location: 'client',
  },
  'widget.recipe.vitamin.a': {
    value: 'Vitamin A',
    location: 'client',
  },
  'widget.recipe.vitamin.c': {
    value: 'Vitamin C',
    location: 'client',
  },
  'widget.recipe.calcium': {
    value: 'Calcium',
    location: 'client',
  },
  'widget.recipe.iron': {
    value: 'Iron',
    location: 'client',
  },
  'widget.recipe.daily.value': {
    value: '% Daily Value',
    location: 'client',
  },
  'widget.qa.question': {
    value: 'Q',
    location: 'client',
  },
  'widget.qa.answer': {
    value: 'A',
    location: 'client',
  },
  'widget.macrocalculator.title': {
    value: 'Macronutrient Calculator',
    location: 'client',
  },
  'widget.macrocalculator.gender': {
    value: 'Gender',
    location: 'client',
  },
  'widget.macrocalculator.gender.female': {
    value: 'Female',
    location: 'client',
  },
  'widget.macrocalculator.gender.male': {
    value: 'Male',
    location: 'client',
  },
  'widget.macrocalculator.units': {
    value: 'Units',
    location: 'client',
  },
  'widget.macrocalculator.units.metric': {
    value: 'Metric',
    location: 'client',
  },
  'widget.macrocalculator.units.imperial': {
    value: 'Imperial',
    location: 'client',
  },
  'widget.macrocalculator.age': {
    value: 'Age',
    location: 'client',
  },
  'widget.macrocalculator.height': {
    value: 'Height',
    location: 'client',
  },
  'widget.macrocalculator.weight': {
    value: 'Weight',
    location: 'client',
  },
  'widget.macrocalculator.goal': {
    value: 'Goal',
    location: 'client',
  },
  'widget.macrocalculator.goal.loss': {
    value: 'Fat/Weight loss',
    location: 'client',
  },
  'widget.macrocalculator.goal.maintain': {
    value: 'Maintain muscle/weight',
    location: 'client',
  },
  'widget.macrocalculator.goal.gain': {
    value: 'Muscle/weight gain',
    location: 'client',
  },
  'widget.macrocalculator.activitylevel': {
    value: 'Activity level',
    location: 'client',
  },
  'widget.macrocalculator.activitylevel.sedentary': {
    value: 'Sedentary',
    location: 'client',
  },
  'widget.macrocalculator.activitylevel.active': {
    value: 'Moderately active',
    location: 'client',
  },
  'widget.macrocalculator.activitylevel.vigorouslyactive': {
    value: 'Vigorously active',
    location: 'client',
  },
  'widget.macrocalculator.activitylevel.extremelyactive': {
    value: 'Extremely active',
    location: 'client',
  },
  'widget.macrocalculator.calculate': {
    value: 'Calculate',
    location: 'client',
  },
  'widget.macrocalculator.macro.information': {
    value:
      'Protein and fat values based on 2018 ISSN exercise & sports nutrition review. Carbohydrate values represent remaining calories after protein and fat are calculated.',
    location: 'client',
  },
  'widget.macrocalculator.form.validation.age.required': {
    value: 'Please enter your age',
    location: 'client',
  },
  'widget.macrocalculator.form.validation.gender.required': {
    value: 'Please select a gender',
    location: 'client',
  },
  'widget.macrocalculator.form.validation.unit.required': {
    value: 'Please select a unit',
    location: 'client',
  },
  'widget.macrocalculator.form.validation.height.required': {
    value: 'Please enter your height',
    location: 'client',
  },
  'widget.macrocalculator.form.validation.weight.required': {
    value: 'Please enter your weight',
    location: 'client',
  },
  'widget.macrocalculator.form.validation.goal.required': {
    value: 'Please select a goal',
    location: 'client',
  },
  'widget.macrocalculator.form.validation.activitylevel.required': {
    value: 'Please select an activity level',
    location: 'client',
  },
  'widget.infinitescroll.showmore': {
    value: 'Show more articles',
    location: 'client',
  },
  'widget.macrocalculator.results.title': {
    value: 'Your daily intake',
    location: 'client',
  },
  'widget.macrocalculator.results.carbs': {
    value: 'Carbs',
    location: 'client',
  },
  'widget.macrocalculator.results.protein': {
    value: 'Protein',
    location: 'client',
  },
  'widget.macrocalculator.results.fats': {
    value: 'Fats',
    location: 'client',
  },
  'widget.macrocalculator.results.goalsbutton': {
    value: 'Shop for your goals',
    location: 'client',
  },
  'widget.macrocalculator.results.calculateagain': {
    value: 'Calculate again',
    location: 'client',
  },
  'widget.listpage.priceperunit.text': {
    value: '%s%s per %s',
    location: 'client',
  },
  'paymentmethods.adyenv2.svg.title': {
    value: 'Adyen V2',
    location: 'client',
  },
  'paymentmethods.afterpay.svg.title': {
    value: 'Afterpay',
    location: 'client',
  },
  'paymentmethods.amazonpay.svg.title': {
    value: 'Amazon Pay',
    location: 'client',
  },
  'paymentmethods.alipay.svg.title': {
    value: 'Alipay',
    location: 'client',
  },
  'paymentmethods.alipay.plus.svg.title': {
    value: 'Alipay Plus',
    location: 'client',
  },
  'paymentmethods.american.express.svg.title': {
    value: 'American Express',
    location: 'client',
  },
  'paymentmethods.apple.pay.svg.title': {
    value: 'Apple Pay',
    location: 'client',
  },
  'paymentmethods.arvato.svg.title': {
    value: 'Arvato',
    location: 'client',
  },
  'paymentmethods.bancontact.svg.title': {
    value: 'Bancontact',
    location: 'client',
  },
  'paymentmethods.clearpay.svg.title': {
    value: 'Clearpay',
    location: 'client',
  },
  'paymentmethods.diners.club.svg.title': {
    value: 'Diners Club',
    location: 'client',
  },
  'paymentmethods.discover.svg.title': {
    value: 'Discover',
    location: 'client',
  },
  'paymentmethods.elo.svg.title': {
    value: 'Elo',
    location: 'client',
  },
  'paymentmethods.gift.card.svg.title': {
    value: 'Gift Card',
    location: 'client',
  },
  'paymentmethods.giro.pay.svg.title': {
    value: 'Giro pay',
    location: 'client',
  },
  'paymentmethods.google.pay.svg.title': {
    value: 'Google pay',
    location: 'client',
  },
  'paymentmethods.hiper.svg.title': {
    value: 'Hiper',
    location: 'client',
  },
  'paymentmethods.humm.svg.title': {
    value: 'Humm',
    location: 'client',
  },
  'paymentmethods.ideal.svg.title': {
    value: 'Ideal',
    location: 'client',
  },
  'paymentmethods.interac.svg.title': {
    value: 'Interac',
    location: 'client',
  },
  'paymentmethods.jcb.svg.title': {
    value: 'JCB',
    location: 'client',
  },
  'paymentmethods.kcp.svg.title': {
    value: 'KCP',
    location: 'client',
  },
  'paymentmethods.klarna.svg.title': {
    value: 'Klarna',
    location: 'client',
  },
  'paymentmethods.upi.svg.title': {
    value: 'Upi',
    location: 'client',
  },
  'paymentmethods.frasersplus.svg.title': {
    value: 'Frasers Plus',
    location: 'client',
  },
  'paymentmethods.laybuy.svg.title': {
    value: 'Laybuy',
    location: 'client',
  },
  'paymentmethods.maestro.svg.title': {
    value: 'Maestro',
    location: 'client',
  },
  'paymentmethods.mastercard.svg.title': {
    value: 'Mastercard',
    location: 'client',
  },
  'paymentmethods.mode.svg.title': {
    value: 'Mode',
    location: 'client',
  },
  'paymentmethods.molpay.svg.title': {
    value: 'MOLPay',
    location: 'client',
  },
  'paymentmethods.multibanco.svg.title': {
    value: 'Multibanco',
    location: 'client',
  },
  'paymentmethods.newpay.svg.title': {
    value: 'Newpay',
    location: 'client',
  },
  'paymentmethods.one4all.svg.title': {
    value: 'One 4 All',
    location: 'client',
  },
  'paymentmethods.openpay.svg.title': {
    value: 'Open Pay',
    location: 'client',
  },
  'paymentmethods.paypal.svg.title': {
    value: 'Paypal',
    location: 'client',
  },
  'paymentmethods.paypal.in3.svg.title': {
    value: 'Paypal In3',
    location: 'client',
  },
  'paymentmethods.paytm.svg.title': {
    value: 'Paytm',
    location: 'client',
  },
  'paymentmethods.payvyne.svg.title': {
    value: 'Payvyne',
    location: 'client',
  },
  'paymentmethods.primeiropay.svg.title': {
    value: 'PrimeiroPay',
    location: 'client',
  },
  'paymentmethods.qiwiwallet.svg.title': {
    value: 'Qiwi Wallet',
    location: 'client',
  },
  'paymentmethods.quadpay.svg.title': {
    value: 'Quadpay',
    location: 'client',
  },
  'paymentmethods.rupay.svg.title': {
    value: 'RuPay',
    location: 'client',
  },
  'paymentmethods.sepa.svg.title': {
    value: 'Sepa',
    location: 'client',
  },
  'paymentmethods.sezzle.svg.title': {
    value: 'Sezzle',
    location: 'client',
  },
  'paymentmethods.sofort.svg.title': {
    value: 'Sofort',
    location: 'client',
  },
  'paymentmethods.solo.svg.title': {
    value: 'Solo',
    location: 'client',
  },
  'paymentmethods.splitit.svg.title': {
    value: 'Split It',
    location: 'client',
  },
  'paymentmethods.spotii.svg.title': {
    value: 'Spotii',
    location: 'client',
  },
  'paymentmethods.tenpay.svg.title': {
    value: 'Tenpay',
    location: 'client',
  },
  'paymentmethods.trustly.svg.title': {
    value: 'Trustly',
    location: 'client',
  },
  'paymentmethods.trust.pay.svg.title': {
    value: 'Trust Pay',
    location: 'client',
  },
  'paymentmethods.unionpay.svg.title': {
    value: 'UnionPay',
    location: 'client',
  },
  'paymentmethods.visa.debit.svg.title': {
    value: 'Visa Debit',
    location: 'client',
  },
  'paymentmethods.visa.credit.svg.title': {
    value: 'Visa Credit',
    location: 'client',
  },
  'paymentmethods.visa.electron.svg.title': {
    value: 'Visa Electron',
    location: 'client',
  },
  'paymentmethods.web.money.svg.title': {
    value: 'Web Money',
    location: 'client',
  },
  'paymentmethods.wechat.pay.svg.title': {
    value: 'WeChat Pay',
    location: 'client',
  },
  'paymentmethods.yandex.svg.title': {
    value: 'Yandex',
    location: 'client',
  },
  'paymentmethods.zippay.svg.title': {
    value: 'ZipPay',
    location: 'client',
  },
  'wishlists.tooltip.modal.welcome.text': {
    value: 'Welcome',
    location: 'client',
  },
  'wishlists.tooltip.modal.text.message': {
    value: '1 item has been added to your Wishlist!',
    location: 'client',
  },
  'wishlists.tooltip.modal.acknowledge.button': {
    value: 'Got it',
    location: 'client',
  },
  'wishlists.tooltip.modal.explore.button': {
    value: 'Explore Wishlist',
    location: 'client',
  },
  'wishlists.newsletter.modal.optin.button': {
    value: 'Yes, notify me',
    location: 'client',
  },
  'wishlists.newsletter.modal.successful.title.text': {
    value: 'Congratulations!',
    location: 'client',
  },
  'wishlists.newsletter.modal.successful.text': {
    value:
      'You will now receive all of the latest offers and updates for products in your wishlist.',
    location: 'client',
  },
  'wishlists.newsletter.modal.title.text': {
    value: 'Wishlist Notifications',
    location: 'client',
  },
  'wishlists.newsletter.modal.text.message': {
    value:
      'Would you like to receive email notifications for price or stock changes to the items in your wishlist?',
    location: 'client',
  },
  'apple.chat.business.id': {
    value: 'bc18840f-d336-4761-b574-2e88c8a07a00',
    location: 'client',
  },
  'whatsapp.phone.number': {
    value: '07481285632',
    location: 'client',
  },
  'carousel.controls.right.arialabel': {
    value: 'Scroll carousel right',
    location: 'client',
  },
  'carousel.controls.left.arialabel': {
    value: 'Scroll carousel left',
    location: 'client',
  },
  'carousel.controls.playpause.arialabel': {
    value: 'Play/Pause carousel',
    location: 'client',
  },
  'general.breadcrumbs.arialabel': {
    value: 'Site Breadcrumbs',
    location: 'client',
  },
  'header.arialabel': {
    value: 'Main site',
    location: 'client',
  },
  'contentheader.arialabel': {
    value: 'Blog',
    location: 'client',
  },
  'general.gallery.viewimage.text': {
    value: 'View image %s',
    location: 'client',
  },
  'general.category.noposts': {
    value: 'There are no posts in this category',
    location: 'client',
  },
  'general.pagination.next': {
    value: 'Next',
    location: 'client',
  },
  'general.pagination.previous.shorthand': {
    value: 'Prev',
    location: 'client',
  },
  'general.pagination.previous': {
    value: 'Previous',
    location: 'client',
  },
  'general.pagination.oftotal': {
    value: 'of %s',
    location: 'client',
  },
  'general.pagination.totalresults.text': {
    value: '%s Results',
    location: 'client',
  },
  'general.pagination.page': {
    value: 'page',
    location: 'client',
  },
  'general.pagination.dropdownlabel': {
    value: 'Select page',
    location: 'client',
  },
  'general.pagination.currentlyontext': {
    value: 'currently on page %s of %s',
    location: 'client',
  },
  'general.pagination.arialabel': {
    value: 'Page',
    location: 'client',
  },
  'general.viewbasket.label': {
    value: 'View basket',
    location: 'client',
  },
  'general.continueshopping.label': {
    value: 'Continue shopping',
    location: 'client',
  },
  'general.signup.text': {
    value: 'Sign up',
    location: 'client',
  },
  'general.tab.button.text': {
    value: 'tab-button',
    location: 'client',
  },
  'general.tab.panel.text': {
    value: 'tab-panel',
    location: 'client',
  },
  'basket.productadded.modaltitle': {
    value: 'Added to your basket',
    location: 'client',
  },
  'basket.productadded.recommendedtitle': {
    value: 'Customers who bought this also bought',
    location: 'client',
  },
  'basket.productadded.baskettotal.singular': {
    value: '%d item in your basket',
    location: 'client',
  },
  'basket.productadded.baskettotal.plural': {
    value: '%d items in your basket',
    location: 'client',
  },
  'basket.subtotal.label': {
    value: 'Subtotal',
    location: 'client',
  },
  'basket.empty.subtitle.text': {
    value: 'There are currently no items in your basket',
    location: 'client',
  },
  'basket.empty.title.text': {
    value: 'Your basket looks empty',
    location: 'client',
  },
  'general.product.quickbuy.text': {
    value: 'Quick buy',
    location: 'client',
  },
  'basket.quickbuy.title': {
    value: 'Quick buy',
    location: 'client',
  },
  'basket.addproduct.text': {
    value: 'Add to basket',
    location: 'client',
  },
  'basket.clickandcollect.modal.trigger': {
    value: 'When will my items be available to pick up?',
    location: 'client',
  },
  'basket.clickandcollect.information.title': {
    value: 'Click and Collect',
    location: 'client',
  },
  'basket.clickandcollect.information.content1': {
    value: 'Your order will be ready to collect within',
    location: 'client',
  },
  'basket.clickandcollect.information.content2': {
    value: '1 hour',
    location: 'client',
  },
  'basket.clickandcollect.information.content3': {
    value:
      "during our store opening times. We'll keep your order safe with us for",
    location: 'client',
  },
  'basket.clickandcollect.information.content4': {
    value: '3 days.',
    location: 'client',
  },
  'basket.removeitem.label': {
    value: 'Remove item',
    location: 'client',
  },
  'basket.discount.removeoffer.text': {
    value: 'Remove',
    location: 'client',
  },
  'basket.discount.removeoffer.label': {
    value: 'Remove offer',
    location: 'client',
  },
  'basket.productinfolink.text': {
    value: 'View more information',
    location: 'client',
  },
  'basket.discount.code.text': {
    value: 'Discount code',
    location: 'client',
  },
  'basket.discount.label.text': {
    value: 'Enter discount',
    location: 'client',
  },
  'basket.discount.apply.text': {
    value: 'Apply',
    location: 'client',
  },

  'basket.checkoutstart.text': {
    value: 'Checkout securely',
    location: 'client',
  },
  'basket.checkoutstart.error.basketsmerged.text': {
    value: 'Items have been added to those already stored in your basket.',
    location: 'client',
  },
  'basket.checkoutstart.error.noteligibleforreferral': {
    value: 'Not eligible for referral',
    location: 'client',
  },
  'basket.checkoutstart.error.nosuchorder': {
    value: 'No such order',
    location: 'client',
  },
  'basket.checkoutstart.error.nosuchsubscription': {
    value: 'No such subscription',
    location: 'client',
  },
  'basket.checkoutstart.error.nopaymentproblem': {
    value: 'No Payment problem',
    location: 'client',
  },
  'basket.recommendations.title.text': {
    value: 'Other Customers Bought',
    location: 'client',
  },
  'basket.referrals.code.applied': {
    value:
      'Thank you for joining, you can now place an order with the discount from your friend',
    location: 'client',
  },
  'basket.referrals.code.invalid': {
    value: 'Referral code is invalid',
    location: 'client',
  },
  'basket.referrals.code.referrernoteligible': {
    value: "As an existing customer, you can't use a referral discount code.",
    location: 'client',
  },
  'basket.referrals.unauthenticated': {
    value: 'Please register an account to use a referral code',
    location: 'client',
  },
  'basket.mixedfulfilment.message': {
    value:
      'Sorry, we are unable to accept mixed orders containing items for Click & Collect and Home Delivery at the same time. Please choose a single preferred method to receive your items',
    location: 'client',
  },
  'basket.itemshaveerrors.message': {
    value:
      'You have 1 or more errors in your basket. Please amend these before continuing',
    location: 'client',
  },
  'basket.item.homedelivery.fulfilment.error': {
    value: 'This item is out of stock for home delivery',
    location: 'client',
  },
  'basket.item.homedelivery.product.error': {
    value: 'This item is not available for home delivery',
    location: 'client',
  },
  'basket.item.storecollection.fulfilment.error': {
    value:
      'This item is currently not available for Click and Collect at this store',
    location: 'client',
  },
  'basket.item.storecollection.product.error': {
    value: 'This item is currently not available for Click and Collect',
    location: 'client',
  },
  'basket.item.storecollection.insufficient.stock': {
    value: 'There is currently not enough stock to fulfil this order',
    location: 'client',
  },
  'basket.item.delivertostore.fulfilment.error': {
    value: 'This item is currently not available for delivery to this store',
    location: 'client',
  },
  'basket.item.delivertostore.product.error': {
    value: 'This item is currently not available for delivery to store',
    location: 'client',
  },
  'basket.item.fulfilment.change.store': {
    value: 'Change store',
    location: 'client',
  },
  'basket.item.fulfilment.collectinstore.notinstock': {
    value: 'Out of Stock',
    location: 'client',
  },
  'basket.item.fulfilment.change.store.aria.label': {
    value: 'Change delivery method to click and collect',
    location: 'client',
  },
  'basket.item.fulfilment.change.to.home.delivery': {
    value: 'Change to home delivery',
    location: 'client',
  },
  'basket.item.fulfilment.change.to.home.delivery.aria.label': {
    value: 'Change delivery method to home delivery',
    location: 'client',
  },
  'basket.item.fulfilment.nameddaydelivery': {
    value: 'Named day delivery available',
    location: 'client',
  },
  'basket.item.fulfilment.nameddaydelivery.options': {
    value: 'Choose a delivery date',
    location: 'client',
  },
  'basket.item.fulfilment.nextdaydelivery': {
    value: 'Next day delivery available',
    location: 'client',
  },
  'basket.item.fulfilment.nextdaydelivery.options': {
    value: 'Order before 4pm Sun-Fri',
    location: 'client',
  },
  'basket.item.fulfilment.collectinstore': {
    value: 'Collection from store ',
    location: 'client',
  },
  'basket.item.fulfilment.collectinstore.stock': {
    value: ' - %d in stock',
    location: 'client',
  },
  'basket.item.fulfilment.changetocollectinstore': {
    value: 'Change to Click and Collect',
    location: 'client',
  },
  'basket.item.fulfilment.delivertostore': {
    value: 'Deliver to store',
    location: 'client',
  },
  'basket.item.fulfilment.leadtime': {
    value: 'Delivery within %d days',
    location: 'client',
  },
  'basket.discount.summary.title': {
    value: 'Total savings',
    location: 'client',
  },
  'basket.loyalty.text': {
    value: 'Rewards members earn %s points on this order',
    location: 'client',
  },
  'basket.checkoutstart.error.generic.text': {
    value: 'An unknown error occurred. Please try again',
    location: 'client',
  },
  'basket.freegift.text': {
    value: 'Free Gift',
    location: 'client',
  },
  'basket.selectyoursample.gifts.selected.text': {
    value: '%d/%d free gifts',
    location: 'client',
  },
  'basket.selectyoursample.productinformation.aria.label': {
    value: 'product information',
    location: 'client',
  },
  'basket.selectyoursample.unqualified.amount.left.text': {
    value: 'to go',
    location: 'client',
  },
  'basket.selectyoursample.unqualified.amount.total.text.part1': {
    value: 'Spend',
    location: 'client',
  },
  'basket.selectyoursample.unqualified.amount.total.text.part2': {
    value: '%s or more',
    location: 'client',
  },
  'basket.selectyoursample.unqualified.amount.total.text.part3': {
    value: 'to qualify',
    location: 'client',
  },
  'basket.selectyoursample.tier.threshold.text': {
    value: '(over %s spent)',
    location: 'client',
  },
  'basket.selectyoursample.confirmation.modal.heading.text': {
    value: "Don't forget your free gifts",
    location: 'client',
  },
  'basket.selectyoursample.tier.oos.text': {
    value:
      'We are very sorry, due to high demand, the advertised free gifts in this tier are no longer available.',
    location: 'client',
  },
  'basket.selectyoursample.oos.text': {
    value:
      'Sorry, due to high demand, some of the advertised free gifts are no longer available.',
    location: 'client',
  },
  'basket.selectyoursample.confirmation.modal.subtitle.text': {
    value: '%d/%d free gifts selected',
    location: 'client',
  },
  'basket.selectyoursample.confirmation.modal.confirm.text': {
    value: 'Continue to checkout',
    location: 'client',
  },
  'basket.selectyoursample.confirmation.modal.cancel.text': {
    value: 'Select your free gifts',
    location: 'client',
  },
  'basket.item.max.quantity.reached': {
    value: 'This product is limited to %s per order',
    location: 'client',
  },
  'basket.rrp.discount.message': {
    value: 'You saved %s off the RRP!',
    location: 'client',
  },
  'account.order.status.cancelled.text': {
    value: 'Your order has been cancelled',
    location: 'client',
  },
  'account.order.status.paymentproblem.text': {
    value: 'There was a problem with the payment on this order',
    location: 'client',
  },
  'account.order.payment.csresolve.text': {
    value:
      'There is a problem with your payment, please visit the Help Centre to resolve.',
    location: 'client',
  },
  'account.order.status.placed.text': {
    value: 'Your order has been placed',
    location: 'client',
  },
  'account.order.status.dispatched.text': {
    value: 'Your order has been dispatched',
    location: 'client',
  },
  'account.order.status.collected.text': {
    value: 'Your order has been collected',
    location: 'client',
  },
  'account.order.status.readytocollect.text': {
    value: 'Your order is ready to be collected',
    location: 'client',
  },
  'account.order.status.processing.text': {
    value: 'We have your order',
    location: 'client',
  },
  'account.navigation.messagesapp.text': {
    value: 'Open messages app',
    location: 'client',
  },
  'account.livechat.unavailable': {
    value: 'Live chat is unavailable at the moment. Please try again later.',
    location: 'client',
  },
  'account.adachat.unavailable': {
    value: 'Ada Chat is unavailable at the moment. Please try again later.',
    location: 'client',
  },
  'product.freeprice.text': {
    value: 'FREE',
    location: 'client',
  },
  'basket.item.product.outofstock.error.text': {
    value: 'This item is out of stock, please remove before continuing',
    location: 'client',
  },
  'basket.addtobasket.error.text': {
    value: 'We are unable to add this item to your basket. Please try again.',
    location: 'client',
  },
  'basket.addtobasketmultiple.error.text': {
    value: 'We are unable to add these items to your basket. Please try again.',
    location: 'client',
  },
  'account.orderdetails.title': {
    value: 'Order Details',
    location: 'client',
  },
  'account.orderdetails.invoice.error.text': {
    value: 'Invoice not available',
    location: 'client',
  },
  'account.accountmessages.title': {
    value: 'Account Messages',
    location: 'client',
  },
  'account.order.status.purchasedinstore.store': {
    value: 'Store: %s',
    location: 'client',
  },
  'account.wishlist.items': {
    value: '%d items',
    location: 'client',
  },
  'account.wishlist.item': {
    value: '1 item',
    location: 'client',
  },
  'account.wishlist.removeItem': {
    value: 'Remove %s from wishlist',
    location: 'client',
  },
  'account.deletepaymentmodal.heading': {
    value: 'Delete card',
    location: 'client',
  },
  'account.deletepaymentmodal.subtitle': {
    value: 'Are you sure you want to delete the card ending in %d?',
    location: 'client',
  },
  'account.deletepaymentmodal.success.message': {
    value: 'The card ending in %d has been deleted',
    location: 'client',
  },
  'account.deletepaymentmodal.error.message': {
    value: 'There was an error while deleting the payment card',
    location: 'client',
  },
  'account.payment.debitcredit.type.text': {
    value: 'Debit/Credit',
    location: 'client',
  },
  'account.payment.giftcard.type.text': {
    value: 'Gift Card',
    location: 'client',
  },
  'account.expandablelist.showing.text': {
    value: 'Showing %d of %d items',
    location: 'client',
  },
  'account.expandablelist.showing.orders.text': {
    value: 'Showing %d of %d orders',
    location: 'client',
  },
  'account.expandablelist.showing.subscriptions.text': {
    value: 'Showing %d of %d subscriptions',
    location: 'client',
  },
  'account.orderdetails.notfound.title': {
    value: "Your order can't be found",
    location: 'client',
  },
  'account.contractdetails.notfound.title': {
    value: "Subscription contracts can't be found",
    location: 'client',
  },
  'account.orderdetails.notfound.text': {
    value:
      "It’s either been removed from this location, or the URL is wrong. We've recorded this error to help us improve the site.",
    location: 'client',
  },
  'orderproduct.quantity.text': {
    value: 'Quantity',
    location: 'client',
  },
  'orderproduct.cancel.reason.placeholder': {
    value: 'Select reason',
    location: 'client',
  },
  'orderproduct.cancel.quantity': {
    value: 'Quantity to cancel from order',
    location: 'client',
  },
  'orderproduct.cancel.reason': {
    value: 'Reason for cancellation',
    location: 'client',
  },
  'orderproduct.price.text': {
    value: 'Price',
    location: 'client',
  },
  'form.field.loyaltyoptin.heading': {
    value: 'Loyalty Reward program',
    location: 'client',
  },
  'account.register.loyalty.terms.text': {
    value: 'See our terms of use',
    location: 'client',
  },
  'account.register.loyalty.terms.url': {
    value: '/terms-of-use.list',
    location: 'client',
  },
  'general.memberperks.link': {
    value: '/members-perks.list',
    location: 'client',
  },
  'product.brand.logo.url': {
    value: '%s/brands/%s',
    location: 'client',
  },
  'general.externalidentifierdisplay.text': {
    value: 'SKU: ',
    location: 'client',
  },
  'titles.page.account-subscriptions.text': {
    value: 'Subscriptions',
    location: 'client',
  },
  'account.subscriptions.heading': {
    value: 'My Subscriptions',
    location: 'client',
  },
  'account.subscriptions.subheading.active': {
    value: 'Active subscriptions',
    location: 'client',
  },
  'account.subscriptions.subheading.completed': {
    value: 'Past subscription(s)',
    location: 'client',
  },
  'account.navigation.loyaltycard.text': {
    value: 'My Rewards',
    location: 'client',
  },
  'account.loyalty.link': {
    value: '/account/redeem-points.list',
    location: 'client',
  },
  'account.loyalty.rewards.title': {
    value: 'About My Reward Card',
    location: 'client',
  },
  'account.loyalty.instructions.text': {
    value:
      'Simply present the barcode at a till point to get instore discounts on selected products.',
    location: 'client',
  },
  'account.loyalty.rewards.faq1.question': {
    value: 'What is Matalan Me?',
    location: 'client',
  },
  'account.loyalty.rewards.faq1.answer': {
    value:
      'When you become a Matalan Me member, you gain access to special rewards and exclusive prices.<br /><br />Exclusive Discounts<br />Plus extra rewards when you shop, in store coupons and more.<br /><br />Shop the sale first<br />Matalan Me members hear about the sale early, so you can be first in line.<br /><br />A Birthday Treat<br />Share your special day with us, and look out for a little treat on your birthday<br /><br />Win your basket<br />Shop online or use your Matalan Me card or app in store for the chance to win your basket – there’s a winner every day!',
    location: 'client',
  },
  'account.loyalty.rewards.faq2.question': {
    value: 'How do I use Matalan Me?',
    location: 'client',
  },
  'account.loyalty.rewards.faq2.answer': {
    value:
      'Use your Matalan Me card every time you shop to earn your rewards.<br /><br />In store, you can use your physical Matalan Me card at the till.<br /><br />If you’ve downloaded the Matalan app, you can scan your digital card at the till.<br /><br />If you’re shopping online, log in to your online account and your Matalan Me card will be applied automatically.',
    location: 'client',
  },
  'account.loyalty.rewards.faq3.question': {
    value:
      'Do I need to use my Matalan Me card to receive discounts and promotions in store?',
    location: 'client',
  },
  'account.loyalty.rewards.faq3.answer': {
    value:
      'Yes. If you want to claim a Matalan Me offer or discount, you must use your Matalan Me card in store, or log in online to do so',
    location: 'client',
  },
  'account.loyalty.rewards.faq4.question': {
    value: 'How do I get my birthday treat?',
    location: 'client',
  },
  'account.loyalty.rewards.faq4.answer': {
    value:
      'Share your birthday with us in account details and we’ll send your birthday treat automatically. Look out for an email a few days before your birthday each year.',
    location: 'client',
  },
  'account.loyalty.banner.anchor.title': {
    value: 'Loyalty App',
    location: 'client',
  },
  'account.loyalty.banner.anchor.href': {
    value: '/loyalty-app',
    location: 'client',
  },
  'account.navigation.loyalty.text': {
    value: 'Your Rewards',
    location: 'client',
  },
  'account.loyalty.redeemoffer.modal.expirydate.text': {
    value: 'Expires: %s',
    location: 'client',
  },
  'account.loyalty.replacerewardcard.error': {
    value:
      'There was an error while replacing your card, please try again later.',
    location: 'client',
  },
  'account.subscriptions.active.none': {
    value: 'You have no active subscriptions',
    location: 'client',
  },
  'account.subscriptions.completed.none': {
    value: 'You have no completed subscriptions',
    location: 'client',
  },
  'account.subscriptions.cancel.modal.heading': {
    value: 'Before you go did you know...',
    location: 'client',
  },
  'account.subscriptions.cancel.modal.alternativeheading': {
    value: 'Are you sure you want to cancel?',
    location: 'client',
  },
  'account.subscriptions.cancel.modal.subheading': {
    value: "If you're receiving too many products you can:",
    location: 'client',
  },
  'account.subscriptions.cancel.modal.skiporder.text': {
    value: 'Skip your next order',
    location: 'client',
  },
  'account.subscriptions.cancel.modal.changeqty.text': {
    value: "Change the quantity of the products you're receiving",
    location: 'client',
  },
  'account.subscriptions.cancel.modal.changeproducts.text': {
    value: "Change the products you're receiving",
    location: 'client',
  },
  'account.subscriptions.cancel.modal.cancelbutton': {
    value: 'Cancel Plan',
    location: 'client',
  },
  'account.subscriptions.cancel.modal.nocancelbutton': {
    value: "Don't Cancel",
    location: 'client',
  },
  'account.subscriptions.detail.heading': {
    value: 'Subscription Details',
    location: 'client',
  },
  'account.subscriptions.canceldelay.heading': {
    value: 'Cancel subscription delay',
    location: 'client',
  },
  'account.subscriptions.cancel.survey.header': {
    value: 'Cancel Subscription Survey',
    location: 'client',
  },
  'account.subscriptions.cancel.survey.text': {
    value:
      "We're sad to see you go! Before you leave please tell us why you're cancelling your subscription so we can improve customer experience.",
    location: 'client',
  },
  'account.subscriptions.canceldelay.message.text': {
    value:
      'You can cancel this delay before %s. Do you wish to cancel this delay?',
    location: 'client',
  },
  'account.subscriptions.cancel.survey.heading': {
    value: 'Why do you want to cancel?',
    location: 'client',
  },
  'account.subscriptions.canceldelay.cancelbutton.title': {
    value: "No, don't cancel",
    location: 'client',
  },
  'account.subscriptions.canceldelay.confirmbutton.title': {
    value: 'Yes, cancel',
    location: 'client',
  },
  'account.subscription.editbillingdate.button.text': {
    value: 'Edit',
    location: 'client',
  },
  'account.subscription.addon.button.text': {
    value: 'Add Product(s)',
    location: 'client',
  },
  'account.subscription.editorderdate.title': {
    value: 'Edit next order date',
    location: 'client',
  },
  'account.subscription.editorderdate.text': {
    value:
      'Edit your next billing date to make your subscription work for you. Learn more about this in the',
    location: 'client',
  },
  'account.subscription.meta.subscriptionnumber.label': {
    value: 'Subscription number',
    location: 'client',
  },
  'account.subscription.edit.title': {
    value: 'Edit subscription details',
    location: 'client',
  },
  'account.subscription.edit.subscription.success': {
    value: 'Your Subscription product(s) have been updated successfully. %s',
    location: 'client',
  },
  'account.subscription.meta.paymentdate.label': {
    value: 'Payment Date',
    location: 'client',
  },
  'account.subscription.meta.next.paymentdate.label': {
    value: 'Next payment date',
    location: 'client',
  },
  'account.subscription.meta.dispatchdate.label': {
    value: 'Dispatch Date',
    location: 'client',
  },
  'account.subscriptiondetail.editbillingdatesuccess.text': {
    value:
      'Your next billing date has been susccessfully updated and will be on the %s',
    location: 'client',
  },
  'account.subscription.editbillingdate.missingbilling': {
    value: 'Please select a new billing date.',
    location: 'client',
  },
  'account.subscription.edit.missingfrequency': {
    value: 'Please select a new frequency',
    location: 'client',
  },
  'account.subscription.editbillingdate.text': {
    value: 'Next Billling Date',
    location: 'client',
  },
  'account.subscription.meta.duration.label': {
    value: 'Duration',
    location: 'client',
  },
  'account.paymentmethod.heading': {
    value: 'Payment method',
    location: 'client',
  },
  'account.paymentmethod.cashattillpoint': {
    value: 'Cash at till point',
    location: 'client',
  },
  'account.paymentmethod.cardattillpoint': {
    value: 'Card at till point',
    location: 'client',
  },
  'account.subscription.meta.orderdate.label': {
    value: 'Order date',
    location: 'client',
  },
  'account.subscriptions.status.new': { value: 'New', location: 'client' },
  'account.subscriptions.status.active': {
    value: 'Active',
    location: 'client',
  },
  'account.subscriptions.status.failedpayment': {
    value: 'Failed Payment',
    location: 'client',
  },
  'account.subscriptions.status.complete': {
    value: 'Complete',
    location: 'client',
  },
  'account.subscriptions.status.cancelled': {
    value: 'Cancelled',
    location: 'client',
  },
  'account.subscriptions.status.noticeperiod': {
    value: 'Notice period',
    location: 'client',
  },
  'account.subscriptions.status.preprocessed': {
    value: 'Awaiting confirmation',
    location: 'client',
  },
  'account.subscriptions.notfound.heading': {
    value: 'Subscription cannot be found',
    location: 'client',
  },
  'account.subscriptions.notfound.text': {
    value:
      "It's either been removed from this location, or the URL is wrong. We’ve recorded this error to help us improve the site.",
    location: 'client',
  },
  'account.subscription.meta.frequency.label': {
    value: 'Frequency',
    location: 'client',
  },
  'account.subscription.meta.title.freegift': {
    value: '- Free Gift',
    location: 'client',
  },
  'account.subscription.edit.frequency.label': {
    value: 'Group Frequency',
    location: 'client',
  },
  'account.subscription.meta.price.label': {
    value: 'Price',
    location: 'client',
  },
  'account.subscriptions.nextorder.heading': {
    value: 'Next Order',
    location: 'client',
  },
  'account.subscriptions.delay.button.text': {
    value: 'Delay your next order',
    location: 'client',
  },
  'account.subscriptions.delay.button.confirm': {
    value: 'Yes, delay',
    location: 'client',
  },
  'account.subscriptions.delay.button.cancel': {
    value: "No, don't delay",
    location: 'client',
  },
  'account.subscriptions.delay.text': {
    value: 'Delay your next delivery by 1 month. Read more about this in the',
    location: 'client',
  },
  'account.subscription.editorderdate.helptext': {
    value:
      'Don’t need this order, need it sooner or maybe you want to change the payment date? Don’t worry, we’ve got you covered. Read more about this in the',
    location: 'client',
  },
  'account.subscriptions.delay.title': {
    value: 'Delay subscription',
    location: 'client',
  },
  'account.subscriptions.delay.heading': {
    value: 'Subscription delay',
    location: 'client',
  },
  'account.subscriptions.delay.delaytext': {
    value:
      'Do you wish to delay your next order by one month? Your subscription after this delay will resume back to your selected frequency.',
    location: 'client',
  },
  'account.subscriptions.delay.period': {
    value: 'Your subscription has been delayed by 1 month.',
    location: 'client',
  },
  'account.subscriptions.delay.period.plural': {
    value: 'Your subscription has been delayed by %s months.',
    location: 'client',
  },
  'account.subscriptions.delay.cancel': {
    value: 'Cancel delay',
    location: 'client',
  },
  'account.subscriptions.edit.button.text': {
    value: 'Edit Frequency',
    location: 'client',
  },
  'account.subscriptions.management.heading': {
    value: 'Subscription Management',
    location: 'client',
  },
  'account.subscriptions.changefequency.button.text': {
    value: 'Change frequency',
    location: 'client',
  },
  'account.subscriptions.cancel.button.text': {
    value: 'Cancel subscription',
    location: 'client',
  },
  'account.subscriptions.cancel.text': {
    value:
      'If you no longer need the subscription you can cancel. Read more about this in the ',
    location: 'client',
  },
  'account.subscriptions.cancel.survey.subheading': {
    value: 'Question 1 of 1',
    location: 'client',
  },
  'account.subscriptions.cancel.survey.cancel.text': {
    value: "I don't want to cancel",
    location: 'client',
  },
  'account.subscriptions.cancel.survey.continue.text': {
    value: 'Continue',
    location: 'client',
  },
  'account.subscriptions.cancel.survey.noreasonselected.error': {
    value: 'You must select a reason before cancelling',
    location: 'client',
  },
  'account.subscriptions.summary.heading': {
    value: 'Subscription Summary',
    location: 'client',
  },
  'account.orders.summary.heading': {
    value: 'Order summary',
    location: 'client',
  },
  'account.subscriptions.summary.error.text': {
    value:
      'There was an error loading subscription summary, please try again later.',
    location: 'client',
  },
  'account.credit.summary.error.text': {
    value:
      'There was an error loading Account credit summary, please try again later.',
    location: 'client',
  },
  'account.messages.create.error.loading.text': {
    value: 'There was an error loading order summary, please try again later.',
    location: 'client',
  },
  'account.orders.messages.create.withordernumber.text': {
    value:
      'If you are contacting us regarding a particular item or items in your order, please select from the list below. This will allow us to direct your query to the correct team. ',
    location: 'client',
  },
  'account.orders.totalbeforesavings.text': {
    value: 'Total before savings',
    location: 'client',
  },
  'account.orders.delivery.text': {
    value: 'Delivery',
    location: 'client',
  },
  'account.orders.savings.text': {
    value: 'Savings',
    location: 'client',
  },
  'account.orders.giftcard.text': {
    value: 'Gift card:',
    location: 'client',
  },
  'account.track.return.orders.text': {
    value: 'You have a return in progress,',
    location: 'client',
  },
  'account.track.return.orders.link.text': {
    value: 'track your return here',
    location: 'client',
  },
  'account.subscription.meta.totalcost.label': {
    value: 'Total cost',
    location: 'client',
  },
  'general.edit.text': { value: 'Edit', location: 'client' },
  'general.edit.something.text': { value: 'Edit %s', location: 'client' },
  'account.meta.cardholdername.label': {
    value: "Cardholder's name",
    location: 'client',
  },
  'account.meta.expirydate.label': {
    value: 'Expiry date',
    location: 'client',
  },
  'general.needhelp.text': { value: 'Need help?', location: 'client' },
  'general.qualified.text': {
    value: 'Qualified',
    location: 'client',
  },
  'account.subscription.frequency.weekly': {
    value: 'Weekly',
    location: 'client',
  },
  'account.subscription.frequency.everytwoweeks': {
    value: 'Every two weeks',
    location: 'client',
  },
  'account.subscription.frequency.everythreeweeks': {
    value: 'Every three weeks',
    location: 'client',
  },
  'account.subscription.frequency.monthly': {
    value: 'Monthly',
    location: 'client',
  },
  'account.subscription.frequency.everytwomonths': {
    value: 'Every two months',
    location: 'client',
  },
  'account.subscription.frequency.quarterly': {
    value: 'Quarterly',
    location: 'client',
  },
  'account.subscription.frequency.everythreemonths': {
    value: 'Every three months',
    location: 'client',
  },
  'account.subscription.frequency.everyfourmonths': {
    value: 'Every four months',
    location: 'client',
  },
  'account.subscription.frequency.everysixmonths': {
    value: 'Every six months',
    location: 'client',
  },
  'account.subscription.frequency.every': {
    value: 'Every %d ',
    location: 'client',
  },
  'account.subscription.frequency.prefix.every': {
    value: 'Every %s ',
    location: 'client',
  },
  'account.subscription.frequency.everyweek': {
    value: 'week',
    location: 'client',
  },
  'account.subscription.frequency.everyweeks': {
    value: 'weeks',
    location: 'client',
  },
  'account.subscription.frequency.everymonth': {
    value: 'month',
    location: 'client',
  },
  'account.subscription.frequency.everymonths': {
    value: 'months',
    location: 'client',
  },
  'account.subscription.frequency.everyyear': {
    value: 'year',
    location: 'client',
  },
  'account.subscription.frequency.everyyears': {
    value: 'years',
    location: 'client',
  },
  'account.subscription.frequency.undefined': {
    value: 'undefined',
    location: 'client',
  },
  'account.subscription.support': {
    value: 'Subscription Support',
    location: 'client',
  },
  'account.subscription.support.message': {
    value:
      'Should you wish to ask us any questions before you do cancel your subscription, please contact our Customer Service Team:',
    location: 'client',
  },
  'account.subscription.support.cta': {
    value: 'Contact Customer Service',
    location: 'client',
  },
  'account.subscription.frequency.annually': {
    value: 'Annually',
    location: 'client',
  },
  'account.subscriptions.edit.save': {
    value: 'Save details',
    location: 'client',
  },
  'account.subscriptionedit.modal.title': {
    value: 'Unsaved changes',
    location: 'client',
  },
  'account.subscriptionedit.modal.body': {
    value:
      'This page contains unsaved changes, do you wish to save before leaving?',
    location: 'client',
  },
  'account.subscriptionedit.modal.save': {
    value: 'Yes, Save changes',
    location: 'client',
  },
  'account.subscriptionedit.title': {
    value: 'Edit subscription frequency',
    location: 'client',
  },
  'account.subscription.edit.page.title': {
    value: 'Edit Products',
    location: 'client',
  },
  'account.subscription.remove.modal.title': {
    value: 'Remove Product?',
    location: 'client',
  },
  'account.subscription.remove.modal.subtitle': {
    value:
      'Are you sure you want to remove this product from your subscription?',
    location: 'client',
  },
  'account.subscription.remove.product.success': {
    value: '"%s" has been successfully removed from your subscription',
    location: 'client',
  },
  'account.subscription.addon.page.title': {
    value: 'Add Products',
    location: 'client',
  },
  'account.subscription.addon.modal.heading': {
    value: 'Add on Summary',
    location: 'client',
  },
  'account.subscription.addon.modal.subtitle': {
    value: 'You are about to add product below to your existing subscription',
    location: 'client',
  },
  'account.subscription.addon.page.subtitle': {
    value:
      'Below is a list of products you can add on to your exisiting subscription. The frequency of added products will match your current subscriptions frequency.',
    location: 'client',
  },
  'account.subscription.addon.page.add.button.text': {
    value: 'Add to subscription',
    location: 'client',
  },
  'account.subscription.addon.modal.save': {
    value: 'Confirm add on',
    location: 'client',
  },
  'account.subscription.addon.modal.cancel': {
    value: 'Go back',
    location: 'client',
  },
  'account.subscriptionedit.modal.cancel': {
    value: 'No, Return to details page',
    location: 'client',
  },
  'account.subscriptionedit.editfrequency.error': {
    value:
      'There was a problem updating your frequency please contact customer service.',
    location: 'client',
  },
  'account.subscriptionedit.removeproduct.error': {
    value:
      'There was a problem removing your item please contact customer service.',
    location: 'client',
  },
  'account.subscriptionedit.removeproduct.notpossible': {
    value: "You can't remove the only one product in the subscription.",
    location: 'client',
  },
  'account.subscriptionedit.editproduct.error': {
    value:
      'There was a problem updating the products on your subscription please contact customer service.',
    location: 'client',
  },
  'account.subscriptionedit.outofstock.error': {
    value:
      'Can not swap to out-of-stock products. Please change product selection.',
    location: 'client',
  },
  'account.subscriptionedit.nochangesmade.error': {
    value: 'No changes detected please make a change before saving',
    location: 'client',
  },
  'account.subscription.addon.error': {
    value:
      'There was a problem adding this product to your subscription, please try again later.',
    location: 'client',
  },
  'account.subscription.meta.paymenttype.label': {
    value: 'Payment type',
    location: 'client',
  },
  'account.subscription.paymenttype.upfront': {
    value: 'Upfront',
    location: 'client',
  },
  'account.subscription.paymenttype.ondispatch': {
    value: 'On dispatch',
    location: 'client',
  },
  'account.subscription.paymenttype.onorder': {
    value: 'On order',
    location: 'client',
  },
  'account.subscriptions.delaydisabled.text': {
    value:
      'You have used all of your delays for this order. Your delay count will be reset following the dispatch of your next order. Read more about this in the',
    location: 'client',
  },
  'account.subscriptions.frequency.heading': {
    value: 'Subscription frequency',
    location: 'client',
  },
  'account.subscription.meta.currentfrequency.label': {
    value: 'Current frequency',
    location: 'client',
  },
  'account.subscription.meta.status.label': {
    value: 'Status',
    location: 'client',
  },
  'general.cancel.text': { value: 'Cancel', location: 'client' },
  'general.next.text': { value: 'Next', location: 'client' },
  'account.subscription.meta.nextshipmentdate.label': {
    value: 'Next shipment date',
    location: 'client',
  },
  'account.subscriptions.shipmentdate.heading': {
    value: 'Shipment date',
    location: 'client',
  },
  'account.subscriptions.shipmentdate.text': {
    value:
      'Your next shipment is due on the %s. Alternatively, you can choose another date below:',
    location: 'client',
  },
  'general.confirmchanges.text': {
    value: 'Confirm changes',
    location: 'client',
  },
  'general.back.text': { value: 'Back', location: 'client' },
  'account.subscriptions.frequency.text': {
    value: 'Please select your preferred subscription frequency.',
    location: 'client',
  },
  'account.deliverydetails.heading': {
    value: 'Delivery details',
    location: 'client',
  },
  'state.us_ak.text': {
    value: 'Alaska',
    location: 'client',
  },
  'state.us_al.text': {
    value: 'Alabama',
    location: 'client',
  },
  'state.us_ar.text': {
    value: 'Arkansas',
    location: 'client',
  },
  'state.us_az.text': {
    value: 'Arizona',
    location: 'client',
  },
  'state.us_ca.text': {
    value: 'California',
    location: 'client',
  },
  'state.us_co.text': {
    value: 'Colorado',
    location: 'client',
  },
  'state.us_ct.text': {
    value: 'Connecticut',
    location: 'client',
  },
  'state.us_dc.text': {
    value: 'Washington, D.C.',
    location: 'client',
  },
  'state.us_de.text': {
    value: 'Delaware',
    location: 'client',
  },
  'state.us_fl.text': {
    value: 'Florida',
    location: 'client',
  },
  'state.us_ga.text': {
    value: 'Georgia',
    location: 'client',
  },
  'state.us_hi.text': {
    value: 'Hawaii',
    location: 'client',
  },
  'state.us_ia.text': {
    value: 'Iowa',
    location: 'client',
  },
  'state.us_id.text': {
    value: 'Idaho',
    location: 'client',
  },
  'state.us_il.text': {
    value: 'Illinois',
    location: 'client',
  },
  'state.us_in.text': {
    value: 'Indiana',
    location: 'client',
  },
  'state.us_ks.text': {
    value: 'Kansas',
    location: 'client',
  },
  'state.us_ky.text': {
    value: 'Kentucky',
    location: 'client',
  },
  'state.us_la.text': {
    value: 'Louisiana',
    location: 'client',
  },
  'state.us_ma.text': {
    value: 'Massachusetts',
    location: 'client',
  },
  'state.us_md.text': {
    value: 'Maryland',
    location: 'client',
  },
  'state.us_me.text': {
    value: 'Maine',
    location: 'client',
  },
  'state.us_mi.text': {
    value: 'Michigan',
    location: 'client',
  },
  'state.us_mn.text': {
    value: 'Minnesota',
    location: 'client',
  },
  'state.us_mo.text': {
    value: 'Missouri',
    location: 'client',
  },
  'state.us_ms.text': {
    value: 'Mississippi',
    location: 'client',
  },
  'state.us_mt.text': {
    value: 'Montana',
    location: 'client',
  },
  'state.us_nc.text': {
    value: 'North Carolina',
    location: 'client',
  },
  'state.us_nd.text': {
    value: 'North Dakota',
    location: 'client',
  },
  'state.us_ne.text': {
    value: 'Nebraska',
    location: 'client',
  },
  'state.us_nh.text': {
    value: 'New Hampshire',
    location: 'client',
  },
  'state.us_nj.text': {
    value: 'New Jersey',
    location: 'client',
  },
  'state.us_nm.text': {
    value: 'New Mexico',
    location: 'client',
  },
  'state.us_nv.text': {
    value: 'Nevada',
    location: 'client',
  },
  'state.us_ny.text': {
    value: 'New York',
    location: 'client',
  },
  'state.us_oh.text': {
    value: 'Ohio',
    location: 'client',
  },
  'state.us_ok.text': {
    value: 'Oklahoma',
    location: 'client',
  },
  'state.us_or.text': {
    value: 'Oregon',
    location: 'client',
  },
  'state.us_pa.text': {
    value: 'Pennsylvania',
    location: 'client',
  },
  'state.us_ri.text': {
    value: 'Rhode Island',
    location: 'client',
  },
  'state.us_sc.text': {
    value: 'South Carolina',
    location: 'client',
  },
  'state.us_sd.text': {
    value: 'South Dakota',
    location: 'client',
  },
  'state.us_tn.text': {
    value: 'Tennessee',
    location: 'client',
  },
  'state.us_tx.text': {
    value: 'Texas',
    location: 'client',
  },
  'state.us_ut.text': {
    value: 'Utah',
    location: 'client',
  },
  'state.us_va.text': {
    value: 'Virginia',
    location: 'client',
  },
  'state.us_vt.text': {
    value: 'Vermont',
    location: 'client',
  },
  'state.us_wa.text': {
    value: 'Washington',
    location: 'client',
  },
  'state.us_wi.text': {
    value: 'Wisconsin',
    location: 'client',
  },
  'state.us_wv.text': {
    value: 'West Virginia',
    location: 'client',
  },
  'state.us_wy.text': {
    value: 'Wyoming',
    location: 'client',
  },
  'state.us_aa.text': {
    value: 'Armed Forces Americas',
    location: 'client',
  },
  'state.us_ae.text': {
    value: 'Armed Forces Europe',
    location: 'client',
  },
  'state.us_ap.text': {
    value: 'Armed Forces Pacific',
    location: 'client',
  },
  'country.name.us.text': {
    value: 'United States',
    location: 'client',
  },
  'general.rrp.text': {
    value: 'RRP',
    location: 'client',
  },
  'general.save.text': {
    value: 'Save',
    location: 'client',
  },
  'general.off.text': {
    value: 'OFF',
    location: 'client',
  },
  'general.delete.text': {
    value: 'Delete',
    location: 'client',
  },
  'product.review.disclaimer.text': {
    value:
      'Where reviews refer to foods or cosmetic products, results may vary from person to person.  Customer reviews are independent and do not represent the views of The Hut Group.',
    location: 'client',
  },
  'paymentmethods.atome.svg.title': {
    value: 'Atome',
    location: 'client',
  },
  'product.review.sort.label': {
    value: 'Sort by',
    location: 'client',
  },
  'product.review.sort.created': {
    value: 'Created On',
    location: 'client',
  },
  'product.review.sort.rating': {
    value: 'Rating',
    location: 'client',
  },
  'order.review.sort.rating': {
    value: 'Overall Rating',
    location: 'client',
  },
  'order.review.featurerating.quality': {
    value: 'Quality',
    location: 'client',
  },
  'order.review.featurerating.value': {
    value: 'Value',
    location: 'client',
  },
  'order.review.featurerating.fit': {
    value: 'Fit',
    location: 'client',
  },
  'order.review.form.error': {
    value:
      'Review contains mandatory information. Edit to complete before submitting.',
    location: 'client',
  },
  'order.review.delete': {
    value: 'DELETE REVIEW',
    location: 'client',
  },
  'order.review.canceldelete': {
    value: 'CONTINUE EDITING',
    location: 'client',
  },
  'order.review.deletemodal.heading': {
    value: 'Are you sure you want to delete your review?',
    location: 'client',
  },
  'order.review.deletemodal.subheading': {
    value: 'Any deleted review will not be published or stored.',
    location: 'client',
  },
  'product.review.sort.votes': {
    value: 'Votes',
    location: 'client',
  },
  'product.review.featurerating.average.title': {
    value: 'Average Customer Ratings',
    location: 'client',
  },
  'product.productrating.score.filter.pill.text.multiple.stars': {
    value: 'Overall rating: %s stars',
    location: 'client',
  },
  'product.productrating.score.filter.pill.text.single.star': {
    value: 'Overall rating: 1 star',
    location: 'client',
  },
  'deliveryandreturnsinfo.delivery.heading': {
    value: 'Delivery & Returns',
    location: 'client',
  },
  'deliveryandreturnsinfo.delivery.message': {
    value:
      "UK Delivery<br />Delivery £4.99 or FREE when you spend £50. See UK delivery options <a href='/test'>here.</a>",
    location: 'client',
  },
  'deliveryandreturnsinfo.delivery.legalmessage': {
    value:
      "<b>International Delivery</b><br />We deliver to over 200 countries with FREE tracked options available. See international delivery options <a href='/international-delivery.list'>here</a>.",
    location: 'client',
  },
  'deliveryandreturnsinfo.returns.heading': {
    value: "If I'm not completely happy with my item?",
    location: 'client',
  },
  'deliveryandreturnsinfo.returns.message': {
    value: 'UK returns are FREE*. See our full returns policy',
    location: 'client',
  },
  'product.freedelivery.text': {
    value: 'FREE STANDARD DELIVERY WHEN YOU SIGN UP TO OUR NEWSLETTER',
    location: 'client',
  },
  'product.freedelivery.href': {
    value: '/emailSignUp.account',
    location: 'client',
  },
  'general.variations.options.placeholder.text': {
    value: 'Please choose...',
    location: 'client',
  },
  'variation.name.size.text': {
    value: 'Size',
    location: 'client',
  },
  'variation.name.fit.text': {
    value: 'Fit',
    location: 'client',
  },
  'variation.name.colour.text': {
    value: 'Colour',
    location: 'client',
  },
  'variation.name.shade.text': {
    value: 'Shade',
    location: 'client',
  },
  'variation.name.flavour.text': {
    value: 'Flavour',
    location: 'client',
  },
  'variation.name.package.text': {
    value: 'Package',
    location: 'client',
  },
  'variation.name.amount.text': {
    value: 'Amount',
    location: 'client',
  },
  'variation.name.strength.text': {
    value: 'Strength',
    location: 'client',
  },
  'variation.name.character.text': {
    value: 'Character',
    location: 'client',
  },
  'variation.name.option.text': {
    value: 'Option',
    location: 'client',
  },
  'variation.name.type.text': {
    value: 'Type',
    location: 'client',
  },
  'variation.name.model.text': {
    value: 'Model',
    location: 'client',
  },
  'variation.name.finish.text': {
    value: 'Finish',
    location: 'client',
  },
  'variation.name.flavour1.text': {
    value: 'Flavour 1',
    location: 'client',
  },
  'variation.name.flavour2.text': {
    value: 'Flavour 2',
    location: 'client',
  },
  'variation.name.proteinflavour.text': {
    value: 'Protein Flavour',
    location: 'client',
  },
  'variation.name.bcaaflavour.text': {
    value: 'BCAA Flavour',
    location: 'client',
  },
  'variation.name.preworkoutflavour.text': {
    value: 'Pre-Workout Flavour',
    location: 'client',
  },
  'variation.name.snackflavour.text': {
    value: 'Snack Flavour',
    location: 'client',
  },
  'variation.name.hoodiesize.text': {
    value: 'Hoodie Size',
    location: 'client',
  },
  'variation.name.shortssize.text': {
    value: 'Short Size',
    location: 'client',
  },
  'variation.name.brasize.text': {
    value: 'Bra Size',
    location: 'client',
  },
  'variation.name.leggingssize.text': {
    value: 'Leggings Size',
    location: 'client',
  },
  'variation.name.bottomssize.text': {
    value: 'Bottom Size',
    location: 'client',
  },
  'variation.name.topsize.text': {
    value: 'Top Size',
    location: 'client',
  },
  'variation.name.creatineflavour.text': {
    value: 'Creatine Flavour',
    location: 'client',
  },
  'variation.name.intraworkoutflavour.text': {
    value: 'Intra-Workout Flavour',
    location: 'client',
  },
  'variation.name.syrupflavour.text': {
    value: 'Syrup Flavour',
    location: 'client',
  },
  'variation.name.fragrance.text': {
    value: 'Fragrance',
    location: 'client',
  },
  'variation.name.width.text': {
    value: 'Width',
    location: 'client',
  },
  'product.reviewinstructions.title': {
    value: 'Write your review',
    location: 'client',
  },
  'product.recommendations.title.text': {
    value: 'You may also like',
    location: 'client',
  },
  'product.recommendations.build.title.text': {
    value: 'Finish the look',
    location: 'client',
  },
  'account.messages.heading': {
    value: 'Messages',
    location: 'client',
  },
  'account.messages.notfound.heading': {
    value: 'Messages cannot be found',
    location: 'client',
  },
  'account.messages.notfound.text': {
    value:
      'They have either been removed from this location, or the URL is wrong. We’ve recorded this error to help us improve the site.',
    location: 'client',
  },
  'account.messages.title': {
    value: 'View messages',
    location: 'client',
  },
  'account.message.title': {
    value: 'View message',
    location: 'client',
  },
  'account.create.message.title': {
    value: 'Send a message',
    location: 'client',
  },
  'account.messages.status.completed': {
    value: 'Complete',
    location: 'client',
  },
  'account.messages.status.inprogress': {
    value: 'In progress',
    location: 'client',
  },
  'account.messages.status.outstanding': {
    value: 'Outstanding',
    location: 'client',
  },
  'account.messages.status.pending': {
    value: 'Pending',
    location: 'client',
  },
  'account.messages.category.whereismyorder': {
    value: 'Where is my order?',
    location: 'client',
  },
  'account.messages.create.category.option.free-gift': {
    value: 'I have a free gift enquiry',
    location: 'client',
  },
  'account.messages.category.free-gift': {
    value: 'I have a free gift enquiry',
    location: 'client',
  },

  'account.messages.category.websiteissues': {
    value: 'Website issues',
    location: 'client',
  },
  'account.messages.category.stockavailability': {
    value: 'Stock Availability',
    location: 'client',
  },
  'account.messages.category.returnsandrefunds': {
    value: 'Returns and Refunds',
    location: 'client',
  },
  'account.messages.category.productinfo': {
    value: 'Product Information',
    location: 'client',
  },
  'product.meta.description.text': {
    value:
      'Find %s at %s. Visit your local store for the widest range of storage & home products',
    location: 'client',
  },
  'product.description.accordion.title': {
    value: 'Product Information',
    location: 'client',
  },
  'product.description.accordion.useaccordion': {
    value: 'Use the accordion below to view details',
    location: 'client',
  },
  'account.messages.category.orderproblem': {
    value: 'Problem with my order',
    location: 'client',
  },
  'account.messages.none': { value: '-', location: 'client' },
  'account.create.message.submit': {
    value: 'Send',
    location: 'client',
  },
  'account.emailsignup.submit': {
    value: 'Submit',
    location: 'client',
  },
  'account.emailsignup.form.loading.error': {
    value: 'Error while loading sign up form',
    location: 'client',
  },
  'account.emailsignup.title': {
    value: 'Sign up to our newsletter',
    location: 'client',
  },
  'account.emailsignup.error.message': {
    value: 'Error subscribing to our newsletter.',
    location: 'client',
  },
  'product.reviewinstructions.instruction1': {
    value:
      "Thanks for sharing your thoughts! Please remember that results will vary from person to person. We're proud to say that all customer reviews are independent, authentic and do not represent the views of Matalan or The Hut Group.",
    location: 'client',
  },
  'product.reviewinstructions.instruction2': {
    value:
      "Simply enter your product review below we'll send you a unique 5% discount code. See below for competition rules. Reviews should relate to the product itself rather than any other aspect of our site or service.",
    location: 'client',
  },
  'product.reviewinstructions.instruction3': {
    value:
      'Reviews should relate to the product itself rather than any other aspect of our site or service.',
    location: 'client',
  },
  'product.reviewinstructions.manditoryfield': {
    value: 'Fields marked with a * are required.',
    location: 'client',
  },
  'product.writereview.heading.text': {
    value: 'Write a review',
    location: 'client',
  },
  'product.sizeguide.heading.text': {
    value: 'SIZE GUIDE',
    location: 'client',
  },
  'reviews.reviewscountersinglesuffix.text': {
    value: 'review',
    location: 'client',
  },
  'reviews.reviewscountersuffix.text': {
    value: 'reviews',
    location: 'client',
  },
  'reviews.viewreviews.text': {
    value: 'See all reviews',
    location: 'client',
  },
  'reviews.createreview.text': {
    value: 'Create a review',
    location: 'client',
  },
  'reviews.customerreviews.title': {
    value: 'Customer Reviews',
    location: 'client',
  },
  'reviews.topcustomerreviews.title': {
    value: 'Top Customer Reviews',
    location: 'client',
  },
  'reviews.topcustomerreviews.alternative.title': {
    value: 'Top customer Reviews',
    location: 'client',
  },
  'reviews.paginatedproductreviews.othercustomersbought.title': {
    value: 'Other Customers Bought',
    location: 'client',
  },
  'reviews.paginatedproductreviews.title': {
    value: 'All Customer Reviews',
    location: 'client',
  },
  'reviews.paginatedproductreviews.backtoproductlink.text': {
    value: 'Back to Product',
    location: 'client',
  },
  'reviews.paginatedproductreviews.productblock.from.text': {
    value: 'FROM',
    location: 'client',
  },
  'reviews.disclaimer.text': {
    value:
      'Where reviews refer to foods or cosmetic products, results may vary from person to person.  Customer reviews are independent and do not represent the views of The Hut Group.',
    location: 'client',
  },
  'reviews.noreviews.text': {
    value: 'There are currently no reviews.',
    location: 'client',
  },
  'reviews.writereview.text': {
    value: 'Write a review to be in with a chance of winning a £100 voucher.',
    location: 'client',
  },
  'product.optionselector.addtobasket.text': {
    value: 'Add to basket',
    location: 'client',
  },
  'product.optionselector.outofstock.text': {
    value: 'OUT OF STOCK',
    location: 'client',
  },
  'listitem.button.preordernow.text': {
    value: 'Pre-Order Now',
    location: 'client',
  },
  'product.releasedate.text': {
    value: 'Launch Date:',
    location: 'client',
  },
  'product.purchaseoptions.onetimepurchase.text': {
    value: 'One-time purchase',
    location: 'client',
  },
  'product.purchaseoptions.subscribeandgain.text': {
    value: 'Subscribe & Gain',
    location: 'client',
  },
  'product.purchaseoptions.payupfront.text': {
    value: 'Pay Upfront',
    location: 'client',
  },
  'product.purchaseoptions.payperiodically.text': {
    value: 'Pay Periodically',
    location: 'client',
  },
  'account.wishlist.cancel-update.text': {
    value: 'Cancel',
    location: 'client',
  },
  'account.wishlist.update.text': {
    value: 'Update',
    location: 'client',
  },
  'product.addtowishlistbutton.addtowishlist.text.dynamic': {
    value: 'Add %s to wishlist',
    location: 'client',
  },
  'product.addtowishlistbutton.addtowishlist.text': {
    value: 'Add to wishlist',
    location: 'client',
  },
  'product.scrolltotopbutton.text': {
    value: 'Preview your personalised bottle.',
    location: 'client',
  },
  'product.addtowishlistbutton.savedtowishlist.text': {
    value: 'Saved to wishlist',
    location: 'client',
  },
  'product.addtowishlist.tooltip.loginsignup.text': {
    value: 'Log in/sign up',
    location: 'client',
  },
  'product.addtowishlist.tooltip.text': {
    value: 'to use Wishlists!',
    location: 'client',
  },
  'product.addtowishlist.tooltip.button.close.text': {
    value: 'Close Save to Wishlist',
    location: 'client',
  },
  'review.addreview.title.text': {
    value: 'Submit a review',
    location: 'client',
  },
  'review.addreview.nickname.text': {
    value: 'Nickname',
    location: 'client',
  },
  'review.addreview.reviewtitle.text': {
    value: 'Review Title',
    location: 'client',
  },
  'review.addreview.yourreview.text': {
    value: 'Your Review',
    location: 'client',
  },
  'review.addreview.gender.text': {
    value: 'Gender',
    location: 'client',
  },
  'review.addreview.gender.placeholder': {
    value: 'Select Gender',
    location: 'client',
  },
  'review.addreview.age.text': {
    value: 'Your Age Range',
    location: 'client',
  },
  'review.addreview.age.placeholder': {
    value: 'Select Your Age Range',
    location: 'client',
  },
  'review.addreview.taste.text': {
    value: 'Taste',
    location: 'client',
  },
  'review.addreview.mixability.text': {
    value: 'Mixability',
    location: 'client',
  },
  'review.addreview.effectiveness.text': {
    value: 'Effectiveness',
    location: 'client',
  },
  'review.addreview.goeswellwith.text': {
    value: 'Goes Well With',
    location: 'client',
  },
  'review.addreview.submit.label': {
    value: 'Submit Review',
    location: 'client',
  },
  'review.addreview.failed.text': {
    value: 'Failed to fetch product form information. Please try again.',
    location: 'client',
  },
  'product.review.type.successfulsubmission': {
    value:
      'Thank you for submitting your review. Your review will appear shortly after going through our moderation process.',
    location: 'client',
  },
  'product.productoptions.swatch.tooltip.close.text': {
    value: 'Close product unavailable',
    location: 'client',
  },
  'product.productoptions.swatch.button.more.text': {
    value: 'Show all colours',
    location: 'client',
  },
  'product.productoptions.swatch.button.less.text': {
    value: 'Show fewer colours',
    location: 'client',
  },
  'product.productoptions.imageswatch.button.show.text': {
    value: 'Show',
    location: 'client',
  },
  'product.productoptions.imageswatch.button.more.text': {
    value: 'More',
    location: 'client',
  },
  'product.productoptions.imageswatch.button.less.text': {
    value: 'Less',
    location: 'client',
  },
  'product.productoptions.swatch.tooltip.unavailable.text': {
    value: 'is currently unavailable',
    location: 'client',
  },
  'product.productoptions.dropdown.unavailable.text': {
    value: 'Out of stock',
    location: 'client',
  },
  'product.productoptions.dropdown.customlabel.text': {
    value: 'Email when in stock',
    location: 'client',
  },
  'product.optionselector.variantoutofstock.text': {
    value: 'This variant is out of stock.',
    location: 'client',
  },
  'product.optionselector.clickandcollect.button.text': {
    value: 'Click and collect',
    location: 'client',
  },
  'product.optionselector.clickandcollect.button.checkstorestock.text': {
    value: 'Check store stock',
    location: 'client',
  },
  'product.optionselector.clickandcollect.link.text': {
    value: 'Check stock in your local store',
    location: 'client',
  },
  'product.recentlyviewedproducts.title': {
    value: 'Recently Viewed Products',
    location: 'client',
  },
  'product.maxquantity.basket.singleitem.text': {
    value: 'Item limited to max quantity of %s (%s item in your basket)',
    location: 'client',
  },
  'product.maxquantity.basket.multipleitems.text': {
    value: 'Item limited to max quantity of %s (%s items in your basket)',
    location: 'client',
  },
  'product.maxquantity.basket.limit.text': {
    value: 'Max quantity limit reached',
    location: 'client',
  },
  'product.productblock.colourswatch.more.text': {
    value: 'More',
    location: 'client',
  },
  'product.productblock.colourswatch.more.alt.text': {
    value: 'colours',
    location: 'client',
  },
  'review.content.verifiedpurchase': {
    value: 'Verified Purchase',
    location: 'client',
  },
  'review.content.washelpful.text': {
    value: 'Was this helpful?',
    location: 'client',
  },
  'review.content.positive.text': {
    value: 'Yes',
    location: 'client',
  },
  'review.content.negative.text': {
    value: 'No',
    location: 'client',
  },
  'review.content.positive.label': {
    value: 'Vote Yes, %s votes',
    location: 'client',
  },
  'review.content.negative.label': {
    value: 'Vote No, %s votes',
    location: 'client',
  },
  'review.content.starrating.label': {
    value: '%s out of 5 stars',
    location: 'client',
  },
  'review.content.failed.text': {
    value: 'Failed to fetch product reviews information. Please retry again.',
    location: 'client',
  },
  'review.content.report.text': {
    value: 'Report this review',
    location: 'client',
  },
  'review.content.by.text': {
    value: 'by',
    location: 'client',
  },
  'review.content.voteregistered': {
    value: 'Your vote has been registered, thank you for your feedback!',
    location: 'client',
  },
  'review.content.duplicatevote': {
    value:
      'You have already voted on this review, thank you for your feedback!',
    location: 'client',
  },
  'orderproduct.cancelreason.amendments': {
    value: 'Ammendments needed',
    location: 'client',
  },
  'orderproduct.cancelreason.discountcodeissue': {
    value: 'Discount code issue',
    location: 'client',
  },
  'orderproduct.cancelreason.duplicateorder': {
    value: 'Duplicate order',
    location: 'client',
  },
  'orderproduct.cancelreason.foundcheaperelsewhere': {
    value: 'Found cheaper elsewhere',
    location: 'client',
  },
  'orderproduct.cancelreason.nolongerrequired': {
    value: 'No longer required',
    location: 'client',
  },
  'orderproduct.cancelreason.orderedwrongitem': {
    value: 'Ordered wrong item',
    location: 'client',
  },
  'orderproduct.cancelreason.waitedtoolong': {
    value: 'Waited too long',
    location: 'client',
  },
  'review.content.reviewreported': {
    value:
      'Thank you for reporting this review. It has been flagged for further investigation.',
    location: 'client',
  },
  'review.content.duplicate.reviewreported': {
    value:
      'You have already reported this review. It has been flagged for further investigation.',
    location: 'client',
  },
  stickyoptanoncookiebuttonlink: {
    value: 'Cookie Consent',
    location: 'client',
  },
  'product.subscriptions.subscriptionfrequencyselector.title': {
    value: 'Deliver every:',
    location: 'client',
  },
  'account.subscriptions.detail.title': {
    value: 'All Products',
    location: 'client',
  },
  'account.subscriptioncancel.title': {
    value: 'Cancel subscription',
    location: 'client',
  },
  'account.subscriptioncancel.delay.text': {
    value:
      'Remember, you can choose to delay your next order before cancelling! Are you sure you would like to cancel your subscription?',
    location: 'client',
  },
  'account.subscriptioncancel.text': {
    value:
      'Once you have cancelled your subscription you will stop receiving your deliveries. An email will be sent to you confirming your cancellation plus instructions on how to claim a refund on your order should you be eligible to do so.',
    location: 'client',
  },
  'account.subscriptioncancel.delaybutton.title': {
    value: 'Delay subscription',
    location: 'client',
  },
  'account.subscriptioncancel.confirmbutton.title': {
    value: 'Yes, cancel subscription',
    location: 'client',
  },
  'account.subscriptions.address.error.text': {
    value:
      'There was an error loading subscription delivery details, please try again later.',
    location: 'client',
  },
  'account.order.reviewform.score': {
    value: 'score',
    location: 'client',
  },
  'account.order.addreview.submit.success.title': {
    value: 'Thank You',
    location: 'client',
  },
  'account.order.addreview.submit.success.message': {
    value: 'You have successfully submitted your review(s)',
    location: 'client',
  },
  'account.order.addreview.submit.button.text': {
    value: 'Submit Review',
    location: 'client',
  },
  'account.order.address.error.text': {
    value:
      'There was an error loading order delivery details, please try again later.',
    location: 'client',
  },
  'account.address.successfuldelete.text': {
    value: 'The address has been deleted',
    location: 'client',
  },
  'account.address.successfulupdate.delivery.text': {
    value: 'Your default delivery address has been updated',
    location: 'client',
  },
  'account.address.successfulupdate.billing.text': {
    value: 'Your default billing address has been updated',
    location: 'client',
  },
  'account.address.errorupdate.deliveryorbilling.text': {
    value: 'Updating address details was unsuccessful, please try again later.',
    location: 'client',
  },
  'account.address.loadingupdate.delivery.addresses': {
    value: '(It might take some time to see the change)',
    location: 'client',
  },
  'account.address.errordeleting.text': {
    value: 'There was an error deleting the address, please try again later.',
    location: 'client',
  },
  'account.address.info.message.setaddress': {
    value:
      'Please set up a default billing address to receive postal marketing.',
    location: 'client',
  },
  'account.order.management.heading': {
    value: 'Order Management',
    location: 'client',
  },
  'account.order.management.reorder': {
    value: 'Reorder',
    location: 'client',
  },
  'account.order.management.cancel': {
    value: 'Cancel',
    location: 'client',
  },
  'account.order.management.return': {
    value: 'Return',
    location: 'client',
  },
  'account.order.management.review': {
    value: 'Review',
    location: 'client',
  },
  'account.payment.error.text': {
    value:
      'There was an error loading payment information, please try again later.',
    location: 'client',
  },
  'account.payment.cardending.text': {
    value: 'Card ending with %s',
    location: 'client',
  },
  'account.subscriptiondetail.requesterror': {
    value:
      'There has been a problem processing your request for this subscription. Please try again later',
    location: 'client',
  },
  'account.subscriptiondetail.cancelsuccess.text': {
    value: 'Your subscription has been cancelled successfully',
    location: 'client',
  },
  'account.subscriptiondetail.update.success': {
    value:
      'Your subscription frequency has been successfully updated to "Every %s". %s (It might take some time to see the change)',
    location: 'client',
  },
  'account.subscriptiondetail.frequency.update.success': {
    value: 'Your next billing date is %s.',
    location: 'client',
  },
  'account.subscriptiondetail.update.success.warning': {
    value: '(It might take some time to see the change)',
    location: 'client',
  },
  'account.subscription.addon.success': {
    value:
      '"%s" has been successfully added to your subscription. (It might take some time to see the change)',
    location: 'client',
  },
  'account.subscriptiondetail.delaysuccess.text': {
    value: 'Your subscription has been delayed successfully',
    location: 'client',
  },
  'account.subscriptiondetail.canceldelaysuccess.text': {
    value: 'Your subscription delay has been cancelled successfully',
    location: 'client',
  },
  'titles.page.account-subscription-canceldelay.text': {
    value: 'Cancel subscription delay',
    location: 'client',
  },
  'titles.page.account-subscription-cancel.text': {
    value: 'Cancel subscription',
    location: 'client',
  },
  'titles.page.account-subscription-delay.text': {
    value: 'Delay subscription',
    location: 'client',
  },
  'product.marketedspecialoffer.freegift.text': {
    value: 'Worth: ',
    location: 'client',
  },
  'titles.page.account.subscriptionedit.text': {
    value: 'Edit subscription',
    location: 'client',
  },
  'titles.page.account.subscriptionbillingdateedit.text': {
    value: 'Edit subscription order date',
    location: 'client',
  },
  'titles.page.account.subscriptionaddon.text': {
    value: 'Subscription Product Addon',
    location: 'client',
  },
  'emailengagement.success.title': {
    value: 'Thank you!',
    location: 'client',
  },
  'emailengagement.success.text': {
    value: 'You are now signed up to our email',
    location: 'client',
  },
  'emailengagement.retry.button.text': {
    value: 'Try Again',
    location: 'client',
  },
  'emailengagement.retry.text': {
    value: 'There was an error please try again',
    location: 'client',
  },
  'product.frequentlyboughttogether.title': {
    value: 'Frequently bought together',
    location: 'client',
  },
  'product.frequentlyboughttogether.thisitem': {
    value: 'This item',
    location: 'client',
  },
  'product.frequentlyboughttogether.recommendeditem': {
    value: 'Recommended Item',
    location: 'client',
  },
  'product.frequentlyboughttogether.addbothtobasket': {
    value: 'Add both to Basket',
    location: 'client',
  },
  'product.frequentlyboughttogether.from.text': {
    value: 'From',
    location: 'client',
  },
  'product.frequentlyboughttogether.totalprice.text': {
    value: 'Total Price',
    location: 'client',
  },
  'product.frequentlyboughttogether.widget.title.text': {
    value: 'Other customers bought:',
    location: 'client',
  },
  'product.alternatives.text': {
    value:
      'This product is coming soon! Add to your wishlist to receive an in stock email notification',
    location: 'client',
  },
  'product.subscription.information.title': {
    value: 'How a subscription works',
    location: 'client',
  },
  'product.subscription.information.list.text1': {
    value: 'Swap your flavour anytime',
    location: 'client',
  },
  'product.subscription.information.list.text2': {
    value: 'You control the frequency',
    location: 'client',
  },
  'product.subscription.information.list.text3': {
    value: 'No commitment. Cancel or delay',
    location: 'client',
  },
  'basket.subscription.information.title': {
    value: 'Future payments',
    location: 'client',
  },
  'basket.subscription.information.percentage.text': {
    value: '%s off',
    location: 'client',
  },
  'basket.subscription.information.duration.single.month.text': {
    value: 'Every 1 month',
    location: 'client',
  },
  'basket.subscription.information.duration.single.week.text': {
    value: 'Every 1 week',
    location: 'client',
  },
  'basket.subscription.information.duration.single.year.text': {
    value: 'Every 1 year',
    location: 'client',
  },
  'basket.subscription.information.duration.multiple.text': {
    value: 'Every %s months',
    location: 'client',
  },
  'basket.subscription.information.duration.multiple.weeks.text': {
    value: 'Every %s weeks',
    location: 'client',
  },
  'basket.subscription.information.duration.multiple.years.text': {
    value: 'Every %s years',
    location: 'client',
  },
  'basket.subscription.information.note': {
    value:
      'Note: You will only be charged when your product is dispatched. You have the option to cancel or delay at any time.',
    location: 'client',
  },
  'basket.subscription.information.shipped.note': {
    value:
      'You will only be charged when your order is shipped. You have the option to cancel or delay at any time.',
    location: 'client',
  },
  'basket.subscriptiondropdown.label.text': {
    value: 'Change subscription frequency',
    location: 'client',
  },
  'account.orderdetail.deliverymethod.text': {
    value: 'Delivery Method:',
    location: 'client',
  },
  'account.orderdetail.clickandcollect.text': {
    value: 'Click and Collect',
    location: 'client',
  },
  'account.orderdetail.delivertostore.text': {
    value: 'Deliver to store',
    location: 'client',
  },
  'general.accessibility.products.label': {
    value: 'Products',
    location: 'client',
  },
  'product.list.sort.arialabel': {
    value: 'Sort by',
    location: 'client',
  },
  'product.list.sort.relevance': {
    value: 'Default',
    location: 'client',
  },
  'product.list.sort.relevancetitle': {
    value: 'Relevance',
    location: 'client',
  },
  'product.list.sort.popularity': {
    value: 'Popularity',
    location: 'client',
  },
  'product.list.sort.pricelowtohigh': {
    value: 'Price (Low - High)',
    location: 'client',
  },
  'product.list.sort.pricehightolow': {
    value: 'Price (High - Low)',
    location: 'client',
  },
  'product.list.sort.namealphabetically': {
    value: 'A - Z',
    location: 'client',
  },
  'product.list.facets.horizontal.brand.all': {
    value: 'All',
    location: 'client',
  },
  'product.list.sort.newesttooldest': {
    value: 'Newest Arrivals',
    location: 'client',
  },
  'product.list.sort.discountpercentagehightolow': {
    value: 'Percentage Discount',
    location: 'client',
  },
  'sponsored.label.text': {
    value: 'SPONSORED',
    location: 'client',
  },
  'sponsored.banner.subtitle.text': {
    value: 'Sponsored',
    location: 'client',
  },
  'product.list.error.text': {
    value: 'There was an error loading products, please try again later.',
    location: 'client',
  },
  'general.readmore.text': {
    value: 'Read More',
    location: 'client',
  },
  'general.readless.text': {
    value: 'Read Less',
    location: 'client',
  },
  'account.sociallinks.active.title': {
    value: 'Active Links',
    location: 'client',
  },
  'account.sociallinks.pending.title': {
    value: 'Pending Links',
    location: 'client',
  },
  'account.sociallinks.empty.text': {
    value: 'You currently have no linked social accounts.',
    location: 'client',
  },
  'account.sociallinks.error.title': {
    value: "Social Links can't be found",
    location: 'client',
  },
  'account.sociallinks.unlink.cta.text': {
    value: 'Unlink',
    location: 'client',
  },
  'account.sociallinks.unlink.cta.arialabel': {
    value: 'Unlink %s',
    location: 'client',
  },
  'account.sociallinks.approve.cta.text': {
    value: 'Approve',
    location: 'client',
  },
  'account.sociallinks.approve.cta.arialabel': {
    value: 'Approve %s',
    location: 'client',
  },
  'account.sociallinks.reject.cta.text': {
    value: 'Reject',
    location: 'client',
  },
  'account.sociallinks.reject.cta.arialabel': {
    value: 'Reject %s',
    location: 'client',
  },
  'account.sociallinks.id.label': {
    value: 'ID',
    location: 'client',
  },
  'account.sociallinks.error.text': {
    value: 'There was an error whilst trying to find linked social accounts.',
    location: 'client',
  },
  'account.sociallinks.unlink.success.text': {
    value: 'Social account unlinked successfully.',
    location: 'client',
  },
  'account.sociallinks.unlink.error.text': {
    value: 'There was an error unlinking your social account.',
    location: 'client',
  },
  'account.sociallinks.approve.success.text': {
    value: 'Social account is now approved.',
    location: 'client',
  },
  'account.sociallinks.approve.error.text': {
    value: 'There was an error approving your social account.',
    location: 'client',
  },
  'account.sociallinks.reject.success.text': {
    value: 'Social account was rejected successfully',
    location: 'client',
  },
  'account.sociallinks.reject.error.text': {
    value: 'There was an error rejecting your social account.',
    location: 'client',
  },
  'general.negative.text': {
    value: 'Negative',
    location: 'client',
  },
  'content.searchresults.title': {
    value: 'Found results for',
    location: 'client',
  },
  'content.searchresultsnotfound.text': {
    value:
      'There are no results for your search term, please try again or use the navigation to make your way around the site.',
    location: 'client',
  },
  'content.searchresultsnotfound.nextstep': {
    value: 'Try another search',
    location: 'client',
  },
  'basket.specialoffer.appliedprefix.text': {
    value: 'You have qualified for:',
    location: 'client',
  },
  'basket.specialoffer.selectyoursample.suffix.text': {
    value: "- Don't forget to make your selection below",
    location: 'client',
  },
  'basket.discountapply.betterofferapplied.text': {
    value:
      'Discount code is not applicable as there is a better offer already applied to the contents of your basket.',
    location: 'client',
  },
  'basket.discountapply.expired.text': {
    value: 'The discount code you entered is not an active offer',
    location: 'client',
  },
  'basket.discountapply.invalid.text': {
    value:
      'The discount code you entered is invalid or not applicable to the contents of your basket',
    location: 'client',
  },
  'basket.discountapply.applied.text': {
    value: 'Your discount has been applied: %s',
    location: 'client',
  },
  'basket.delivery.message.text': {
    value: 'Delivery charge calculated at checkout',
    location: 'client',
  },
  'component.livechat.statusonline.text': {
    value: 'Our operators are online',
    location: 'client',
  },
  'component.livechat.operators.text': {
    value: 'Our operators are',
    location: 'client',
  },
  'component.livechat.online.text': {
    value: 'online',
    location: 'client',
  },
  'component.livechat.info.text': {
    value: 'Average connection time 25 secs',
    location: 'client',
  },
  'component.livechat.chat.button.text': {
    value: 'Start Chat',
    location: 'client',
  },
  'component.livechat.title.text': {
    value: 'Live Chat',
    location: 'client',
  },
  'component.adachat.operators.text': {
    value: 'Our operators are',
    location: 'client',
  },
  'component.adachat.online.text': {
    value: 'online',
    location: 'client',
  },
  'component.adachat.info.text': {
    value: 'Average connection time 25 secs',
    location: 'client',
  },
  'component.adachat.chat.button.text': {
    value: 'Start Chat',
    location: 'client',
  },
  'component.adachat.title.text': {
    value: 'Ada Chat',
    location: 'client',
  },
  'product.brand.logo.label': {
    value: 'Explore %s range',
    location: 'client',
  },
  'product.content.synopsis.title.text': {
    value: 'Description',
    location: 'client',
  },
  'product.content.careinstructions.title.text': {
    value: 'Care Instruction',
    location: 'client',
  },
  'product.content.nutritionalinfo.title.text': {
    value: 'Nutritional Information',
    location: 'client',
  },
  'product.content.faq.title.text': {
    value: 'FAQ',
    location: 'client',
  },
  'product.content.ingredients.title.text': {
    value: 'Ingredients',
    location: 'client',
  },
  'product.content.aboutthegame.title.text': {
    value: 'Notes',
    location: 'client',
  },
  'product.content.sizefit.title.text': {
    value: 'Size & Fit',
    location: 'client',
  },
  'product.content.suggesteduse.title.text': {
    value: 'Suggested Use',
    location: 'client',
  },
  'product.content.strengthdetail.title.text': {
    value: 'Ingredient Benefits',
    location: 'client',
  },
  'product.content.additionalproductinfo.title.text': {
    value: 'Beauty Editor’s Notes',
    location: 'client',
  },
  'product.content.visibleresults.title.text': {
    value: 'About The Brand',
    location: 'client',
  },
  'product.provenancetab.heading.text': {
    value: 'Provenance',
    location: 'client',
  },
  'product.provenance.proofofimpact.title.text': {
    value: 'Provenance Proof of Impact',
    location: 'client',
  },
  'product.provenance.verified.icon.aria.label': {
    value: 'Go to verified product transparency information',
    location: 'client',
  },
  'product.content.directions.title.text': {
    value: 'How To Use',
    location: 'client',
  },
  'product.content.legalname.title.text': {
    value: 'Legal Name',
    location: 'client',
  },
  'product.content.keybenefits.title.text': {
    value: 'Key Benefits',
    location: 'client',
  },
  'product.content.recyclability.title.text': {
    value: 'Fixed For Life',
    location: 'client',
  },
  'product.content.whychoose.title.text': {
    value: 'Why Choose?',
    location: 'client',
  },
  'product.content.volume.title.text': {
    value: 'Volume',
    location: 'client',
  },
  'product.content.trailer.title.text': {
    value: 'Trailer',
    location: 'client',
  },
  'product.content.allergens.title.text': {
    value: 'Allergens',
    location: 'client',
  },
  'product.content.diet.title.text': {
    value: 'Diet',
    location: 'client',
  },
  'product.content.brand.title.text': {
    value: 'Brand',
    location: 'client',
  },
  'product.content.range.title.text': {
    value: 'Range',
    location: 'client',
  },
  'product.content.productdetails.title.text': {
    value: 'Product Details',
    location: 'client',
  },
  'product.content.productvideo.title.text': {
    value: 'Product Video',
    location: 'client',
  },
  'product.content.mat_material.title.text': {
    value: 'Material',
    location: 'client',
  },
  'product.content.mat_fabric.title.text': {
    value: 'Fabric',
    location: 'client',
  },
  'product.content.mat_careInstruction.title.text': {
    value: 'Care instructions',
    location: 'client',
  },
  'product.content.mat_weight.title.text': {
    value: 'Weight',
    location: 'client',
  },
  'product.content.mat_volume.title.text': {
    value: 'Volume',
    location: 'client',
  },
  'product.content.mat_assembly.title.text': {
    value: 'Assembly',
    location: 'client',
  },
  'product.content.mat_depth.title.text': {
    value: 'Depth',
    location: 'client',
  },
  'product.content.mat_productId.title.text': {
    value: 'Product ID',
    location: 'client',
  },
  'product.content.mat_height.title.text': {
    value: 'Height',
    location: 'client',
  },
  'product.content.mat_width.title.text': {
    value: 'Width',
    location: 'client',
  },
  'product.content.mat_length.title.text': {
    value: 'Length',
    location: 'client',
  },
  'product.content.hbg_accurateupto.title.text': {
    value: 'Accurate Up To',
    location: 'client',
  },
  'product.content.hbg_addAssemblyinfo.title.text': {
    value: 'Additional Assembly Information',
    location: 'client',
  },
  'product.content.hbg_addinfo.title.text': {
    value: 'Additional Information',
    location: 'client',
  },
  'product.content.hbg_adhesivesachet.title.text': {
    value: 'Adhesive Sachet',
    location: 'client',
  },
  'product.content.hbg_adjustthermostat.title.text': {
    value: 'Adjustable Thermostat',
    location: 'client',
  },
  'product.content.hbg_adjustable.title.text': {
    value: 'Adjustable',
    location: 'client',
  },
  'product.content.hbg_agerange.title.text': {
    value: 'Age Range',
    location: 'client',
  },
  'product.content.hbg_agerestrictions.title.text': {
    value: 'Age Restrictions',
    location: 'client',
  },
  'product.content.hbg_airinlet.title.text': {
    value: 'Air Inlet',
    location: 'client',
  },
  'product.content.hbg_airpressure.title.text': {
    value: 'Air Pressure',
    location: 'client',
  },
  'product.content.hbg_airertype.title.text': {
    value: 'Airer Type',
    location: 'client',
  },
  'product.content.hbg_airflowrate.title.text': {
    value: 'Airflow Rate',
    location: 'client',
  },
  'product.content.hbg_alerts.title.text': {
    value: 'Alerts',
    location: 'client',
  },
  'product.content.hbg_alsoavailableIn.title.text': {
    value: 'Also Available In',
    location: 'client',
  },
  'product.content.hbg_amprating.title.text': {
    value: 'Amp Rating',
    location: 'client',
  },
  'product.content.hbg_amps.title.text': {
    value: 'Amps',
    location: 'client',
  },
  'product.content.hbg_annual.title.text': {
    value: 'Annual',
    location: 'client',
  },
  'product.content.hbg_antiallergy.title.text': {
    value: 'Anti-Allergy',
    location: 'client',
  },
  'product.content.hbg_antiscale.title.text': {
    value: 'Anti Scale',
    location: 'client',
  },
  'product.content.hbg_application.title.text': {
    value: 'Application Method',
    location: 'client',
  },
  'product.content.hbg_assemblyoption.title.text': {
    value: 'Assembly OptionsÂ ',
    location: 'client',
  },
  'product.content.hbg_autodefrost.title.text': {
    value: 'Auto Defrost',
    location: 'client',
  },
  'product.content.hbg_autoshut-off.title.text': {
    value: 'Auto Shut Off',
    location: 'client',
  },
  'product.content.hbg_avairconsumption.title.text': {
    value: 'Average Air Consumption',
    location: 'client',
  },
  'product.content.hbg_bagorbagless.title.text': {
    value: 'Bagged or Bagless',
    location: 'client',
  },
  'product.content.hbg_baseincluded.title.text': {
    value: 'Base Included',
    location: 'client',
  },
  'product.content.hbg_basindetails.title.text': {
    value: 'Basin Details',
    location: 'client',
  },
  'product.content.hbg_battmainsoperate.title.text': {
    value: 'Battery or Mains Operated',
    location: 'client',
  },
  'product.content.hbg_batterylife.title.text': {
    value: 'Battery Life',
    location: 'client',
  },
  'product.content.hbg_batteryname.title.text': {
    value: 'Type Of Battery',
    location: 'client',
  },
  'product.content.hbg_battsincluded.title.text': {
    value: 'Battery Included',
    location: 'client',
  },
  'product.content.hbg_battsrequired.title.text': {
    value: 'Type Of Battery Required',
    location: 'client',
  },
  'product.content.hbg_beabapproved.title.text': {
    value: 'BEAB Approved',
    location: 'client',
  },
  'product.content.hbg_bedsize.title.text': {
    value: 'Bed Size',
    location: 'client',
  },
  'product.content.hbg_bevellededge.title.text': {
    value: 'Beveled Edge',
    location: 'client',
  },
  'product.content.hbg_blackout.title.text': {
    value: 'Blackout',
    location: 'client',
  },
  'product.content.hbg_bladesize.title.text': {
    value: 'Blade Dimensions (cm)',
    location: 'client',
  },
  'product.content.hbg_blowspeed.title.text': {
    value: 'Blow Speed',
    location: 'client',
  },
  'product.content.hbg_blowsperminute.title.text': {
    value: 'Blows Per Minute',
    location: 'client',
  },
  'product.content.hbg_borderdimensions.title.text': {
    value: 'Border Size',
    location: 'client',
  },
  'product.content.hbg_boresize.title.text': {
    value: 'Bore Size',
    location: 'client',
  },
  'product.content.hbg_bottleclosure.title.text': {
    value: 'Bottle Closure',
    location: 'client',
  },
  'product.content.hbg_brackets.title.text': {
    value: 'Bracket Included',
    location: 'client',
  },
  'product.content.hbg_built-inseating.title.text': {
    value: 'Built-In Seating',
    location: 'client',
  },
  'product.content.hbg_builtInfreestand.title.text': {
    value: 'Freestanding',
    location: 'client',
  },
  'product.content.hbg_bulbcolour.title.text': {
    value: 'Bulb Colour',
    location: 'client',
  },
  'product.content.hbg_bulbrequired.title.text': {
    value: 'Bulb Required',
    location: 'client',
  },
  'product.content.hbg_burnerdetails.title.text': {
    value: 'Burner Details',
    location: 'client',
  },
  'product.content.hbg_burningtime.title.text': {
    value: 'Burning Time',
    location: 'client',
  },
  'product.content.hbg_cabinetmaterial.title.text': {
    value: 'Cabinet Material',
    location: 'client',
  },
  'product.content.hbg_cadrratingdust.title.text': {
    value: 'CADR Rating Dust',
    location: 'client',
  },
  'product.content.hbg_cadrratingpollen.title.text': {
    value: 'CADR Rating Pollen',
    location: 'client',
  },
  'product.content.hbg_cadrratingsmoke.title.text': {
    value: 'CADR Rating Smoke',
    location: 'client',
  },
  'product.content.hbg_cameradetails.title.text': {
    value: 'Camera Details',
    location: 'client',
  },
  'product.content.hbg_canbecuttosize.title.text': {
    value: 'Can Be Cut To Size',
    location: 'client',
  },
  'product.content.hbg_capbottomoven.title.text': {
    value: 'Capacity For Bottom Oven',
    location: 'client',
  },
  'product.content.hbg_capmainoven.title.text': {
    value: 'Capacity Of Main Oven',
    location: 'client',
  },
  'product.content.hbg_capsecondoven.title.text': {
    value: 'Capacity Of Second Oven',
    location: 'client',
  },
  'product.content.hbg_captopoven.title.text': {
    value: 'Capacity For Top Oven',
    location: 'client',
  },
  'product.content.hbg_capacwatertank.title.text': {
    value: 'Capacity Of Water Tank',
    location: 'client',
  },
  'product.content.hbg_capacity.title.text': {
    value: 'Capacity',
    location: 'client',
  },
  'product.content.hbg_capacityofgrill.title.text': {
    value: 'Capacity Of Grill',
    location: 'client',
  },
  'product.content.hbg_caseincluded.title.text': {
    value: 'Case Included',
    location: 'client',
  },
  'product.content.hbg_cashrating.title.text': {
    value: 'Cash Rating',
    location: 'client',
  },
  'product.content.hbg_chargetime.title.text': {
    value: 'Charge Time',
    location: 'client',
  },
  'product.content.hbg_chargerincluded.title.text': {
    value: 'Charger Included',
    location: 'client',
  },
  'product.content.hbg_childlock.title.text': {
    value: 'Child Lock',
    location: 'client',
  },
  'product.content.hbg_chuckdetails.title.text': {
    value: 'Chuck Details',
    location: 'client',
  },
  'product.content.hbg_cill.title.text': {
    value: 'Cill',
    location: 'client',
  },
  'product.content.hbg_cleaningcareinst.title.text': {
    value: 'Care Instructions',
    location: 'client',
  },
  'product.content.hbg_climateclass.title.text': {
    value: 'Climate Class',
    location: 'client',
  },
  'product.content.hbg_clothingsize.title.text': {
    value: 'Clothing Size',
    location: 'client',
  },
  'product.content.hbg_collectioncapac.title.text': {
    value: 'Collection Capacity',
    location: 'client',
  },
  'product.content.hbg_colourofflower.title.text': {
    value: 'Colour Of Flower/Head',
    location: 'client',
  },
  'product.content.hbg_colouroflight.title.text': {
    value: 'Colour Of Light',
    location: 'client',
  },
  'product.content.hbg_colourofproduct.title.text': {
    value: 'Core Colour',
    location: 'client',
  },
  'product.content.hbg_combidetails.title.text': {
    value: 'Combination Details',
    location: 'client',
  },
  'product.content.hbg_compeasyplumbkit.title.text': {
    value: 'Compatible Easy Plumb Kit',
    location: 'client',
  },
  'product.content.hbg_compartcapacity.title.text': {
    value: 'Compartment Capacity',
    location: 'client',
  },
  'product.content.hbg_compatbathpanel.title.text': {
    value: 'Compatible Bath Panel',
    location: 'client',
  },
  'product.content.hbg_compatibleWith.title.text': {
    value: 'Compatible With',
    location: 'client',
  },
  'product.content.hbg_concealedelement.title.text': {
    value: 'Concealed Element',
    location: 'client',
  },
  'product.content.hbg_construction.title.text': {
    value: 'Construction',
    location: 'client',
  },
  'product.content.hbg_constructiontype.title.text': {
    value: 'Shed Construction Type',
    location: 'client',
  },
  'product.content.hbg_contents.title.text': {
    value: 'Contents',
    location: 'client',
  },
  'product.content.hbg_cookareadims.title.text': {
    value: 'Dimensions Of Cooking Area (HxWxD)',
    location: 'client',
  },
  'product.content.hbg_cordedorcordless.title.text': {
    value: 'Corded',
    location: 'client',
  },
  'product.content.hbg_coverincluded.title.text': {
    value: 'Cover Included',
    location: 'client',
  },
  'product.content.hbg_covertype.title.text': {
    value: 'Cover Type',
    location: 'client',
  },
  'product.content.hbg_coverage.title.text': {
    value: 'Coverage',
    location: 'client',
  },
  'product.content.hbg_coveragearea.title.text': {
    value: 'Coverage Area',
    location: 'client',
  },
  'product.content.hbg_coverageperlitre.title.text': {
    value: 'Coverage Per Litre',
    location: 'client',
  },
  'product.content.hbg_coverageperpack.title.text': {
    value: 'Coverage Per Pack',
    location: 'client',
  },
  'product.content.hbg_coveragepertin.title.text': {
    value: 'Coverage Per Tin',
    location: 'client',
  },
  'product.content.hbg_cushionsincluded.title.text': {
    value: 'Cushions Included',
    location: 'client',
  },
  'product.content.hbg_cuttingheight.title.text': {
    value: 'Cutting Height (cm)',
    location: 'client',
  },
  'product.content.hbg_cuttingwidth.title.text': {
    value: 'Cutting Width (cm)',
    location: 'client',
  },
  'product.content.hbg_deciduous.title.text': {
    value: 'DeciduousÂ ',
    location: 'client',
  },
  'product.content.hbg_deckingcolour.title.text': {
    value: 'Decking Colour',
    location: 'client',
  },
  'product.content.hbg_defrostsystem.title.text': {
    value: 'Defrost System',
    location: 'client',
  },
  'product.content.hbg_deliverytnfo.title.text': {
    value: 'Additional Delivery Information',
    location: 'client',
  },
  'product.content.hbg_depth.title.text': {
    value: 'Depth',
    location: 'client',
  },
  'product.content.hbg_detectionangle.title.text': {
    value: 'Detection Angle',
    location: 'client',
  },
  'product.content.hbg_detectionrange.title.text': {
    value: 'Detective Range',
    location: 'client',
  },
  'product.content.hbg_diameter.title.text': {
    value: 'Diameter',
    location: 'client',
  },
  'product.content.hbg_diameterextended.title.text': {
    value: 'Diameter When Extended',
    location: 'client',
  },
  'product.content.hbg_diameterfitting.title.text': {
    value: 'Diameter Of Fitting',
    location: 'client',
  },
  'product.content.hbg_diameterofbase.title.text': {
    value: 'Diameter Of Base',
    location: 'client',
  },
  'product.content.hbg_diameteroflamp.title.text': {
    value: 'Diameter Of Lamp',
    location: 'client',
  },
  'product.content.hbg_dimensions.title.text': {
    value: 'Product Dimensions (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_dimensionsdrawer.title.text': {
    value: 'Dimensions Of Drawer (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_dimensionsfolded.title.text': {
    value: 'Dimensions When Folded',
    location: 'client',
  },
  'product.content.hbg_dimensionshandle.title.text': {
    value: 'Handle Length (cm)',
    location: 'client',
  },
  'product.content.hbg_dimensionsLXWXH.title.text': {
    value: 'Dimensions (L)x(W)',
    location: 'client',
  },
  'product.content.hbg_dimensionsofsofa.title.text': {
    value: 'Dimensions Of Sofa (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_dimensionsopen.title.text': {
    value: 'Dimensions When Open',
    location: 'client',
  },
  'product.content.hbg_dimensionsshelf.title.text': {
    value: 'Dimensions Of Shelf (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_dimmable.title.text': {
    value: 'Dimmable',
    location: 'client',
  },
  'product.content.hbg_dimschairs.title.text': {
    value: 'Dimensions Of Chairs (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_dimsironsurface.title.text': {
    value: 'Dimensions Of Ironing Surface',
    location: 'client',
  },
  'product.content.hbg_dimsoffootstool.title.text': {
    value: 'Dimensions Of Footstool (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_dimsofparasol.title.text': {
    value: 'Dimensions Of Parasol (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_dimsopeningreq.title.text': {
    value: 'Dimensions Of Opening Required',
    location: 'client',
  },
  'product.content.hbg_dimsrecessreq.title.text': {
    value: 'Dimensions Of Recess Required',
    location: 'client',
  },
  'product.content.hbg_dimstable.title.text': {
    value: 'Dimensions Of Table (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_dimstableextend.title.text': {
    value: 'Dimensions Of Table (When Extended)',
    location: 'client',
  },
  'product.content.hbg_dishwashersafe.title.text': {
    value: 'Dishwasher Safe',
    location: 'client',
  },
  'product.content.hbg_distbetweenlight.title.text': {
    value: 'Distance Between Lights',
    location: 'client',
  },
  'product.content.hbg_distfirstlight.title.text': {
    value: 'Distance To First Light',
    location: 'client',
  },
  'product.content.hbg_distwallcenconn.title.text': {
    value: 'Distance - Wall To Centre Connection',
    location: 'client',
  },
  'product.content.hbg_distwallfronradi.title.text': {
    value: 'Distance - Wall To Front Of Radiator',
    location: 'client',
  },
  'product.content.hbg_doormaterial.title.text': {
    value: 'Door Material',
    location: 'client',
  },
  'product.content.hbg_drillingcapacity.title.text': {
    value: 'Drilling Capacity',
    location: 'client',
  },
  'product.content.hbg_driveonsetttime.title.text': {
    value: 'Drive On Setting Time',
    location: 'client',
  },
  'product.content.hbg_drytankalert.title.text': {
    value: 'Dry Tank Alert',
    location: 'client',
  },
  'product.content.hbg_dryingcapacity.title.text': {
    value: 'Drying Capacity',
    location: 'client',
  },
  'product.content.hbg_dryingperfrating.title.text': {
    value: 'Drying Performance Rating',
    location: 'client',
  },
  'product.content.hbg_dryingtime.title.text': {
    value: 'Drying Time',
    location: 'client',
  },
  'product.content.hbg_dualzones.title.text': {
    value: 'Dual Zones',
    location: 'client',
  },
  'product.content.hbg_dustcollection.title.text': {
    value: 'Dust Collection',
    location: 'client',
  },
  'product.content.hbg_dustreemisrating.title.text': {
    value: 'Dust Re-Emission Rating',
    location: 'client',
  },
  'product.content.hbg_dustremoverating.title.text': {
    value: 'Dust Removal Rating',
    location: 'client',
  },
  'product.content.hbg_effectsandfinish.title.text': {
    value: 'Effects and Finish',
    location: 'client',
  },
  'product.content.hbg_electricoperated.title.text': {
    value: 'Electrically Operated',
    location: 'client',
  },
  'product.content.hbg_enclratebottoven.title.text': {
    value: 'Energy Class Rating of Bottom Oven',
    location: 'client',
  },
  'product.content.hbg_enclratemainoven.title.text': {
    value: 'Energy Class Rating Of Main Oven',
    location: 'client',
  },
  'product.content.hbg_enclratetopoven.title.text': {
    value: 'Energy Class Rating of Top Oven',
    location: 'client',
  },
  'product.content.hbg_endpanel.title.text': {
    value: 'End Panel',
    location: 'client',
  },
  'product.content.hbg_energyclassrate.title.text': {
    value: 'Energy Class Rating',
    location: 'client',
  },
  'product.content.hbg_estannenergycons.title.text': {
    value: 'Estimated Annual Energy Consumption',
    location: 'client',
  },
  'product.content.hbg_eventualdims.title.text': {
    value: 'Eventual Dimensions',
    location: 'client',
  },
  'product.content.hbg_externaldims.title.text': {
    value: 'External Dimensions (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_extractionrate.title.text': {
    value: 'Extraction Rate',
    location: 'client',
  },
  'product.content.hbg_fastfreezefunct.title.text': {
    value: 'Fast Freeze Function',
    location: 'client',
  },
  'product.content.hbg_fasteningtype.title.text': {
    value: 'Fastening Type',
    location: 'client',
  },
  'product.content.hbg_feedinginstr.title.text': {
    value: 'Feeding Instructions',
    location: 'client',
  },
  'product.content.hbg_fillingmaterial.title.text': {
    value: 'Filling Material',
    location: 'client',
  },
  'product.content.hbg_filterdetails.title.text': {
    value: 'Filter Details',
    location: 'client',
  },
  'product.content.hbg_fireproof.title.text': {
    value: 'Fireproof',
    location: 'client',
  },
  'product.content.hbg_fitsdiametercm.title.text': {
    value: 'Fits Diameter (cm)',
    location: 'client',
  },
  'product.content.hbg_fittinginstr.title.text': {
    value: 'Fitting Instructions',
    location: 'client',
  },
  'product.content.hbg_fittingsystem.title.text': {
    value: 'Fitting System',
    location: 'client',
  },
  'product.content.hbg_fixingsincluded.title.text': {
    value: 'Fixings Included',
    location: 'client',
  },
  'product.content.hbg_floormaterial.title.text': {
    value: 'Building Floor Material',
    location: 'client',
  },
  'product.content.hbg_floorseatheight.title.text': {
    value: 'Floor To Seat Height',
    location: 'client',
  },
  'product.content.hbg_flowrate.title.text': {
    value: 'Flow Rate',
    location: 'client',
  },
  'product.content.hbg_flueoutletsize.title.text': {
    value: 'Flue Outlet Size',
    location: 'client',
  },
  'product.content.hbg_fluetype.title.text': {
    value: 'Flue Type',
    location: 'client',
  },
  'product.content.hbg_foldable.title.text': {
    value: 'Foldable',
    location: 'client',
  },
  'product.content.hbg_foliagecolour.title.text': {
    value: 'Colour Of Foliage',
    location: 'client',
  },
  'product.content.hbg_freestandtapsreq.title.text': {
    value: 'Freestanding Taps Required',
    location: 'client',
  },
  'product.content.hbg_freezersafe.title.text': {
    value: 'Freezer Safe',
    location: 'client',
  },
  'product.content.hbg_freezerstarrate.title.text': {
    value: 'Freezer Star Rating',
    location: 'client',
  },
  'product.content.hbg_freezeresistant.title.text': {
    value: 'Freeze Resistant',
    location: 'client',
  },
  'product.content.hbg_frostfreefunct.title.text': {
    value: 'Frost Free Function',
    location: 'client',
  },
  'product.content.hbg_fueltype.title.text': {
    value: 'Fuel Type',
    location: 'client',
  },
  'product.content.hbg_glassdetails.title.text': {
    value: 'Glass Details',
    location: 'client',
  },
  'product.content.hbg_glazingtype.title.text': {
    value: 'Window Glazing',
    location: 'client',
  },
  'product.content.hbg_glueless.title.text': {
    value: 'Glueless',
    location: 'client',
  },
  'product.content.hbg_grillsafe.title.text': {
    value: 'Grill SafeÂ ',
    location: 'client',
  },
  'product.content.hbg_groundprep.title.text': {
    value: 'Ground Preparation',
    location: 'client',
  },
  'product.content.hbg_guarantee.title.text': {
    value: 'Guarantee',
    location: 'client',
  },
  'product.content.hbg_halogen.title.text': {
    value: 'Halogen',
    location: 'client',
  },
  'product.content.hbg_handledetails.title.text': {
    value: 'Handle Details',
    location: 'client',
  },
  'product.content.hbg_headlength.title.text': {
    value: 'Head Length (cm)',
    location: 'client',
  },
  'product.content.hbg_headmaterial.title.text': {
    value: 'Material Of Head',
    location: 'client',
  },
  'product.content.hbg_heatflamesettsep.title.text': {
    value: 'Heat and Flame Setting Seperate',
    location: 'client',
  },
  'product.content.hbg_heatoutput.title.text': {
    value: 'Heat Output',
    location: 'client',
  },
  'product.content.hbg_heatresistant.title.text': {
    value: 'Heat Resistant',
    location: 'client',
  },
  'product.content.hbg_heatuptime.title.text': {
    value: 'Heat Up Time',
    location: 'client',
  },
  'product.content.hbg_heightextended.title.text': {
    value: 'Height When Extended',
    location: 'client',
  },
  'product.content.hbg_heightofarmrest.title.text': {
    value: 'Height Of Arm Rest',
    location: 'client',
  },
  'product.content.hbg_hepafilter.title.text': {
    value: 'HEPA Filter',
    location: 'client',
  },
  'product.content.hbg_hosedimensions.title.text': {
    value: 'Hose Dimensions',
    location: 'client',
  },
  'product.content.hbg_howmanymodes.title.text': {
    value: 'How Many Modes',
    location: 'client',
  },
  'product.content.hbg_ignitiontype.title.text': {
    value: 'Ignition Type',
    location: 'client',
  },
  'product.content.hbg_includes.title.text': {
    value: 'Product Contains',
    location: 'client',
  },
  'product.content.hbg_inductioncompat.title.text': {
    value: 'Induction Suitable',
    location: 'client',
  },
  'product.content.hbg_industrystandard.title.text': {
    value: 'Industry Standards Certification',
    location: 'client',
  },
  'product.content.hbg_inflationtime.title.text': {
    value: 'Inflation Time',
    location: 'client',
  },
  'product.content.hbg_ingredients.title.text': {
    value: 'Ingredients',
    location: 'client',
  },
  'product.content.hbg_inletconnection.title.text': {
    value: 'Inlet Connection',
    location: 'client',
  },
  'product.content.hbg_inletvalvewidth.title.text': {
    value: 'Inlet Valve Width',
    location: 'client',
  },
  'product.content.hbg_intextuse.title.text': {
    value: 'For Interior Or Exterior',
    location: 'client',
  },
  'product.content.hbg_interiorlight.title.text': {
    value: 'Interior Light',
    location: 'client',
  },
  'product.content.hbg_internaldims.title.text': {
    value: 'Internal Dimensions (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_iprating.title.text': {
    value: 'IP Rating',
    location: 'client',
  },
  'product.content.hbg_jointkit.title.text': {
    value: 'Joint Kit',
    location: 'client',
  },
  'product.content.hbg_kelvins.title.text': {
    value: 'Kelvins',
    location: 'client',
  },
  'product.content.hbg_keypadcontrol.title.text': {
    value: 'Keypad Control',
    location: 'client',
  },
  'product.content.hbg_kitincludes.title.text': {
    value: 'Kit Includes',
    location: 'client',
  },
  'product.content.hbg_kwhrating.title.text': {
    value: 'KWH Rating',
    location: 'client',
  },
  'product.content.hbg_largeshelfsize.title.text': {
    value: 'Large Shelf Size',
    location: 'client',
  },
  'product.content.hbg_latinname.title.text': {
    value: 'Latin Name',
    location: 'client',
  },
  'product.content.hbg_ledcolour.title.text': {
    value: 'LED Colour',
    location: 'client',
  },
  'product.content.hbg_legmatfinish.title.text': {
    value: 'Leg Material and Finish',
    location: 'client',
  },
  'product.content.hbg_length.title.text': {
    value: 'Length',
    location: 'client',
  },
  'product.content.hbg_lengthedgstrip.title.text': {
    value: 'Length Of Edging Strip',
    location: 'client',
  },
  'product.content.hbg_lengthextended.title.text': {
    value: 'Length When Extended',
    location: 'client',
  },
  'product.content.hbg_lengthofblade.title.text': {
    value: 'Length Of Blade',
    location: 'client',
  },
  'product.content.hbg_lengthofcord.title.text': {
    value: 'Length Of Cord',
    location: 'client',
  },
  'product.content.hbg_lengthofdrop.title.text': {
    value: 'Length Of Drop',
    location: 'client',
  },
  'product.content.hbg_lengthofhose.title.text': {
    value: 'Length Of Hose',
    location: 'client',
  },
  'product.content.hbg_lengthoftassel.title.text': {
    value: 'Length Of Tassel',
    location: 'client',
  },
  'product.content.hbg_lifeexpectancy.title.text': {
    value: 'Life Expectancy',
    location: 'client',
  },
  'product.content.hbg_lightcolour.title.text': {
    value: 'Light Colour',
    location: 'client',
  },
  'product.content.hbg_liquepetrolgas.title.text': {
    value: 'Liquefied Petroleum Gas',
    location: 'client',
  },
  'product.content.hbg_loadcapacity.title.text': {
    value: 'Load Capacity',
    location: 'client',
  },
  'product.content.hbg_lockdetails.title.text': {
    value: 'Lock Details',
    location: 'client',
  },
  'product.content.hbg_lockingmechanism.title.text': {
    value: 'Locking Mechanism',
    location: 'client',
  },
  'product.content.hbg_lumens.title.text': {
    value: 'Lumens',
    location: 'client',
  },
  'product.content.hbg_matercushinners.title.text': {
    value: 'Material Of Cushion Inners',
    location: 'client',
  },
  'product.content.hbg_material.title.text': {
    value: 'Material',
    location: 'client',
  },
  'product.content.hbg_materialofblade.title.text': {
    value: 'Blade Material',
    location: 'client',
  },
  'product.content.hbg_materialofcover.title.text': {
    value: 'Material Of Covers',
    location: 'client',
  },
  'product.content.hbg_materialofgrill.title.text': {
    value: 'Material Of Grill',
    location: 'client',
  },
  'product.content.hbg_materialofhandle.title.text': {
    value: 'Handle Material',
    location: 'client',
  },
  'product.content.hbg_materialofshade.title.text': {
    value: 'Material Of Shade',
    location: 'client',
  },
  'product.content.hbg_materialoftape.title.text': {
    value: 'Material Of Tape',
    location: 'client',
  },
  'product.content.hbg_mattressdepth.title.text': {
    value: 'Mattress Depth',
    location: 'client',
  },
  'product.content.hbg_mattressfirmness.title.text': {
    value: 'Mattress Firmness',
    location: 'client',
  },
  'product.content.hbg_maxbarpressure.title.text': {
    value: 'Maximum Bar Pressure',
    location: 'client',
  },
  'product.content.hbg_maxbulbwattage.title.text': {
    value: 'Maximum Bulb Wattage',
    location: 'client',
  },
  'product.content.hbg_maxcutcapmetal.title.text': {
    value: 'Maximum Cutting Capacity For Metal',
    location: 'client',
  },
  'product.content.hbg_maxcutcapwood.title.text': {
    value: 'Maximum Cutting Capacity For Wood',
    location: 'client',
  },
  'product.content.hbg_maxdetectdepth.title.text': {
    value: 'Maximum Detection Depth',
    location: 'client',
  },
  'product.content.hbg_maxlitresperhour.title.text': {
    value: 'Maximum Litres Per Hour',
    location: 'client',
  },
  'product.content.hbg_maxreachheight.title.text': {
    value: 'Maximum Reach Height',
    location: 'client',
  },
  'product.content.hbg_maxtemprange.title.text': {
    value: 'Maximum Temperature Range',
    location: 'client',
  },
  'product.content.hbg_maxtemperature.title.text': {
    value: 'Maximum Temperature (Celsius)',
    location: 'client',
  },
  'product.content.hbg_maxtrimmabledims.title.text': {
    value: 'Maximum Trimmable Dimensions',
    location: 'client',
  },
  'product.content.hbg_maxwaterpressure.title.text': {
    value: 'Maximum Water Pressure',
    location: 'client',
  },
  'product.content.hbg_maxweightsupport.title.text': {
    value: 'Maximum Weight Supported',
    location: 'client',
  },
  'product.content.hbg_maxwidthhatchreq.title.text': {
    value: 'Maximum Width Of Hatch Required',
    location: 'client',
  },
  'product.content.hbg_maxworkingtemp.title.text': {
    value: 'Working Temperature',
    location: 'client',
  },
  'product.content.hbg_maximumspinspeed.title.text': {
    value: 'Maximum Spin Speed',
    location: 'client',
  },
  'product.content.hbg_measuringrange.title.text': {
    value: 'Measurement Range',
    location: 'client',
  },
  'product.content.hbg_mediumshelfsize.title.text': {
    value: 'Medium Shelf Size',
    location: 'client',
  },
  'product.content.hbg_microwavesafe.title.text': {
    value: 'Microwave Safe',
    location: 'client',
  },
  'product.content.hbg_mincoolingtemp.title.text': {
    value: 'Minimum Cooling Temperature',
    location: 'client',
  },
  'product.content.hbg_minfloorlofthei.title.text': {
    value: 'Minimum Floor To Loft Height',
    location: 'client',
  },
  'product.content.hbg_minwaterpressure.title.text': {
    value: 'Minimum Water Pressure',
    location: 'client',
  },
  'product.content.hbg_mixinginfo.title.text': {
    value: 'Mixing Instructions',
    location: 'client',
  },
  'product.content.hbg_modelname.title.text': {
    value: 'Model Name',
    location: 'client',
  },
  'product.content.hbg_modelnumber.title.text': {
    value: 'Model Number',
    location: 'client',
  },
  'product.content.hbg_moistureremoval.title.text': {
    value: 'Moisture Removal',
    location: 'client',
  },
  'product.content.hbg_motion.title.text': {
    value: 'Motion',
    location: 'client',
  },
  'product.content.hbg_mouldresistant.title.text': {
    value: 'Mould Resistant',
    location: 'client',
  },
  'product.content.hbg_musical.title.text': {
    value: 'Musical',
    location: 'client',
  },
  'product.content.hbg_noloadspeed.title.text': {
    value: 'Load Speed',
    location: 'client',
  },
  'product.content.hbg_noturnmattress.title.text': {
    value: 'No Turn Mattress',
    location: 'client',
  },
  'product.content.hbg_noiselevel.title.text': {
    value: 'Noise Level',
    location: 'client',
  },
  'product.content.hbg_nonsuitsurfaces.title.text': {
    value: 'Non-Suitable Surfaces',
    location: 'client',
  },
  'product.content.hbg_nonstick.title.text': {
    value: 'Non-Stick',
    location: 'client',
  },
  'product.content.hbg_numbottlesheld.title.text': {
    value: 'Number Of Bottles Held',
    location: 'client',
  },
  'product.content.hbg_numbulbsrequired.title.text': {
    value: 'Number Of Bulbs Required',
    location: 'client',
  },
  'product.content.hbg_numcompartments.title.text': {
    value: 'Number Of Compartments',
    location: 'client',
  },
  'product.content.hbg_numhangingrails.title.text': {
    value: 'Number Of Hanging Rails',
    location: 'client',
  },
  'product.content.hbg_numheatsettings.title.text': {
    value: 'Number Of Heat Settings',
    location: 'client',
  },
  'product.content.hbg_numlinearmetre.title.text': {
    value: 'Number Per Linear Metre',
    location: 'client',
  },
  'product.content.hbg_numPeoplecookfor.title.text': {
    value: 'Number Of People Cooks For',
    location: 'client',
  },
  'product.content.hbg_numpeoplesuitfor.title.text': {
    value: 'Maximum OccupancyÂ ',
    location: 'client',
  },
  'product.content.hbg_numpirsensorsinc.title.text': {
    value: 'Number Of PIR Sensors Fitted',
    location: 'client',
  },
  'product.content.hbg_numplanksperpack.title.text': {
    value: 'Number Of Planks Per Pack',
    location: 'client',
  },
  'product.content.hbg_numprogbotoven.title.text': {
    value: 'Number Of Programmes For Bottom Oven',
    location: 'client',
  },
  'product.content.hbg_numprogtopoven.title.text': {
    value: 'Number Of Programmes For Top Oven',
    location: 'client',
  },
  'product.content.hbg_numspraypatterns.title.text': {
    value: 'Number Of Spray Patterns',
    location: 'client',
  },
  'product.content.hbg_numberbulbsfit.title.text': {
    value: 'Number Of Bulbs Fitted',
    location: 'client',
  },
  'product.content.hbg_numbercoatsrec.title.text': {
    value: 'Number Of Coats Recommended',
    location: 'client',
  },
  'product.content.hbg_numberlightsrec.title.text': {
    value: 'Number Of Lights Recommended',
    location: 'client',
  },
  'product.content.hbg_numberofbars.title.text': {
    value: 'Number Of Bars',
    location: 'client',
  },
  'product.content.hbg_numberofburners.title.text': {
    value: 'Number Of Burners',
    location: 'client',
  },
  'product.content.hbg_numberofdoors.title.text': {
    value: 'Number Of Doors',
    location: 'client',
  },
  'product.content.hbg_numberofdrawers.title.text': {
    value: 'Number Of Drawers',
    location: 'client',
  },
  'product.content.hbg_numberofgangs.title.text': {
    value: 'Number Of Gangs',
    location: 'client',
  },
  'product.content.hbg_numberofgears.title.text': {
    value: 'Number Of Gears',
    location: 'client',
  },
  'product.content.hbg_numberofjets.title.text': {
    value: 'Number Of Jets',
    location: 'client',
  },
  'product.content.hbg_numberoflights.title.text': {
    value: 'Number Of Lights',
    location: 'client',
  },
  'product.content.hbg_numberofpieces.title.text': {
    value: 'Number Of Pieces',
    location: 'client',
  },
  'product.content.hbg_numberofpumps.title.text': {
    value: 'Number Of Pumps',
    location: 'client',
  },
  'product.content.hbg_numberofrungs.title.text': {
    value: 'Number Of Rungs',
    location: 'client',
  },
  'product.content.hbg_numberofseats.title.text': {
    value: 'Number Of Seats',
    location: 'client',
  },
  'product.content.hbg_numberofshelves.title.text': {
    value: 'Number Of Shelves',
    location: 'client',
  },
  'product.content.hbg_numberofsockets.title.text': {
    value: 'Number Of Sockets',
    location: 'client',
  },
  'product.content.hbg_numberofsprings.title.text': {
    value: 'Number Of Springs',
    location: 'client',
  },
  'product.content.hbg_numberoftapholes.title.text': {
    value: 'Number of Tap Holes',
    location: 'client',
  },
  'product.content.hbg_numberofwheels.title.text': {
    value: 'Number Of Wheels',
    location: 'client',
  },
  'product.content.hbg_numberofwindows.title.text': {
    value: 'Number Of Windows',
    location: 'client',
  },
  'product.content.hbg_numberprogrammes.title.text': {
    value: 'Number Of Programmes',
    location: 'client',
  },
  'product.content.hbg_nutrcontkval.title.text': {
    value: 'Nutrient Content K Value',
    location: 'client',
  },
  'product.content.hbg_nutrcontnval.title.text': {
    value: 'Nutrient Content N Value',
    location: 'client',
  },
  'product.content.hbg_nutrcontotherval.title.text': {
    value: 'Nutrient Content Other Value',
    location: 'client',
  },
  'product.content.hbg_oilfilled.title.text': {
    value: 'Oil Filled',
    location: 'client',
  },
  'product.content.hbg_operationalrange.title.text': {
    value: 'Operational Range',
    location: 'client',
  },
  'product.content.hbg_oscompdetails.title.text': {
    value: 'O S Compatibility Details',
    location: 'client',
  },
  'product.content.hbg_outletconnection.title.text': {
    value: 'Outlet Connection',
    location: 'client',
  },
  'product.content.hbg_output.title.text': {
    value: 'Output',
    location: 'client',
  },
  'product.content.hbg_ovenlight.title.text': {
    value: 'Oven Light',
    location: 'client',
  },
  'product.content.hbg_ovensafe.title.text': {
    value: 'Oven Safe',
    location: 'client',
  },
  'product.content.hbg_oventimer.title.text': {
    value: 'Oven Timer',
    location: 'client',
  },
  'product.content.hbg_overridefacility.title.text': {
    value: 'Override Facility',
    location: 'client',
  },
  'product.content.hbg_ozonatorincluded.title.text': {
    value: 'Ozonator Included',
    location: 'client',
  },
  'product.content.hbg_pansupports.title.text': {
    value: 'Pan Supports',
    location: 'client',
  },
  'product.content.hbg_parasolholediam.title.text': {
    value: 'Parasol Hole Diameter',
    location: 'client',
  },
  'product.content.hbg_parasolincluded.title.text': {
    value: 'Parasol Included',
    location: 'client',
  },
  'product.content.hbg_parasolpolediam.title.text': {
    value: 'Parasol Pole Diameter',
    location: 'client',
  },
  'product.content.hbg_patternrepeat.title.text': {
    value: 'Pattern Repeat',
    location: 'client',
  },
  'product.content.hbg_perennial.title.text': {
    value: 'Annual or Perennial',
    location: 'client',
  },
  'product.content.hbg_pillowcasestyle.title.text': {
    value: 'Pillowcase Style',
    location: 'client',
  },
  'product.content.hbg_plantinginfo.title.text': {
    value: 'Planting Instructions',
    location: 'client',
  },
  'product.content.hbg_plantingposition.title.text': {
    value: 'Planting Position',
    location: 'client',
  },
  'product.content.hbg_plinth.title.text': {
    value: 'Plinth',
    location: 'client',
  },
  'product.content.hbg_plugsockettype.title.text': {
    value: 'Plug and Socket Type',
    location: 'client',
  },
  'product.content.hbg_plumbingsystem.title.text': {
    value: 'Plumbing System',
    location: 'client',
  },
  'product.content.hbg_portability.title.text': {
    value: 'PortabilityÂ ',
    location: 'client',
  },
  'product.content.hbg_potsize.title.text': {
    value: 'Pot Dimensions (H)x(W)x(D)',
    location: 'client',
  },
  'product.content.hbg_powerlevels.title.text': {
    value: 'Power Levels',
    location: 'client',
  },
  'product.content.hbg_powersource.title.text': {
    value: 'Power Source',
    location: 'client',
  },
  'product.content.hbg_prelit.title.text': {
    value: 'Prelit',
    location: 'client',
  },
  'product.content.hbg_pumpdetails.title.text': {
    value: 'Pump Details',
    location: 'client',
  },
  'product.content.hbg_quantityinpack.title.text': {
    value: 'Quantity In Pack',
    location: 'client',
  },
  'product.content.hbg_quickdryfunction.title.text': {
    value: 'Quick Dry Function',
    location: 'client',
  },
  'product.content.hbg_quickwashtime.title.text': {
    value: 'Quick Wash Time',
    location: 'client',
  },
  'product.content.hbg_readymixed.title.text': {
    value: 'Ready Mixed',
    location: 'client',
  },
  'product.content.hbg_recaltbulb.title.text': {
    value: 'Recommended Alternative Bulb',
    location: 'client',
  },
  'product.content.hbg_recessrequired.title.text': {
    value: 'Recess Required',
    location: 'client',
  },
  'product.content.hbg_rechargeable.title.text': {
    value: 'Rechargeable',
    location: 'client',
  },
  'product.content.hbg_remotecontrol.title.text': {
    value: 'Remote Control',
    location: 'client',
  },
  'product.content.hbg_removingmethod.title.text': {
    value: 'Removing Method',
    location: 'client',
  },
  'product.content.hbg_requiresgrout.title.text': {
    value: 'Requires Grout',
    location: 'client',
  },
  'product.content.hbg_residualheatind.title.text': {
    value: 'Residual Heat Indicator',
    location: 'client',
  },
  'product.content.hbg_reversibledoor.title.text': {
    value: 'Reversible Door',
    location: 'client',
  },
  'product.content.hbg_rhsawargardmerit.title.text': {
    value: 'RHS Award or Merited',
    location: 'client',
  },
  'product.content.hbg_roofcovering.title.text': {
    value: 'Building Roof Covering',
    location: 'client',
  },
  'product.content.hbg_roofstyle.title.text': {
    value: 'Roof Style',
    location: 'client',
  },
  'product.content.hbg_safecutoutsystem.title.text': {
    value: 'Safety Cut Out System',
    location: 'client',
  },
  'product.content.hbg_safetyinstr.title.text': {
    value: 'Safety Information',
    location: 'client',
  },
  'product.content.hbg_samplecode.title.text': {
    value: 'Sample Homebase Code',
    location: 'client',
  },
  'product.content.hbg_sandertype.title.text': {
    value: 'Sander Sheet Type',
    location: 'client',
  },
  'product.content.hbg_scent.title.text': {
    value: 'Scent',
    location: 'client',
  },
  'product.content.hbg_scratchresistant.title.text': {
    value: 'Scratch Resistant',
    location: 'client',
  },
  'product.content.hbg_screwdrivertype.title.text': {
    value: 'Screwdriver Type',
    location: 'client',
  },
  'product.content.hbg_securitylevel.title.text': {
    value: 'Security Level',
    location: 'client',
  },
  'product.content.hbg_selfadhesive.title.text': {
    value: 'Self Adhesive',
    location: 'client',
  },
  'product.content.hbg_selfcleaning.title.text': {
    value: 'Self Cleaning',
    location: 'client',
  },
  'product.content.hbg_sensortype.title.text': {
    value: 'Type Of Sensor',
    location: 'client',
  },
  'product.content.hbg_separategrill.title.text': {
    value: 'Seperate Grill',
    location: 'client',
  },
  'product.content.hbg_setincludes.title.text': {
    value: 'Set Includes',
    location: 'client',
  },
  'product.content.hbg_settingtime.title.text': {
    value: 'Setting Time',
    location: 'client',
  },
  'product.content.hbg_shadecolour.title.text': {
    value: 'Shade Colour',
    location: 'client',
  },
  'product.content.hbg_shapeofbulb.title.text': {
    value: 'Shape Of Bulb',
    location: 'client',
  },
  'product.content.hbg_shatterproof.title.text': {
    value: 'Shatterproof',
    location: 'client',
  },
  'product.content.hbg_showerdetails.title.text': {
    value: 'Shower Details',
    location: 'client',
  },
  'product.content.hbg_showertrayincl.title.text': {
    value: 'Shower Tray Included',
    location: 'client',
  },
  'product.content.hbg_showertraydims.title.text': {
    value: 'Shower Tray Dimensions',
    location: 'client',
  },
  'product.content.hbg_showerwasteincl.title.text': {
    value: 'Shower Waste Included',
    location: 'client',
  },
  'product.content.hbg_sirennoiselevel.title.text': {
    value: 'Siren Noise Level',
    location: 'client',
  },
  'product.content.hbg_sirennoiserange.title.text': {
    value: 'Siren Noise Range',
    location: 'client',
  },
  'product.content.hbg_sizecutoutreq.title.text': {
    value: 'Size Of Cut Out Required',
    location: 'client',
  },
  'product.content.hbg_sizeinternaldoor.title.text': {
    value: 'Size Of Internal Door',
    location: 'client',
  },
  'product.content.hbg_slatsdetails.title.text': {
    value: 'Slat Details',
    location: 'client',
  },
  'product.content.hbg_slideincluded.title.text': {
    value: 'Slide Included',
    location: 'client',
  },
  'product.content.hbg_slidelength.title.text': {
    value: 'Dimensions Of Slide',
    location: 'client',
  },
  'product.content.hbg_smallshelfsize.title.text': {
    value: 'Small Shelf Size',
    location: 'client',
  },
  'product.content.hbg_softgriphandle.title.text': {
    value: 'Soft Grip Handle',
    location: 'client',
  },
  'product.content.hbg_soiltype.title.text': {
    value: 'Soil Type',
    location: 'client',
  },
  'product.content.hbg_species.title.text': {
    value: 'Species',
    location: 'client',
  },
  'product.content.hbg_speedvariables.title.text': {
    value: 'Speed Variables',
    location: 'client',
  },
  'product.content.hbg_spinperfrating.title.text': {
    value: 'Spinning Performance Rating',
    location: 'client',
  },
  'product.content.hbg_stackable.title.text': {
    value: 'Stackable Chairs',
    location: 'client',
  },
  'product.content.hbg_stanbathpanelsup.title.text': {
    value: 'Standard Bath Panel Supplied',
    location: 'client',
  },
  'product.content.hbg_steamboost.title.text': {
    value: 'Steam Boost',
    location: 'client',
  },
  'product.content.hbg_steamoutput.title.text': {
    value: 'Steam Output',
    location: 'client',
  },
  'product.content.hbg_steamreadytime.title.text': {
    value: 'Steam Ready Time',
    location: 'client',
  },
  'product.content.hbg_stepsincluded.title.text': {
    value: 'Steps Included',
    location: 'client',
  },
  'product.content.hbg_stereoincluded.title.text': {
    value: 'Stereo Included',
    location: 'client',
  },
  'product.content.hbg_storageinfo.title.text': {
    value: 'Storage',
    location: 'client',
  },
  'product.content.hbg_storageinstruct.title.text': {
    value: 'Storage Instructions',
    location: 'client',
  },
  'product.content.hbg_style.title.text': {
    value: 'Style',
    location: 'client',
  },
  'product.content.hbg_styledoorhandle.title.text': {
    value: 'Style Of Door Handle',
    location: 'client',
  },
  'product.content.hbg_styleofbath.title.text': {
    value: 'Style Of Bath',
    location: 'client',
  },
  'product.content.hbg_styleoffinial.title.text': {
    value: 'Style Of Finial',
    location: 'client',
  },
  'product.content.hbg_styleofpaving.title.text': {
    value: 'Style Of Paving',
    location: 'client',
  },
  'product.content.hbg_suitbathrooms.title.text': {
    value: 'Rooms Suitable For',
    location: 'client',
  },
  'product.content.hbg_suitfloortype.title.text': {
    value: 'Suitable For Surfaces',
    location: 'client',
  },
  'product.content.hbg_suitablecover.title.text': {
    value: 'Suitable Cover',
    location: 'client',
  },
  'product.content.hbg_suitablefor.title.text': {
    value: 'Suitable For',
    location: 'client',
  },
  'product.content.hbg_suitableparasol.title.text': {
    value: 'Suitable Parasol',
    location: 'client',
  },
  'product.content.hbg_suitableroomsize.title.text': {
    value: 'Suitable For Room Size',
    location: 'client',
  },
  'product.content.hbg_tiledepth.title.text': {
    value: 'Tile Depth',
    location: 'client',
  },
  'product.content.hbg_weightkg.title.text': {
    value: 'Weight Of Product (kg)',
    location: 'client',
  },

  'product.content.hbg_2waydiverter.title.text': {
    value: '2 Way Diverter',
    location: 'client',
  },
  'product.content.hbg_adjrange.title.text': {
    value: 'Adjustment Range (mm)',
    location: 'client',
  },
  'product.content.hbg_adjustablefeet.title.text': {
    value: 'Adjustable Feet',
    location: 'client',
  },
  'product.content.hbg_adjustableshelves.title.text': {
    value: 'Adjustable Shelves',
    location: 'client',
  },
  'product.content.hbg_aeratorinc.title.text': {
    value: 'Aerator Included',
    location: 'client',
  },
  'product.content.hbg_antibactprotection.title.text': {
    value: 'Anti Bacterial Protection',
    location: 'client',
  },
  'product.content.hbg_autosafetyshutoff.title.text': {
    value: 'Automatic Safety Shut Off',
    location: 'client',
  },
  'product.content.hbg_averagebattruntime.title.text': {
    value: 'Average Battery Run Time',
    location: 'client',
  },
  'product.content.hbg_audibleendofprogramme.title.text': {
    value: 'Audible End of Programme',
    location: 'client',
  },
  'product.content.hbg_allyearrounduse.title.text': {
    value: 'All Year Round Use',
    location: 'client',
  },
  'product.content.hbg_base.title.text': {
    value: 'Base',
    location: 'client',
  },
  'product.content.hbg_basintype.title.text': {
    value: 'Basin Type',
    location: 'client',
  },
  'product.content.hbg_bathfeet.title.text': {
    value: 'Bath Feet',
    location: 'client',
  },
  'product.content.hbg_baththickness.title.text': {
    value: 'Bath Thickness (mm)',
    location: 'client',
  },
  'product.content.hbg_brushbristlematerial.title.text': {
    value: 'Brush/Bristle Material',
    location: 'client',
  },
  'product.content.hbg_brushfirmness.title.text': {
    value: 'Brush Firmness',
    location: 'client',
  },
  'product.content.hbg_brushheadmaterial.title.text': {
    value: 'Brush Head Material',
    location: 'client',
  },
  'product.content.hbg_btuoutput.title.text': {
    value: 'Btu Output',
    location: 'client',
  },
  'product.content.hbg_builtinheatingdesign.title.text': {
    value: 'Built-In Heating Design',
    location: 'client',
  },
  'product.content.hbg_cablelength.title.text': {
    value: 'Cable Length',
    location: 'client',
  },
  'product.content.hbg_canopycolour.title.text': {
    value: 'Canopy Colour',
    location: 'client',
  },
  'product.content.hbg_capacityinltr.title.text': {
    value: 'Capacity (ltr)',
    location: 'client',
  },
  'product.content.hbg_carrybagincluded.title.text': {
    value: 'Carry Bag Included',
    location: 'client',
  },
  'product.content.hbg_connectiontype.title.text': {
    value: 'Connection Type',
    location: 'client',
  },
  'product.content.hbg_controlpanellock.title.text': {
    value: 'Control Panel Lock',
    location: 'client',
  },
  'product.content.hbg_compsoilpipe.title.text': {
    value: 'Compatible Soil Pipe',
    location: 'client',
  },
  'product.content.hbg_controlpanelposition.title.text': {
    value: 'Control Panel Position',
    location: 'client',
  },
  'product.content.hbg_controltype.title.text': {
    value: 'Control Type',
    location: 'client',
  },
  'product.content.hbg_concealedcistern.title.text': {
    value: 'Concealed Cistern Required',
    location: 'client',
  },
  'product.content.hbg_cycleduration.title.text': {
    value: 'Cycle Duration',
    location: 'client',
  },
  'product.content.hbg_delaytimer.title.text': {
    value: 'Delay Timer',
    location: 'client',
  },
  'product.content.hbg_demistorinc.title.text': {
    value: 'Demistor Included',
    location: 'client',
  },
  'product.content.hbg_depthinmm.title.text': {
    value: 'Depth (mm)',
    location: 'client',
  },
  'product.content.hbg_doortype.title.text': {
    value: 'Door Type',
    location: 'client',
  },
  'product.content.hbg_draws.title.text': {
    value: 'Drawers',
    location: 'client',
  },
  'product.content.hbg_drainagehole.title.text': {
    value: 'Drainage Hole',
    location: 'client',
  },
  'product.content.hbg_dualflush.title.text': {
    value: 'Dual Flush',
    location: 'client',
  },
  'product.content.hbg_easyshapebranches.title.text': {
    value: 'Easy Shape Branches',
    location: 'client',
  },
  'product.content.hbg_energyrating.title.text': {
    value: 'Energy Rating',
    location: 'client',
  },
  'product.content.hbg_energylabelprimary.title.text': {
    value: 'Energy Label Primary',
    location: 'client',
  },
  'product.content.hbg_englishname.title.text': {
    value: 'English Name',
    location: 'client',
  },
  'product.content.hbg_fanefficiencyclass.title.text': {
    value: 'Fan Efficiency Class',
    location: 'client',
  },
  'product.content.hbg_ferrulematerial.title.text': {
    value: 'Ferrule Material',
    location: 'client',
  },
  'product.content.hbg_familyname.title.text': {
    value: 'Family Name',
    location: 'client',
  },
  'product.content.hbg_filterfullindicator.title.text': {
    value: 'Filter Full Indicator',
    location: 'client',
  },
  'product.content.hbg_fixingboltsinc.title.text': {
    value: 'Fixing Bolts Included',
    location: 'client',
  },
  'product.content.hbg_finialdimensions.title.text': {
    value: 'Finial Dimensions',
    location: 'client',
  },
  'product.content.hbg_flowerorharvestmonths.title.text': {
    value: 'Flowering or Harvesting Months',
    location: 'client',
  },
  'product.content.hbg_genus.title.text': {
    value: 'Genus',
    location: 'client',
  },
  'product.content.hbg_grillpandriptrayinc.title.text': {
    value: 'Grill Pan / Drip Tray Included',
    location: 'client',
  },
  'product.content.hbg_greasefilteringclass.title.text': {
    value: 'Grease Filtering Class',
    location: 'client',
  },
  'product.content.hbg_grownheight.title.text': {
    value: 'Grown Height',
    location: 'client',
  },
  'product.content.hbg_handleinc.title.text': {
    value: 'Handle Included',
    location: 'client',
  },
  'product.content.hbg_hardiness.title.text': {
    value: 'Hardiness',
    location: 'client',
  },
  'product.content.hbg_heatoutputinws.title.text': {
    value: 'Heat Output (Watts)',
    location: 'client',
  },
  'product.content.hbg_heightinmm.title.text': {
    value: 'Height (mm)',
    location: 'client',
  },
  'product.content.hbg_hingeposition.title.text': {
    value: 'Hinge Position',
    location: 'client',
  },
  'product.content.hbg_instructionsinc.title.text': {
    value: 'Instructions Included',
    location: 'client',
  },
  'product.content.hbg_knockoutposition.title.text': {
    value: 'Knockout Position',
    location: 'client',
  },
  'product.content.hbg_knockoutsize.title.text': {
    value: 'Knockout Size',
    location: 'client',
  },
  'product.content.hbg_ledindicator.title.text': {
    value: 'LED Indicator',
    location: 'client',
  },
  'product.content.hbg_lengthinmm.title.text': {
    value: 'Length (mm)',
    location: 'client',
  },
  'product.content.hbg_lifecycle.title.text': {
    value: 'Lifecycle',
    location: 'client',
  },
  'product.content.hbg_lightingincluded.title.text': {
    value: 'Lighting Included',
    location: 'client',
  },
  'product.content.hbg_lightingconditions.title.text': {
    value: 'Lighting Conditions',
    location: 'client',
  },
  'product.content.hbg_matchscotiaavailable.title.text': {
    value: 'Matching Scotia Avaliable',
    location: 'client',
  },
  'product.content.hbg_moisture.title.text': {
    value: 'Moisture',
    location: 'client',
  },
  'product.content.hbg_mountingtype.title.text': {
    value: 'Mounting Type',
    location: 'client',
  },
  'product.content.hbg_noiselevelspin.title.text': {
    value: 'Noise Level (Spin)',
    location: 'client',
  },
  'product.content.hbg_noiselevelwash.title.text': {
    value: 'Noise Level (Wash)',
    location: 'client',
  },
  'product.content.hbg_numberofknockouts.title.text': {
    value: 'Number of Knockouts',
    location: 'client',
  },
  'product.content.hbg_numberofports.title.text': {
    value: 'Number of Ports',
    location: 'client',
  },
  'product.content.hbg_numberoffilters.title.text': {
    value: 'Number of Filters',
    location: 'client',
  },
  'product.content.hbg_othermaterials.title.text': {
    value: 'Other Materials',
    location: 'client',
  },
  'product.content.hbg_overflowinc.title.text': {
    value: 'Overflow Incorporated',
    location: 'client',
  },
  'product.content.hbg_overloadoffaultprot.title.text': {
    value: 'Overload of Fault Protection',
    location: 'client',
  },
  'product.content.hbg_packagingdimensions.title.text': {
    value: 'Packaging Dimensions',
    location: 'client',
  },
  'product.content.hbg_paintcompatibility.title.text': {
    value: 'Paint Compatibility',
    location: 'client',
  },
  'product.content.hbg_pegsincluded.title.text': {
    value: 'Pegs Included',
    location: 'client',
  },
  'product.content.hbg_polelength.title.text': {
    value: 'Pole Length',
    location: 'client',
  },
  'product.content.hbg_polediameter.title.text': {
    value: 'Pole Diameter',
    location: 'client',
  },
  'product.content.hbg_poweroutput.title.text': {
    value: 'Power Output (KW)',
    location: 'client',
  },
  'product.content.hbg_poweroutput2.title.text': {
    value: 'Power Output',
    location: 'client',
  },
  'product.content.hbg_predrilledholes.title.text': {
    value: 'Pre Drilled Tap Holes',
    location: 'client',
  },
  'product.content.hbg_propulsiontype.title.text': {
    value: 'Propulsion Type',
    location: 'client',
  },
  'product.content.hbg_pumpinc.title.text': {
    value: 'Pump Included',
    location: 'client',
  },
  'product.content.hbg_quickrelease.title.text': {
    value: 'Quick Release',
    location: 'client',
  },
  'product.content.hbg_quickset.title.text': {
    value: 'Quickset',
    location: 'client',
  },
  'product.content.hbg_requiredroomtemp.title.text': {
    value: 'Required Room Temperature',
    location: 'client',
  },
  'product.content.hbg_resistanttype.title.text': {
    value: 'Resistant Type',
    location: 'client',
  },
  'product.content.hbg_rollercoresize.title.text': {
    value: 'Roller Core Size',
    location: 'client',
  },
  'product.content.hbg_sampleavailable.title.text': {
    value: 'Sample Available',
    location: 'client',
  },
  'product.content.hbg_scrnthickness.title.text': {
    value: 'Screen Thickness (mm)',
    location: 'client',
  },
  'product.content.hbg_seatinc.title.text': {
    value: 'Seat Included',
    location: 'client',
  },
  'product.content.hbg_shape.title.text': {
    value: 'Shape',
    location: 'client',
  },
  'product.content.hbg_shanksize.title.text': {
    value: 'Shank Size',
    location: 'client',
  },
  'product.content.hbg_showerarmlen.title.text': {
    value: 'Shower Arm Length (mm)',
    location: 'client',
  },
  'product.content.hbg_showerarmtype.title.text': {
    value: 'Shower Arm Type',
    location: 'client',
  },
  'product.content.hbg_showerfunc.title.text': {
    value: 'Shower Functions',
    location: 'client',
  },
  'product.content.hbg_showerhddiam.title.text': {
    value: 'Shower Head Diameter (mm)',
    location: 'client',
  },
  'product.content.hbg_showerhoseinc.title.text': {
    value: 'Shower Hose Included',
    location: 'client',
  },
  'product.content.hbg_showerscreeninc.title.text': {
    value: 'Shower Screen Included',
    location: 'client',
  },
  'product.content.hbg_showertrayinc.title.text': {
    value: 'Shower Tray Included',
    location: 'client',
  },
  'product.content.hbg_softclsseat.title.text': {
    value: 'Soft Close Seat',
    location: 'client',
  },
  'product.content.hbg_standbypowercons.title.text': {
    value: 'Standby Power Consumption',
    location: 'client',
  },
  'product.content.hbg_suitableforzone.title.text': {
    value: 'Suitable For Zone',
    location: 'client',
  },
  'product.content.hbg_tapsinc.title.text': {
    value: 'Taps Included',
    location: 'client',
  },
  'product.content.hbg_temperedglass.title.text': {
    value: 'Tempered Glass',
    location: 'client',
  },
  'product.content.hbg_tempwarninglight.title.text': {
    value: 'Temperature Warning Light',
    location: 'client',
  },
  'product.content.hbg_tilefinish.title.text': {
    value: 'Tile Finish',
    location: 'client',
  },
  'product.content.hbg_tilesperbox.title.text': {
    value: 'Tiles Per Box',
    location: 'client',
  },
  'product.content.hbg_timernew.title.text': {
    value: 'Timer',
    location: 'client',
  },
  'product.content.hbg_timetoendofprogramme.title.text': {
    value: 'Time to End of Programme',
    location: 'client',
  },
  'product.content.hbg_tvremoteinc.title.text': {
    value: 'TV Remote Control Included',
    location: 'client',
  },
  'product.content.hbg_typecontent.title.text': {
    value: 'Type',
    location: 'client',
  },
  'product.content.hbg_ultplantdimension.title.text': {
    value: 'Ultimate Plant Dimension',
    location: 'client',
  },
  'product.content.hbg_usewithpedestal.title.text': {
    value: 'Can Be Used With Pedestal',
    location: 'client',
  },
  'product.content.hbg_valveangle.title.text': {
    value: 'Valve Angle',
    location: 'client',
  },
  'product.content.hbg_valvesinc.title.text': {
    value: 'Valves Included',
    location: 'client',
  },
  'product.content.hbg_ventingtype.title.text': {
    value: 'Venting Type',
    location: 'client',
  },
  'product.content.hbg_whentosow.title.text': {
    value: 'When to Sow',
    location: 'client',
  },
  'product.content.hbg_wasteinc.title.text': {
    value: 'Waste Included',
    location: 'client',
  },
  'product.content.hbg_watercap.title.text': {
    value: 'Water Capacity (ltr)',
    location: 'client',
  },
  'product.content.hbg_weatherresistant.title.text': {
    value: 'Weather Resistant ',
    location: 'client',
  },
  'product.content.hbg_widthinmm.title.text': {
    value: 'Width (mm)',
    location: 'client',
  },
  'product.content.hbg_wideopeningdoor.title.text': {
    value: 'Wide Opening Door',
    location: 'client',
  },
  'product.content.hbg_workingdiameter.title.text': {
    value: 'Working Diameter',
    location: 'client',
  },
  'product.content.hbg_wiringbattchargerinc.title.text': {
    value: 'Wiring Battery and Charger Included',
    location: 'client',
  },
  'product.content.hbg_installguide.title.text': {
    value: 'Installation Guide',
    location: 'client',
  },
  'product.content.hbg_productmanual.title.text': {
    value: 'Product Manual',
    location: 'client',
  },
  'product.content.hbg_productwarranty.title.text': {
    value: 'Product Warranty',
    location: 'client',
  },
  'product.content.hbg_userguide.title.text': {
    value: 'User Guide',
    location: 'client',
  },
  'product.content.hbg_techdrawing.title.text': {
    value: 'Technical Drawing',
    location: 'client',
  },
  'product.content.hbg_featandbenefits.title.text': {
    value: 'Features and Benefits',
    location: 'client',
  },
  'product.content.hbg_productpdf.title.text': {
    value: 'HB Product PDF',
    location: 'client',
  },
  'product.content.hbg_heatingtype.title.text': {
    value: 'Heating Type',
    location: 'client',
  },
  'product.content.hbg_numberofphases.title.text': {
    value: 'Number of Phases',
    location: 'client',
  },
  'product.content.ws_standardMeasurementsdi.title.text': {
    value: 'Characteristics',
    location: 'client',
  },
  'product.content.jaypore_category.title.text': {
    value: 'Category',
    location: 'client',
  },
  'product.content.jaypore_washCare.title.text': {
    value: 'Wash Care',
    location: 'client',
  },
  'product.content.jaypore_dispatchdays.title.text': {
    value: 'Dispatch Days',
    location: 'client',
  },
  'product.content.jaypore_productmaterial.title.text': {
    value: 'Product Material',
    location: 'client',
  },
  'product.content.jaypore_topmaterial.title.text': {
    value: 'Top Material',
    location: 'client',
  },
  'product.content.jaypore_bottommaterial.title.text': {
    value: 'Bottom Material',
    location: 'client',
  },
  'product.content.jaypore_collection.title.text': {
    value: 'Collection',
    location: 'client',
  },
  'product.content.jaypore_occasion.title.text': {
    value: 'Occasion',
    location: 'client',
  },
  'product.content.jaypore_fit.title.text': {
    value: 'Fit',
    location: 'client',
  },
  'product.content.jaypore_pattern.title.text': {
    value: 'Pattern',
    location: 'client',
  },
  'product.content.jaypore_sleeveLength.title.text': {
    value: 'sleeveLength',
    location: 'client',
  },
  'product.content.jaypore_collar.title.text': {
    value: 'Collar',
    location: 'client',
  },
  'product.content.jaypore_length.title.text': {
    value: 'length',
    location: 'client',
  },
  'product.content.jaypore_style.title.text': {
    value: 'Style',
    location: 'client',
  },
  'product.content.jaypore_neck.title.text': {
    value: 'Neck',
    location: 'client',
  },
  'product.content.jaypore_closure.title.text': {
    value: 'Closure',
    location: 'client',
  },
  'product.content.jaypore_closuretype.title.text': {
    value: 'Closure Type',
    location: 'client',
  },
  'product.content.jaypore_craft.title.text': {
    value: 'Craft',
    location: 'client',
  },
  'product.content.jaypore_design.title.text': {
    value: 'Design',
    location: 'client',
  },
  'product.content.jaypore_dishwashersafe.title.text': {
    value: 'Dish Washer Safe',
    location: 'client',
  },
  'product.content.jaypore_dupatta.title.text': {
    value: 'Dupatta',
    location: 'client',
  },
  'product.content.jaypore_lining.title.text': {
    value: 'Lining',
    location: 'client',
  },
  'product.content.jaypore_ornamentation.title.text': {
    value: 'Ornamentation',
    location: 'client',
  },
  'product.content.jaypore_region.title.text': {
    value: 'Region',
    location: 'client',
  },
  'product.content.jaypore_set.title.text': {
    value: 'Set',
    location: 'client',
  },
  'product.content.jaypore_stoneType.title.text': {
    value: 'Stone Type',
    location: 'client',
  },
  'product.content.jaypore_tone.title.text': {
    value: 'Tone',
    location: 'client',
  },
  'product.content.jaypore_type.title.text': {
    value: 'Type',
    location: 'client',
  },
  'product.content.jaypore_size.title.text': {
    value: 'Size',
    location: 'client',
  },
  'product.loyalty.points.text': {
    value: 'Earn %s Status Points',
    location: 'client',
  },
  'product.loyalty.pointstonexttier': {
    value: "You're %s points away from next tier.",
    location: 'client',
  },
  'general.breadcrumbs.home.text': {
    value: 'Home',
    location: 'client',
  },
  'general.bestsellers.title.text': {
    value: 'Best Sellers',
    location: 'client',
  },
  'product.list.facets.refine.text': {
    value: 'Refine',
    location: 'client',
  },
  'product.list.facets.editrefine.text': {
    value: 'Edit Refinement',
    location: 'client',
  },
  'product.list.facets.clear.text': {
    value: 'Clear',
    location: 'client',
  },
  'product.list.facets.clearall.text': {
    value: 'Clear all',
    location: 'client',
  },
  'product.list.facets.close.text': {
    value: 'Close',
    location: 'client',
  },
  'product.list.facets.removefilter.text': {
    value: 'Remove filter',
    location: 'client',
  },
  'product.list.facets.currentfilters.text': {
    value: 'Current filters',
    location: 'client',
  },
  'product.list.facets.submit.text': {
    value: 'Save and view',
    location: 'client',
  },
  'product.list.facets.searchbrand.label': {
    value: 'Search brand',
    location: 'client',
  },
  'product.list.facets.searchbrand.placeholder': {
    value: 'Search brand',
    location: 'client',
  },
  'product.list.facets.expandfilter.text': {
    value: 'Filter',
    location: 'client',
  },
  'product.outofstocknotification.gdprdisclaimer': {
    value:
      'Join to receive our marketing communications and get priority access, hear about new cult products and exclusive offers.',
    location: 'client',
  },
  'product.outofstocknotification.success.legend.text': {
    value: "We'll send you an email if the product comes back in stock",
    location: 'client',
  },
  'product.outofstocknotification.alert.email.required': {
    value: 'Email Address is a required field',
    location: 'client',
  },
  'product.outofstocknotification.alert.checkbox.required': {
    value: 'Marketing Preferences is a required field',
    location: 'client',
  },
  'product.outofstocknotification.main.text': {
    value: 'Email me when back in stock',
    location: 'client',
  },
  'product.outofstocknotification.alert.email.invalid': {
    value: 'Email Address must be a valid email',
    location: 'client',
  },
  'product.outofstocknotification.result.failure': {
    value:
      'Sorry something has gone wrong, please refresh the page and try again.',
    location: 'client',
  },
  'product.outofstocknotification.legend.text': {
    value: 'Sign up to get priority access',
    location: 'client',
  },
  'product.outofstocknotification.submit': {
    value: 'Join The Waitlist',
    location: 'client',
  },
  'product.outofstocknotification.success.main.text': {
    value: 'Thank You',
    location: 'client',
  },
  'product.outofstocknotification.recaptcha.required': {
    value: 'Please complete reCAPTCHA',
    location: 'client',
  },
  'product.outofstocknotification.email': {
    value: 'Email Address',
    location: 'client',
  },
  'product.outofstocknotification.privacypolicylink.text': {
    value: 'View Privacy Policy',
    location: 'client',
  },
  'product.outofstocknotification.privacypolicylink.url': {
    value: '/info/privacy-policy.list',
    location: 'client',
  },
  'product.outofstocknotification.marketinglabel': {
    value: '%s <a href="%s" target="_blank">%s</a>',
    location: 'client',
  },
  'product.outofstocknotification.footer.text': {
    value:
      "If the item doesn't come back in stock, we'll notify you. We'll also share some similar product recommendations.",
    location: 'client',
  },
  'imagethumbnails.arialabel.text': {
    value: 'Open image %d, ',
    location: 'client',
  },
  'imagegallery.fullscreen.enter.text': {
    value: 'Enter Fullscreen view',
    location: 'client',
  },
  'imagegallery.fullscreen.exit.text': {
    value: 'Exit Fullscreen view',
    location: 'client',
  },
  'imagegallery.announcer.imagechange.text': {
    value: 'Showing Image %d, %s',
    location: 'client',
  },
  'imagegallery.fullscreen.index.text': {
    value: 'Image %d of %d',
    location: 'client',
  },
  'general.sectionpeek.viewall.text': {
    value: 'View all',
    location: 'client',
  },
  'imagegallery.controls.zoomin.arialabel': {
    value: 'Zoom in',
    location: 'client',
  },
  'imagegallery.controls.zoomout.arialabel': {
    value: 'Zoom out',
    location: 'client',
  },
  'imagegallery.announcer.zoomlevel.text': {
    value: 'Zoom level %s',
    location: 'client',
  },
  'imagegallery.announcer.fullscreenview.enter.text': {
    value: 'Entered Fullscreen View, %s',
    location: 'client',
  },
  'imagegallery.announcer.fullscreenview.exit.text': {
    value: 'Exited Fullscreen View, %s',
    location: 'client',
  },
  'imagegallery.announcer.panning.text': {
    value: 'Panning Image %s',
    location: 'client',
  },
  'imagegallery.announcer.pan.direction.left.text': {
    value: 'Left',
    location: 'client',
  },
  'imagegallery.announcer.pan.direction.right.text': {
    value: 'Right',
    location: 'client',
  },
  'imagegallery.announcer.pan.direction.down.text': {
    value: 'Down',
    location: 'client',
  },
  'imagegallery.announcer.pan.direction.up.text': {
    value: 'Up',
    location: 'client',
  },
  'imagegallery.accessibilitylabel.text': {
    value: 'Click to zoom. Use keyboard arrow keys to pan image.',
    location: 'client',
  },
  'account.details.edit.fullname.updatesuccess': {
    value: 'Your full name has successfully been updated',
    location: 'client',
  },
  'account.details.edit.fullname.updatefailed': {
    value: 'There was an error updating your full name. Please try again',
    location: 'client',
  },
  'account.details.edit.phonenumber.updatesuccess': {
    value: 'Your phone number has successfully been updated',
    location: 'client',
  },
  'account.details.edit.phonenumber.updatefailed': {
    value: 'There was an error updating your phone number. Please try again',
    location: 'client',
  },
  'account.details.edit.email.updatesuccess': {
    value: 'Your email address has successfully been updated',
    location: 'client',
  },
  'account.details.edit.email.updatefailed': {
    value: 'There was an error updating your email address. Please try again',
    location: 'client',
  },
  'account.details.edit.password.updatesuccess': {
    value: 'Your password has successfully been updated',
    location: 'client',
  },
  'account.details.edit.password.updatefailed': {
    value: 'There was an error updating your password. Please try again',
    location: 'client',
  },
  'account.details.edit.password.history.failed': {
    value:
      'You cannot reuse any of your last 8 passwords. Please choose a new password',
    location: 'client',
  },
  'account.wishlist.sort.availability': {
    value: 'Availability',
    location: 'client',
  },
  'account.wishlist.sort.discounthighesttolowest': {
    value: 'Discount: high to low',
    location: 'client',
  },
  'account.wishlist.sort.pricelowesttohighest': {
    value: 'Price: low to high',
    location: 'client',
  },
  'account.wishlist.sort.title': {
    value: 'Title',
    location: 'client',
  },
  'account.wishlist.viewmore.text': {
    value: 'View more',
    location: 'client',
  },
  'header.wishlist.slot.text': {
    value: 'Wishlist',
    location: 'client',
  },
  'header.wishlist.slot.link': {
    value: '#',
    location: 'client',
  },
  'header.slot1.text': {
    value: 'Blog',
    location: 'client',
  },
  'header.slot2.text': {
    value: 'Stores',
    location: 'client',
  },
  'header.slot1.link': {
    value: '#',
    location: 'client',
  },
  'header.slot2.link': {
    value: '#',
    location: 'client',
  },
  'content.search.title': {
    value: 'Found results for "%s"',
    location: 'client',
  },
  'content.searchresults.title.resultsfound': {
    value: 'Results for "%s"',
    location: 'client',
  },
  'content.searchresults.title.correctedquery': {
    value: '0 results found for "%s" Showing suggested results for "%s"',
    location: 'client',
  },
  'content.searchresults.noresultsfound.title': {
    value: `Sorry We Couldn't Find Any Results Matching "%s"`,
    location: 'client',
  },
  'content.searchresults.noresultsfound.subtitle': {
    value: `Use our search tips & try again.`,
    location: 'client',
  },
  'content.searchresults.noresultsfound.searchtips.1': {
    value: `Check your spelling`,
    location: 'client',
  },
  'content.searchresults.noresultsfound.searchtips.2': {
    value: `Use one or two key words`,
    location: 'client',
  },
  'content.searchresults.noresultsfound.searchtips.3': {
    value: `Try a different search term`,
    location: 'client',
  },
  'content.searchresults.noresultsfound.discontinuedtext': {
    value: `Please note this product may have been discontinued`,
    location: 'client',
  },
  'content.searchresults.noresultsfound.searchform.placeholder': {
    value: `Try again`,
    location: 'client',
  },
  'content.searchresults.noresultsfound.searchform.title': {
    value: `Enter product name or keyword`,
    location: 'client',
  },
  'content.searchresults.noresultsfound.searchform.button.title': {
    value: `Start search`,
    location: 'client',
  },
  'content.listresults.noresultsfound.title': {
    value: `Looks like we couldn’t find any products in this category`,
    location: 'client',
  },
  'content.listresults.noresultsfound.subtitle': {
    value: `Don't worry, though! We may have what you’re looking for in another category. Feel free to explore other sections of our site or use the search bar to find exactly what you need.`,
    location: 'client',
  },
  'product.buynowpaylater.title.text': {
    value: 'Buy Now Pay Later:',
    location: 'client',
  },
  'product.buynowpaylater.learnmore.link': {
    value: 'Learn more about %s',
    location: 'client',
  },
  'product.buynowpaylater.generic.text': {
    value:
      'Split/delay the payment at checkout & pay over time (interest free)',
    location: 'client',
  },
  'general.external.route': {
    value: '/',
    location: 'client',
  },
  'general.help.center.page.title': {
    value: 'Help Center',
    location: 'client',
  },
  'general.help.center.page.sub.title': {
    value: 'How can we help?',
    location: 'client',
  },
  'general.help.center.search.value.text': {
    value: 'Try searching for your question',
    location: 'client',
  },
  'general.help.center.left.rail.title': {
    value: 'Frequently Asked Questions',
    location: 'client',
  },
  'general.help.center.customer.support.title': {
    value: 'Customer Support',
    location: 'client',
  },
  'general.help.center.social.links.title': {
    value: 'Social Links',
    location: 'client',
  },
  'general.help.center.accordion.sub.title': {
    value: 'Below are the most frequently asked questions for this topic',
    location: 'client',
  },
  'general.help.center.back.text': {
    value: 'Back to Help Centre',
    location: 'client',
  },
  'general.help.center.empty.search.results.text': {
    value: 'No matches were found. Please refine your search.',
    location: 'client',
  },
  'general.go.back.text': {
    value: 'Go back',
    location: 'client',
  },
  'general.or.text': {
    value: 'Or',
    location: 'client',
  },
  'account.referrals.share.title': {
    value: 'Get sharing',
    location: 'client',
  },
  'account.referrals.balance.title': {
    value: 'Your balance',
    location: 'client',
  },
  'account.referrals.sharing.title.email': {
    value: 'Email',
    location: 'client',
  },
  'account.referrals.sharing.email.modal.title': {
    value: 'Send Via Email',
    location: 'client',
  },
  'account.referrals.sharing.email.modal.form.label': {
    value: 'To:',
    location: 'client',
  },
  'account.referrals.sharing.email.modal.form.input.placeholder': {
    value: 'Email Separated by Space or Comma',
    location: 'client',
  },
  'account.referrals.sharing.email.success.message': {
    value: 'The referral email was sent successfully',
    location: 'client',
  },
  'account.referrals.sharing.email.modal.send.text': {
    value: 'Send',
    location: 'client',
  },
  'widget.shop.the.look.product.more.text': {
    value: `View item`,
    location: 'client',
  },
  'widget.shop.the.look.range.shop.text': {
    value: `Shop the brand`,
    location: 'client',
  },
  'general.form.required.field': {
    value: '* Required field',
    location: 'client',
  },
  'general.waitlist.signup.success.title': {
    value: 'Thanks, we’ll be in touch',
    location: 'client',
  },
  'general.waitlist.signup.success.subtitle': {
    value: 'Get ready for an exciting email landing in your inbox soon...',
    location: 'client',
  },
  'general.waitlist.signup.error.title': {
    value: 'Oops, something went wrong!',
    location: 'client',
  },
  'general.waitlist.signup.error.subtitle': {
    value:
      'Click the below link to try again and don’t forget to select “I’m not a robot”, so you don’t miss out on this exciting launch.',
    location: 'client',
  },
  'general.waitlist.signup.error.button.text': {
    value: 'Go back and try again',
    location: 'client',
  },
  'product.accessibility.go.to.product.section': {
    value: 'Go to product section',
    location: 'client',
  },
  'product.accessibility.go.to.refine.section': {
    value: 'Go to refine section',
    location: 'client',
  },
  'general.play.video.button.text': {
    value: 'Play Video',
    location: 'client',
  },
  'general.videoherobanner.transcript.title': {
    value: 'Transcript',
    location: 'client',
  },
  'coveragecalculator.metric.label': {
    value: '*Metric',
    location: 'client',
  },
  'coveragecalculator.wallheight.label': {
    value: 'Wall height',
    location: 'client',
  },
  'coveragecalculator.wallwidth.label': {
    value: 'Wall width',
    location: 'client',
  },
  'coveragecalculator.addwall.button.text': {
    value: 'Add A Wall',
    location: 'client',
  },
  'coveragecalculator.excludeareaheight.label': {
    value: 'Exclude area height',
    location: 'client',
  },
  'coveragecalculator.excludeareawidth.label': {
    value: 'Exclude area width',
    location: 'client',
  },
  'coveragecalculator.addarea.button.text': {
    value: 'Add An Area',
    location: 'client',
  },
  'coveragecalculator.paintcoats.label': {
    value: 'Number of paint coats',
    location: 'client',
  },
  'coveragecalculator.includecoverage.label': {
    value: 'Include 10% coverage',
    location: 'client',
  },
  'coveragecalculator.includecoverage.text': {
    value:
      'We recommend purchasing at least 10% extra product to allow for errors, damages or obstacles.',
    location: 'client',
  },
  'coveragecalculator.calculatebutton.text': {
    value: 'Calculate',
    location: 'client',
  },
  'coveragecalculator.resetbutton.text': {
    value: 'Reset',
    location: 'client',
  },
  'coveragecalculator.results.title': {
    value: 'Results',
    location: 'client',
  },
  'coveragecalculator.results.paint.volume.text': {
    value: 'Litres of paint',
    location: 'client',
  },
  'coveragecalculator.results.basedon.text': {
    value: 'Based on total area of:',
    location: 'client',
  },
  'coveragecalculator.results.basedon.coverage.text': {
    value: 'This is based on a coverage of %dm² per litre of paint.',
    location: 'client',
  },
  'coveragecalculator.results.basedon.paint.per.text': {
    value: 'Shop our range to find the perfect paint for your job.',
    location: 'client',
  },
  'coveragecalculator.results.extramaterial.text': {
    value: ' + 10 %extra material',
    location: 'client',
  },
  'coveragecalculator.results.cta.paint.text': {
    value: 'Shop Now',
    location: 'client',
  },
  'coveragecalculator.removewall.label': {
    value: 'Remove wall',
    location: 'client',
  },
  'coveragecalculator.removearea.label': {
    value: 'Remove area',
    location: 'client',
  },
  'coveragecalculator.open.instructions.text': {
    value: 'Open Instructions',
    location: 'client',
  },
  'coveragecalculator.close.instructions.text': {
    value: 'Close Instructions',
    location: 'client',
  },
  'coveragecalculator.metric.metres.text': {
    value: 'Metres',
    location: 'client',
  },
  'coveragecalculator.metric.feet.text': {
    value: 'Feet',
    location: 'client',
  },
  'coveragecalculator.metric.inches.text': {
    value: 'Inches',
    location: 'client',
  },
  'account.useragent.nottrusted.text': {
    value:
      'You may experience technical issues using Google Sign-In from this browser. We recommend opening in a different browser on your device.',
    location: 'client',
  },
  'product.list.filter.price.minimum.price.ariaLabel': {
    value: 'Minimum Price',
    location: 'client',
  },
  'product.list.filter.price.maximum.price.ariaLabel': {
    value: 'Maximum Price',
    location: 'client',
  },
  'product.list.filter.slider.header': {
    value: 'Price range selected',
    location: 'client',
  },
  'widget.dynamicreferral.loggedout.title.text': {
    value: 'Welcome',
    location: 'client',
  },
  'widget.dynamicreferral.loggedout.subtitle.text': {
    value:
      'Want to get money off your next order? Introduce a friend to our site today and not only do they receive discount but you will receive credits too!',
    location: 'client',
  },
  'widget.dynamicreferral.loggedout.loginbutton.title': {
    value: 'Login',
    location: 'client',
  },
  'product.infoboxes.warehouse.delivery.title': {
    value: 'Home Delivery - ',
    location: 'client',
  },
  'product.infoboxes.warehouse.delivery.buttontext': {
    value: 'Learn More',
    location: 'client',
  },
  'product.infoboxes.warehouse.delivery.description': {
    value: 'Free standard home delivery on orders over £40',
    location: 'client',
  },
  'product.infoboxes.dropship.delivery.title': {
    value: 'Fulfilled by our partner - ',
    location: 'client',
  },
  'product.infoboxes.dropship.delivery.buttontext': {
    value: 'Learn More',
    location: 'client',
  },
  'product.infoboxes.dropship.delivery.description': {
    value: 'Free standard home delivery on qualifying orders',
    location: 'client',
  },
  'product.infoboxes.clickandcollect.title': {
    value: 'Click & Collect - ',
    location: 'client',
  },
  'product.infoboxes.clickandcollect.buttontext': {
    value: 'Learn More',
    location: 'client',
  },
  'product.infoboxes.clickandcollect.description': {
    value: 'Free delivery to store with Click & Collect',
    location: 'client',
  },
  'product.infoboxes.warehouse.freereturn.title': {
    value: 'Free returns to store - ',
    location: 'client',
  },
  'product.infoboxes.warehouse.freereturn.buttontext': {
    value: 'Learn More',
    location: 'client',
  },
  'product.infoboxes.warehouse.freereturn.description': {
    value: 'Placeholder copy',
    location: 'client',
  },
  'product.infoboxes.dropship.freereturn.title': {
    value: 'Returns policy - ',
    location: 'client',
  },
  'product.infoboxes.dropship.freereturn.buttontext': {
    value: 'Learn More',
    location: 'client',
  },
  'product.infoboxes.dropship.freereturn.description': {
    value:
      'This item is delivered directly from our supplier and returns policies may differ',
    location: 'client',
  },
  'product.infoboxes.modal.delivery.title': {
    value: 'Delivery',
    location: 'client',
  },
  'product.infoboxes.modal.dropship.returns.title': {
    value: 'Returns',
    location: 'client',
  },
  'product.infoboxes.modal.dropship.return.text': {
    value:
      'This product is dispatched directly by a third party. To ensure your returns are processed correctly, please return the item using the provided returns slip. If you have misplaced your returns slip, please refer to our Return Policy or contact our customer service team for assistance.​',
    location: 'client',
  },
  'product.infoboxes.modal.dropship.delivery.title': {
    value: 'Delivery',
    location: 'client',
  },

  'product.infoboxes.modal.delivery.freestandard.title': {
    value: 'Free Standard Home Delivery',
    location: 'client',
  },
  'product.infoboxes.modal.delivery.freestandard.subtitle': {
    value: 'FREE on orders over £40',
    location: 'client',
  },
  'product.infoboxes.modal.delivery.freestandard.description': {
    value:
      '<p>By spending over £40 online, you will qualify for Free Standard Home Delivery. We reserve the right to vary the terms of this promotion at any time, or to withdraw this promotion altogether.</p><p>*Excludes items that have a £10 large item delivery cost.</p>',
    location: 'client',
  },
  'product.infoboxes.modal.delivery.standard.title': {
    value: 'Standard Home Delivery',
    location: 'client',
  },
  'product.infoboxes.modal.delivery.standard.subtitle': {
    value: '£3.95',
    location: 'client',
  },
  'product.infoboxes.modal.delivery.standard.description': {
    value:
      'Standard Home Delivery orders should arrive within 3-5 days, and deliveries are made 7 days a week.',
    location: 'client',
  },
  'product.infoboxes.modal.delivery.clickandcollect.title': {
    value: 'Standard Click And Collect',
    location: 'client',
  },
  'product.infoboxes.modal.delivery.clickandcollect.subtitle': {
    value: 'FREE',
    location: 'client',
  },
  'product.infoboxes.modal.delivery.clickandcollect.description': {
    value:
      "<p>Standard Click & Collect orders will arrive in store within 3-5 days.</p><p>Once your Click & Collect order has arrived in store, we'll send you a confirmation email and a text to let you know your order is ready to collect. Please bring a copy of your email as proof of purchase.</p><p>If you haven't collected your order after 7 days, we'll send you a reminder, and then another one after 10 days. At 15 days, we'll refund your money and return the order.</p>",
    location: 'client',
  },
  'product.infoboxes.modal.moreinfo.text': {
    value: 'More Info & Exceptions >>',
    location: 'client',
  },
  'product.infoboxes.modal.moreinfo.link': {
    value: '',
    location: 'client',
  },
  'product.infoboxes.modal.returns.title': {
    value: 'Returns',
    location: 'client',
  },
  'product.infoboxes.modal.returns.policy.title': {
    value: 'Returns Policy',
    location: 'client',
  },
  'product.infoboxes.modal.returns.policy.pleasenote': {
    value:
      "Please note: there are exceptions to our in-store and online returns policy. Please refer to the 'Exceptions to Refund Policy' section below for more information.",
    location: 'client',
  },
  'product.infoboxes.modal.returns.policy.description': {
    value:
      "<p>Changed your mind? Don't worry, you can return items in store, by post or via our online portal.</p><p>We'll be happy to exchange or refund within 14 days of purchase if you bought the items in store, or within 14 days of you receiving the items if you ordered them online. Items must be returned with the original receipt, unworn and in the condition they were purchased, including all labels, tags and packaging. The refund will be issued on the method of payment you used.</p>",
    location: 'client',
  },
  'product.infoboxes.modal.returns.instore.title': {
    value: 'Return or exchange in store',
    location: 'client',
  },
  'product.infoboxes.modal.returns.instore.subtitle': {
    value: 'FREE',
    location: 'client',
  },
  'product.infoboxes.modal.returns.instore.description': {
    value:
      "<p>Your local store will arrange an exchange or refund for you. Use our Store Finder tool to find details of your nearest store and its opening hours.</p><p>Please note that we're unable to accept returns to our Matalan Clearance Stores. Please safely dispose of any broken glass before returning your item to us.</p>",
    location: 'client',
  },
  'product.infoboxes.modal.returns.instore.saleitems.title': {
    value: 'Sale items',
    location: 'client',
  },
  'product.infoboxes.modal.returns.instore.saleitems.description': {
    value:
      'If goods are Sale items we will only offer an exchange or refund on to a refund card only.',
    location: 'client',
  },
  'product.infoboxes.modal.returns.instore.online.title': {
    value: 'Online returns',
    location: 'client',
  },
  'product.infoboxes.modal.returns.instore.online.description': {
    value:
      'For online orders, we offer refunds within 14 days of you receiving the items. Unwanted items must be returned with the original receipt, unworn and in the condition they were purchased, including all packaging.',
    location: 'client',
  },
  'account.returns.stepscard.title': {
    value: 'Next steps',
    location: 'client',
  },
  'account.returns.stepscard.table.title': {
    value: 'You are returning',
    location: 'client',
  },
  'account.returns.stepscard.table.title.cancelled': {
    value: 'You were returning',
    location: 'client',
  },
  'account.returns.stepscard.table.title.completed': {
    value: 'Returned items',
    location: 'client',
  },
  'account.returns.stepscard.table.headeritem': {
    value: 'Item',
    location: 'client',
  },
  'account.returns.stepscard.table.headerquantity': {
    value: 'Quantity',
    location: 'client',
  },
  'account.returns.stepscard.download.button': {
    value: 'Download label',
    location: 'client',
  },
  'account.returns.stepscard.title.return.complete': {
    value: 'Return summary',
    location: 'client',
  },
  'account.returns.postal.packitems.label.text': {
    value: 'Download or print your label',
    location: 'client',
  },
  'account.returns.postal.packitems.packageyouritems.text': {
    value: 'Package up your items according to the',
    location: 'client',
  },
  'account.returns.postal.packitems.packageyouritems.royalmail.link.text': {
    value: 'Royal Mail postal guidelines',
    location: 'client',
  },
  'account.returns.postal.packitems.dropoffpoints.text': {
    value:
      'Take your label or email to a Royal Mail’s Customer Service point or a Post Office, use this link to',
    location: 'client',
  },
  'account.returns.postal.packitems.dropoffpoints.text2': {
    value: ' find drop off points near you',
    location: 'client',
  },
  'account.returns.postal.packitems.dropoffpoints.text2Link': {
    value: '/Home',
    location: 'client',
  },
  'account.returns.postal.packitems.dropoffpoints.link.text': {
    value: 'find drop off points near you',
    location: 'client',
  },
  'account.returns.postal.intransit.warehouse.text': {
    value:
      'Your return is on its way to the warehouse, we’ll update you when it arrives',
    location: 'client',
  },
  'account.returns.postal.intransit.time.text': {
    value: 'It normally takes 5-7 working days for a return to get back to us',
    location: 'client',
  },
  'account.returns.postal.intransit.processed.text': {
    value: 'Once it’s been processed you can expect your refund in 10-15 days',
    location: 'client',
  },
  'account.returns.postal.returned.processed.text': {
    value:
      'Once we have processed your return, you can expect your refund in 10-15 days',
    location: 'client',
  },
  'account.returns.store.packitems.findstore.text': {
    value:
      'Take your label and the items you are returning to your nearest store, use this link to',
    location: 'client',
  },
  'account.returns.store.packitems.showlabel.text': {
    value:
      'Once in-store, show your label or email and give your items to a store colleague for processing.',
    location: 'client',
  },
  'account.returns.store.returned.text': {
    value:
      'Once we have processed your return, you can expect your refund in 10-15 days',
    location: 'client',
  },
  'titles.page.returns.history.text': {
    value: 'Returns History',
    location: 'client',
  },
  'account.history.noreturns.message': {
    value: 'You currently have no returns',
    location: 'client',
  },
  'account.history.noreturns.active.text': {
    value: 'You have no active returns',
    location: 'client',
  },
  'account.returns.noreturns.completed.text': {
    value: 'You have no completed returns',
    location: 'client',
  },
  'account.returns.active.text': {
    value: 'Active returns',
    location: 'client',
  },
  'account.returns.completed.text': {
    value: 'Completed returns',
    location: 'client',
  },
  'account.myreturns.page.title': {
    value: 'My Returns',
    location: 'client',
  },
  'account.return.status.cancelled.text': {
    value: 'Your return has been cancelled.',
    location: 'client',
  },
  'account.return.status.created.text': {
    value: 'Your return has been created and is expected.',
    location: 'client',
  },
  'account.return.status.processed.text': {
    value: 'Your return has been processed.',
    location: 'client',
  },
  'account.return.status.completed.text': {
    value: 'Your return has been completed.',
    location: 'client',
  },
  'account.return.status.unexpected.text': {
    value: 'Your return was unexpected.',
    location: 'client',
  },
  'account.return.status.held.text': {
    value: 'Your return is currently being held.',
    location: 'client',
  },
  'account.return.status.contacted.text': {
    value: 'You have been contacted about this return.',
    location: 'client',
  },
  'account.return.status.identified.text': {
    value: 'An unidentified return has now been identified.',
    location: 'client',
  },
  'account.return.status.manualresolved.text': {
    value: 'An unidentified return has been resolved.',
    location: 'client',
  },
  'account.return.status.earlyrefund.text': {
    value: 'The return was refunded early.',
    location: 'client',
  },
  'account.return.status.returninprogress.text': {
    value: 'Your return is in progress.',
    location: 'client',
  },
  'account.returns.label.desktop.packitems.text': {
    value: 'Pack Items',
    location: 'client',
  },
  'account.returns.label.desktop.intransit.text': {
    value: 'In transit',
    location: 'client',
  },
  'account.returns.label.desktop.returned.text': {
    value: 'Returned',
    location: 'client',
  },
  'account.returns.label.desktop.refund.text': {
    value: 'Refund Items',
    location: 'client',
  },
  'account.returns.label.alternate.refund.text': {
    value: 'Refunding',
    location: 'client',
  },
  'account.returns.progressbar.arialabel.text': {
    value: 'Your return is currently in transit',
    location: 'client',
  },
  'account.returns.progressbar.packitems.title': {
    value: 'Your return has been submitted',
    location: 'client',
  },
  'account.returns.progressbar.intransit.title': {
    value: 'Your return is in transit',
    location: 'client',
  },
  'account.returns.progressbar.returned.title': {
    value: 'We’ve got your items',
    location: 'client',
  },
  'account.returns.progressbar.refunded.title': {
    value: 'Return completed',
    location: 'client',
  },
  'account.returnsnumber.card.title': {
    value: 'Return details',
    location: 'client',
  },
  'account.returnsnumber.return.number': {
    value: 'Return number',
    location: 'client',
  },
  'account.returnsnumber.submitted.on': {
    value: 'Submitted on',
    location: 'client',
  },
  'account.returnsnumber.refunded.on': {
    value: 'Refunded on',
    location: 'client',
  },
  'account.returnsnumber.return.method': {
    value: 'Return method',
    location: 'client',
  },
  'account.returnsnumber.item.total': {
    value: 'Item total',
    location: 'client',
  },
  'account.returnsnumber.total.refund': {
    value: 'Total refund',
    location: 'client',
  },
  'account.returnsnumber.cancel.return': {
    value: 'Cancel Return',
    location: 'client',
  },
  'account.returnsnumber.cancel.modal': {
    value: 'Are you sure you want to proceed with this change?',
    location: 'client',
  },
  'account.returnsnumber.returnMethod': {
    value: 'In-store return',
    location: 'client',
  },
  'account.returnsnumber.success.message': {
    value: 'The return has been cancelled',
    location: 'client',
  },
  'account.returnsnumber.error.message': {
    value: 'There was an error cancelling the return, please try again later.',
    location: 'client',
  },
  'account.returnsnumber.notfound.title': {
    value: "Your return can't be found",
    location: 'client',
  },
  'account.set.default.delivery.address': {
    value: 'Set as default delivery address',
    location: 'client',
  },
  'account.set.default.billing.address': {
    value: 'Set as default billing address',
    location: 'client',
  },
  'account.default.deliveryandbilling.address': {
    value: 'Default delivery and billing address',
    location: 'client',
  },
  'account.default.billing.address': {
    value: 'Default billing address',
    location: 'client',
  },
  'account.default.delivery.address': {
    value: 'Default delivery address',
    location: 'client',
  },
  'account.confirm.address.default.modal.confirm': {
    value: 'Yes, I want to update',
    location: 'client',
  },
  'account.modal.default.delivery.title': {
    value: 'Update default delivery address',
    location: 'client',
  },
  'account.modal.default.billing.title': {
    value: 'Update default billing address',
    location: 'client',
  },
  'account.modal.default.delivery.message': {
    value: 'Are you sure you want to update your delivery address details?',
    location: 'client',
  },
  'account.modal.default.billing.message': {
    value: 'Are you sure you want to update your billing address details?',
    location: 'client',
  },
  'account.addresses.edit.default.billing.checkbox.label': {
    value: 'Set as default billing address',
    location: 'client',
  },
  'account.addresses.edit.default.delivery.checkbox.label': {
    value: 'Set as default delivery address',
    location: 'client',
  },
  'account.login.passwordreset.title.message': {
    value: 'Welcome back, please reset your password to continue',
    location: 'client',
  },
  'account.login.passwordreset.subtitle.message': {
    value:
      "We've updated our website recently, so you'll need to reset your password to login. Please enter your email address below and we’ll email you instructions to reset it.",
    location: 'client',
  },
  'account.login.passwordreset.newpassword.label': {
    value:
      'New password must be a minimum of 6 characters long and contain at least one number.',
    location: 'client',
  },
  'account.cancelledreturn.details.page': {
    value: 'Return cancelled',
    location: 'client',
  },
  'account.returnsnumber.cancelled.on': {
    value: 'Cancelled on',
    location: 'client',
  },
  'account.card.expired.message': {
    value: "This card has expired, you won't be able to use it",
    location: 'client',
  },
  'account.order.dispatched.postal.code': {
    value: 'Delivery to',
    location: 'client',
  },
  'account.orders.missingorders.button.text': {
    value: "Can't find an order?",
    location: 'client',
  },
  'account.returns.store.packitems.findstore.link.text': {
    value: 'find your nearest store',
    location: 'client',
  },
  'account.returns.postal.guidelines.link': {
    value: '/',
    location: 'client',
  },
  'account.returns.nearest.store.link': {
    value: '/',
    location: 'client',
  },
  'account.returns.dropoff.points.link': {
    value: '/',
    location: 'client',
  },
  'variation.placeholder.fit.text': {
    value: 'Select a fit',
    location: 'client',
  },
  'variation.placeholder.size.text': {
    value: 'Select a size',
    location: 'client',
  },
  'variation.placeholder.colour.text': {
    value: 'Select a colour',
    location: 'client',
  },
  'variation.placeholder.shade.text': {
    value: 'Select a shade',
    location: 'client',
  },
  'variation.placeholder.flavour.text': {
    value: 'Select a flavour',
    location: 'client',
  },
  'variation.placeholder.package.text': {
    value: 'Select a package',
    location: 'client',
  },
  'variation.placeholder.amount.text': {
    value: 'Select an amount',
    location: 'client',
  },
  'variation.placeholder.strength.text': {
    value: 'Select a strenth',
    location: 'client',
  },
  'variation.placeholder.character.text': {
    value: 'Select a character',
    location: 'client',
  },
  'variation.placeholder.option.text': {
    value: 'Select an option',
    location: 'client',
  },
  'variation.placeholder.type.text': {
    value: 'Select a type',
    location: 'client',
  },
  'variation.placeholder.model.text': {
    value: 'Select a model',
    location: 'client',
  },
  'variation.placeholder.finish.text': {
    value: 'Select a finish',
    location: 'client',
  },
  'variation.placeholder.flavour1.text': {
    value: 'Select flavour 1',
    location: 'client',
  },
  'variation.placeholder.flavour2.text': {
    value: 'Select flavour 2',
    location: 'client',
  },
  'variation.placeholder.proteinflavour.text': {
    value: 'Select protein flavour',
    location: 'client',
  },
  'variation.placeholder.bcaaflavour.text': {
    value: 'Select BCAA flavour',
    location: 'client',
  },
  'variation.placeholder.preworkoutflavour.text': {
    value: 'Select pre-workout flavour',
    location: 'client',
  },
  'variation.placeholder.snackflavour.text': {
    value: 'Select snack flavour',
    location: 'client',
  },
  'variation.placeholder.hoodiesize.text': {
    value: 'Select hoodie size',
    location: 'client',
  },
  'variation.placeholder.shortssize.text': {
    value: 'Select shorts size',
    location: 'client',
  },
  'variation.placeholder.brasize.text': {
    value: 'Select a bra size',
    location: 'client',
  },
  'variation.placeholder.leggingssize.text': {
    value: 'Select leggings size',
    location: 'client',
  },
  'variation.placeholder.bottomssize.text': {
    value: 'Select bottoms size',
    location: 'client',
  },
  'variation.placeholder.topsize.text': {
    value: 'Select topsize',
    location: 'client',
  },
  'variation.placeholder.creatineflavour.text': {
    value: 'Select creatine flavour',
    location: 'client',
  },
  'variation.placeholder.intraworkoutflavour.text': {
    value: 'Select intra workout flavour',
    location: 'client',
  },
  'variation.placeholder.syrupflavour.text': {
    value: 'Select syrup flavour',
    location: 'client',
  },
  'variation.placeholder.fragrance.text': {
    value: 'Select fragrance',
    location: 'client',
  },
  'variation.placeholder.width.text': {
    value: 'Select width',
    location: 'client',
  },
  'meta.description.product.1.text': {
    value: 'Find',
    location: 'client',
  },
  'meta.description.product.2.text': {
    value: 'at Whitelabel.',
    location: 'client',
  },
  'meta.description.product.3.text': {
    value:
      'Visit your local store for the widest range of storage & home products.',
    location: 'client',
  },
  'meta.description.list.default.1': {
    value: 'Find',
    location: 'client',
  },
  'meta.description.list.default.2': {
    value: 'at Whitelabel.',
    location: 'client',
  },
  'meta.description.list.default.3': {
    value: 'Shop today.',
    location: 'client',
  },
  'account.subscription.orderhistory.timefilter.lastsixmonth': {
    value: 'Last 6 Months',
    location: 'client',
  },
  'account.subscription.orderhistory.timefilter.lasttwelvemonth': {
    value: 'Last 12 Months',
    location: 'client',
  },
  'account.subscription.orderhistory.error.message': {
    value: 'No subscription data found',
    location: 'client',
  },
  'product.delivery.home.instock.text': {
    value: 'Delivery from 10 days',
    location: 'client',
  },
  'product.delivery.home.outofstock.text': {
    value: 'Out of stock for delivery',
    location: 'client',
  },
  'product.delivery.home.unavailable.text': {
    value: 'Delivery is not available',
    location: 'client',
  },
  'product.delivery.store.available.text': {
    value: 'Available in Stores',
    location: 'client',
  },
  'product.clickandcollect.unavailable.text': {
    value: 'Not available in stores',
    location: 'client',
  },
  'account.emailsignup.form.email.required.label': {
    value: 'Email address is a required field',
    location: 'client',
  },
  'product.storystream.title.text': {
    value: 'Share your style',
    location: 'client',
  },
  'clickandcollect.search.title': {
    value: 'Check Stock',
    location: 'client',
  },
  'clickandcollect.search.subtitle': {
    value:
      'Enter your full postcode for product availability in your %d nearest stores.',
    location: 'client',
  },
  'clickandcollect.search.cta': {
    value: 'Find Stores',
    location: 'client',
  },
  'clickandcollect.search.label': {
    value: '*Enter your postcode',
    location: 'client',
  },
  'clickandcollect.messages.postcoderequired': {
    value: 'The postcode field is required',
    location: 'client',
  },
  'clickandcollect.messages.noresultsfound': {
    value: 'Sorry, we found no results for %s',
    location: 'client',
  },
  'clickandcollect.messages.outofstock': {
    value:
      'Stock levels are provided as a guide and may change quickly with high demand',
    location: 'client',
  },
  'clickandcollect.selectstore': {
    value: 'Click And Collect',
    location: 'client',
  },
  'clickandcollect.storedeliver': {
    value: 'Deliver To Store',
    location: 'client',
  },
  'clickandcollect.store.availability.stockmessage': {
    value:
      'Can be delivered into this store for collection. Delivery charges applied in checkout',
    location: 'client',
  },
  'clickandcollect.store.availability.instock': {
    value: '%d in stock',
    location: 'client',
  },
  'clickandcollect.store.availability.outofstock': {
    value: 'Out of stock',
    location: 'client',
  },
  'clickandcollect.store.availability.not.ranged': {
    value: 'Not available at this store',
    location: 'client',
  },
  'clickandcollect.productstock.messages.noclickandcollectonproduct': {
    value: 'Sorry, Click and Collect is unavailable on this product.',
    location: 'client',
  },
  'productStock.messages.notranged': {
    value: 'Sorry, this product is not ranged at this store.',
    location: 'client',
  },
  'product.stock.information.custom': {
    value: 'Estimated shipping in 8 weeks',
    location: 'client',
  },
  'product.alreadyaddedinbasket.info.text': {
    value:
      'Item already in basket as a one time purchase. If you also wish to subscribe to the same item please make 2 separate checkout purchases.',
    location: 'client',
  },
  'product.inbasketwith.subscription.contracts': {
    value:
      'This product is already in your basket as subscription product. If you also wish to buy as a one time purchase, please make 2 separate orders.',
    location: 'client',
  },
  'product.subscription.onetimepurchase.discount.percentage.text': {
    value: 'Or subscribe and save up to %s',
    location: 'client',
  },
  'product.subscriptions.nofrequency.available.text': {
    value: 'No delivery frequency  available',
    location: 'client',
  },
  'storelocator.storesearch.indexpage.title': {
    value: '%s Stores in %s',
    location: 'client',
  },
  'storelocator.storesearch.searchpage.viewmap': {
    value: 'VIEW MAP',
    location: 'client',
  },
  'storelocator.storesearch.searchpage.viewlist': {
    value: 'VIEW LIST',
    location: 'client',
  },
  'storelocator.storesearch.searchpage.title': {
    value: 'Find your nearest %s store',
    location: 'client',
  },
  'storelocator.storesearch.subtitle': {
    value: 'Enter your full postcode or town to find your nearest store',
    location: 'client',
  },
  'storelocator.storesearch.inputlabel': {
    value: 'Enter Town or Postcode',
    location: 'client',
  },
  'storelocator.storesearch.infolabel': {
    value: 'Use the search above or',
    location: 'client',
  },
  'storelocator.storesearch.infolinklabel': {
    value: 'browse our directory',
    location: 'client',
  },
  'storelocator.storesearch.usemylocation': {
    value: 'Use my location',
    location: 'client',
  },
  'storelocator.storedetails.storeinfo.title': {
    value: 'Store Info',
    location: 'client',
  },
  'storelocator.storedetails.openingtimes.title': {
    value: 'Opening Times',
    location: 'client',
  },
  'storelocator.storedetails.facilities.title': {
    value: 'Store Facilities',
    location: 'client',
  },
  'storelocator.storedetails.openingtimes.open': {
    value: 'Open',
    location: 'client',
  },
  'storelocator.storedetails.openingtimes.closed': {
    value: 'Closed',
    location: 'client',
  },
  'storelocator.storedetails.distance': {
    value: 'miles away',
    location: 'client',
  },
  'storelocator.storedetails.open': {
    value: 'Open now',
    location: 'client',
  },
  'storelocator.storedetails.closed': {
    value: 'Closed',
    location: 'client',
  },
  'storelocator.storedetails.temporarilyclosed': {
    value: 'Temporarily Closed',
    location: 'client',
  },
  'storelocator.storedetails.permanentlyclosed': {
    value: 'Permanently Closed',
    location: 'client',
  },
  'storelocator.storedetails.detailsbutton': {
    value: 'Store Details',
    location: 'client',
  },
  'storelocator.storedetails.directionsbutton': {
    value: 'Get Directions',
    location: 'client',
  },
  'storelocator.storedetails.distanceunits': {
    value: 'miles',
    location: 'client',
  },
  'storelocator.storedetails.nearbystores.title': {
    value: 'Nearby Stores',
    location: 'client',
  },
  'storelocator.storedetails.description.text': {
    value: 'Store Description',
    location: 'client',
  },
  'storelocator.storesearch.page.title': {
    value: 'Store Locator Page',
    location: 'client',
  },
  'storelocator.storesearch.buttonlabel': {
    value: 'Search',
    location: 'client',
  },
  'storelocator.breadcrumbs.storefinder': {
    value: 'Store Finder',
    location: 'client',
  },
  'storelocator.storesearch.results.text': {
    value: 'Showing %s stores within 100 miles of %s',
    location: 'client',
  },
  'product.storepickup.cost': {
    value: 'Store pickup: Free',
    location: 'client',
  },
  'product.list.delivery.available': {
    value: 'Delivery Available',
    location: 'client',
  },
  'product.list.item.nextdaydelivery': {
    value: 'Next day delivery',
    location: 'client',
  },
  'product.item.fulfilment.1man.nextdaydeliveryavailable': {
    value: 'Next day delivery available',
    location: 'client',
  },
  'product.item.fulfilment.outofgauge.nameddaydeliveryavailable': {
    value: 'Named day delivery available',
    location: 'client',
  },
  'product.item.fulfilment.1man.secondmessage': {
    value: 'Order before 4pm Sun-Fri',
    location: 'client',
  },
  'product.item.fulfilment.outofgauge.secondmessage': {
    value: 'Choose a delivery date',
    location: 'client',
  },
  'product.list.fulfilment.1man.nextdaydeliveryavailable': {
    value: 'Next day delivery',
    location: 'client',
  },
  'product.list.fulfilment.outofgauge.nameddaydeliveryavailable': {
    value: 'Named day delivery',
    location: 'client',
  },
  'product.list.clickandcollect.text': {
    value: 'Click & Collect',
    location: 'client',
  },
  'product.item.fulfilment.1-man': {
    value: 'Delivery within 3 days',
    location: 'client',
  },
  'product.item.fulfilment.2-man-a': {
    value: 'Delivery from 10 days',
    location: 'client',
  },
  'product.item.fulfilment.2-man-b': {
    value: 'Delivery from 10 days',
    location: 'client',
  },
  'product.item.fulfilment.2-man-c': {
    value: 'Delivery from 21 days',
    location: 'client',
  },
  'product.item.fulfilment.2-man-d': {
    value: 'Delivery from 10 days',
    location: 'client',
  },
  'product.item.fulfilment.2-man-e': {
    value: 'Delivery from 10 days',
    location: 'client',
  },
  'product.item.fulfilment.pdp.1-man': {
    value: 'Delivery within 3 days',
    location: 'client',
  },
  'product.item.fulfilment.pdp.2-man-a': {
    value: 'Delivery from 10 days - we will contact you to arrange delivery',
    location: 'client',
  },
  'product.item.fulfilment.pdp.2-man-b': {
    value: 'Delivery from 10 days - we will contact you to arrange delivery',
    location: 'client',
  },
  'product.item.fulfilment.pdp.2-man-c': {
    value: 'Delivery from 21 days - we will contact you to arrange delivery',
    location: 'client',
  },
  'product.item.fulfilment.pdp.2-man-d': {
    value: 'Delivery from 10 days - we will contact you to arrange delivery',
    location: 'client',
  },
  'product.item.fulfilment.pdp.2-man-e': {
    value: 'Delivery from 10 days - we will contact you to arrange delivery',
    location: 'client',
  },
  'product.item.fulfilment.orderinstore.available.text': {
    value: 'Order in store',
    location: 'client',
  },
  'product.item.fulfilment.orderinstore.available.message': {
    value:
      'Can be ordered for delivery from our Customer Service desk in store (subject to stock availability)',
    location: 'client',
  },
  'storelocator.storedetails.email': {
    value: 'cs@matalanonline.co.uk',
    location: 'client',
  },
  'storelocator.storedetails.breadcrumbs.storefinder': {
    value: 'Store Finder',
    location: 'client',
  },
  'stores.regions.head.title': {
    value: 'Stores',
    location: 'client',
  },
  'stores.country.page.title': {
    value: 'Country',
    location: 'client',
  },
  'stores.city.page.title': {
    value: 'Town',
    location: 'client',
  },
  'breadcrumbs.all.stores': {
    value: 'All Stores',
    location: 'client',
  },
  'storelocator.storsearch.title': {
    value: 'Stores in %s',
    location: 'client',
  },
  'basket.orders.summary.heading': {
    value: 'Order Summary',
    location: 'client',
  },
  'basket.totalbeforediscount.label': {
    value: 'Total before saving',
    location: 'client',
  },
  'product.missing.size.error': {
    value: 'Please select a size',
    location: 'client',
  },
  'account.subscriptionrenewal.title': {
    value: 'Update your subscription',
    location: 'client',
  },
  'account.subscriptionrenewal.text': {
    value:
      'Your subscription contract is ending on %s. Renew your plan by selecting one of the options below:',
    location: 'client',
  },
  'account.subscriptionrenewal.currentsubscription': {
    value: 'Current Subscription:',
    location: 'client',
  },
  'account.subscriptionrenewal.preferredsubscription': {
    value: 'Please select your preferred subscription:',
    location: 'client',
  },
  'account.subscriptionrenewal.existingterms': {
    value: 'Renew with existing terms',
    location: 'client',
  },
  'account.subscriptionrenewal.indefinite': {
    value: 'Indefinite*',
    location: 'client',
  },
  'account.subscriptionrenewal.indefiniteplan.description': {
    value: '*Indefinite plans can be cancelled with 30 days notice.',
    location: 'client',
  },
  'account.subscriptionrenewal.success.text': {
    value: 'Your subscription has been renewed successfully',
    location: 'client',
  },
  'account.subscriptionrenewal.error.text': {
    value:
      'An error occurred when trying to renew your subscription. Please try again.',
    location: 'client',
  },
  'account.subscriptionrenewal.confirmbutton.title': {
    value: 'Confirm',
    location: 'client',
  },
  'account.subscription.numberoforders.singular': {
    value: 'for %d order',
    location: 'client',
  },
  'account.subscription.numberoforders.plural': {
    value: 'for %d orders',
    location: 'client',
  },
  'account.subscriptionrenewal.default.rolling': {
    value:
      'If no option is selected before your next contract renewal date, your subscription will continue on an indefinite monthly rolling basis.',
    location: 'client',
  },
  'account.subscriptionrenewal.default.cancel': {
    value:
      'If no option is selected before your next contract renewal date, your subscription will be cancelled.',
    location: 'client',
  },
  'footer.footernotes.financial.terms.policy.text': {
    value: '',
    location: 'client',
  },
  'account.login.termsandconditions.text': {
    value:
      '%s <a href="/%s" target=”_blank” rel=”noopener noreferrer”>%s</a> %s <a href="/%s" target=”_blank” rel=”noopener noreferrer”>%s</a> %s',
    location: 'client',
  },
  'search.recent.items': {
    value: 'Recently Searched',
    location: 'client',
  },
  'search.recent.searched': {
    value: 'in %s',
    location: 'client',
  },
  'personalisation.tab.label.classic': {
    value: 'CLASSIC',
    location: 'client',
  },
  'personalisation.tab.label.personalised': {
    value: 'PERSONALISED',
    location: 'client',
  },
  'personalisation.input.label.name': {
    value: 'Write their name (Required)',
    location: 'client',
  },
  'personalisation.input.label.message': {
    value: 'Enter your message to them (Required)',
    location: 'client',
  },
  'personalisation.image.select.text': {
    value: 'Choose a distinct design:',
    location: 'client',
  },
  'personalisation.swatches.label': {
    value: 'Choose a distinct design:',
    location: 'client',
  },
  'basket.discount.saving.text': {
    value: 'Savings',
    location: 'client',
  },
  'basket.discount.saving.rrp.text': {
    value: 'Sale',
    location: 'client',
  },
  'basket.total.rrp.text': {
    value: 'Subtotal',
    location: 'client',
  },
  'product.usp.delivery.viewmore.text': {
    value: 'View Delivery and Returns Information',
    location: 'client',
  },
  'product.item.build.your.outfit.text': {
    value: 'Build your outfit',
    location: 'client',
  },
}
